import React, { useState, useEffect } from "react";
import axios from "axios";
import Styles from "./HoverWishlist.module.css";
import { FaRegHeart } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useAuth } from "../../../Context/AuthContext";

export default function HoverWishlist() {
  const [wishlistData, setWishlistData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();
  const emailId = user?.email_id || null;

  useEffect(() => {
    if (!emailId) {
      setLoading(false);
      return;
    }
    setLoading(true);
    const fetchWishlistCourses = async () => {
      try {
        const response = await axios.get(
          `https://auth.ssccglpinnacle.com/api/userwishlist/${emailId}`
        );
        if (response.data?.data && Array.isArray(response.data.data)) {
          setWishlistData(response.data.data);
        } else {
          throw new Error("Failed to load wishlist courses.");
        }
      } catch (error) {
        console.error("Error fetching wishlist data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchWishlistCourses();
  }, [emailId]);

  return (
    <div className={Styles.learning}>
      <div className={Styles.dropdown}>
        <button className={Styles.dropbtn}>
          <FaRegHeart size={30} className={Styles.wishlist_icon} />
          {wishlistData.length > 0 && (
            <span className={Styles.badge}>{wishlistData.length}</span>
          )}
        </button>
        <div className={Styles.dropdown_content}>
          {loading ? (
            <p>Loading...</p>
          ) : wishlistData.length > 0 ? (
            <div className={Styles.myLearningContainer}>
              {wishlistData.map((item, index) => (
                <div key={index} className={Styles["CartList-ul"]}>
                  <div
                    className={Styles["both-ImageSection-descriptionSection"]}
                  >
                    <div className={Styles["CartList-ImageSection"]}>
                      <img
                        src={item.englishCoverImage || ""}
                        alt="course image"
                        className={Styles["Image"]}
                      />
                    </div>
                    <div className={Styles["CartList-descriptionSection"]}>
                      <div className={Styles["courseName"]}>
                        {item.courseTitle || "Title not available"}
                      </div>
                      <div className={Styles["faculty"]}>
                        {item.instructorName || "Instructor not available"}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <Link to={"/wishlist"} className={Styles["GoToWishlist-btn"]}>
                Go to Wishlist
              </Link>
            </div>
          ) : (
            <div className={Styles.dropdown_content}>
              <div className={Styles["CartList-ul"]}>
                <div className={Styles["both-ImageSection-descriptionSection"]}>
                  <div className={Styles["CartList-descriptionSection"]}>
                    No items in Wishlist
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
