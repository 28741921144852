import React, { useState } from "react";
import axios from "axios";
import styles from "./AddKnowledgeBaseForm.module.css";

const AddKnowledgeBaseForm = ({ fetchEntries }) => {
  const [formData, setFormData] = useState({ question: "", answer: "" });
  const [message, setMessage] = useState({ type: "", content: "" });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage({ type: "", content: "" });

    try {
      const response = await axios.post("https://auth.ssccglpinnacle.com/api/knowledgebase",{
        question: formData.question,
        answer: formData.answer,
      });
      if (response.status === 201 || response.status === 200) {
        setMessage({ type: "success", content: response.data.message || "Entry added successfully!" });
        fetchEntries();
        setFormData({ question: "", answer: "" });
      } else {
        throw new Error(response.data.message || "Unexpected response from the server.");
      }
    } catch (error) {
      console.error("Error adding entry:", error.response?.data || error.message);
      setMessage({
        type: "error",
        content: error.response?.data?.message || "Failed to add entry. Check console for details.",
      });
    }
  };

  return (
    <div className={styles.container}>
      <h2>Add Knowledge Base Entry</h2>
      <form onSubmit={handleSubmit} className={styles.form}>
        <input
          type="text"
          name="question"
          value={formData.question}
          onChange={handleChange}
          placeholder="Enter question"
          required
          className={styles.input}
        />
        <textarea
          name="answer"
          value={formData.answer}
          onChange={handleChange}
          placeholder="Enter answer"
          required
          className={styles.textarea}
        />
        <button type="submit" className={styles.button}>
          Add Entry
        </button>
      </form>
      {message.content && (
        <div
          className={`${styles.message} ${
            message.type === "success" ? styles.success : styles.error
          }`}
        >
          {message.content}
        </div>
      )}
    </div>
  );
};

export default AddKnowledgeBaseForm;