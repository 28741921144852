import React, { useState } from "react";
import Swal from "sweetalert2";
import styles from "./AddOgDataForm.module.css";

// const baseURL = "http://localhost:5000/api";
const baseURL = "https://auth.ssccglpinnacle.com/api";

const AddOgDataForm = () => {
  const [formData, setFormData] = useState({
    type: "",
    ogTitle: "",
    ogDescription: "",
    ogImage: "",
    ogType: "",
  });

  const [ogTitleLimit] = useState(60); // OG Title
  const [ogDescriptionLimit] = useState(300); // OG Description
  const [ogTypeLimit] = useState(20); // OG Type
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${baseURL}/og`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        Swal.fire("Success", "OG tag added successfully!", "success");
        setFormData({
          type: "",
          ogTitle: "",
          ogDescription: "",
          ogImage: "",
          ogType: "",
        });
      } else {
        const errorData = await response.json();
        Swal.fire(
          "Error",
          errorData.message || "Failed to add OG tag.",
          "error"
        );
      }
    } catch (error) {
      Swal.fire("Error", "Something went wrong!", "error");
    }
  };

  const generateOgDescription = async () => {
    const { type, ogTitle } = formData;

    if (!type || !ogTitle) {
      Swal.fire(
        "Error",
        "Please fill in the Meta Type and OG Title fields before generating the description.",
        "error"
      );
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(`${baseURL}/og/generate-description`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ type, ogTitle }),
      });

      const data = await response.json();

      if (response.ok) {
        setFormData((prev) => ({
          ...prev,
          ogDescription: data.ogDescription || "Description not generated...",
        }));
        Swal.fire(
          "Success",
          "OG Description generated successfully!",
          "success"
        );
      } else {
        console.error("Error from API:", data.message);
        Swal.fire(
          "Error",
          data.message || "Failed to generate OG Description.",
          "error"
        );
      }
    } catch (error) {
      console.error("Error generating OG description:", error.message);
      Swal.fire(
        "Error",
        "Something went wrong while generating the OG description.",
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.ogFormContainer}>
      <h2 className={styles.ogFormTitle}>Add OG Tag</h2>
      <form onSubmit={handleSubmit} className={styles.ogForm}>
        {/* Meta Type */}
        <div className={styles.ogFormGroup}>
          <label className={styles.ogFormLabel}>Meta Type (Unique):</label>
          <input
            type="text"
            name="type"
            value={formData.type}
            onChange={handleChange}
            required
            className={styles.ogFormInput}
            placeholder="Enter meta type (e.g., home, about-us)"
          />
        </div>

        {/* OG Title */}
        <div className={styles.ogFormGroup}>
          <label className={styles.ogFormLabel}>OG Title:</label>
          <input
            type="text"
            name="ogTitle"
            value={formData.ogTitle}
            onChange={handleChange}
            style={{
              color: formData.ogTitle.length > ogTitleLimit ? "red" : "black",
            }}
            className={styles.ogFormInput}
            placeholder="Enter OG title"
          />
          <small className={styles.ogFormInfoText}>
            {formData.ogTitle.length}/{ogTitleLimit} characters
          </small>
        </div>
        {/* OG Image URL */}
        <div className={styles.ogFormGroup}>
          <label className={styles.ogFormLabel}>OG Image URL:</label>
          <input
            type="text"
            name="ogImage"
            value={formData.ogImage}
            onChange={handleChange}
            className={styles.ogFormInput}
            placeholder="Enter OG image URL"
          />
        </div>

        {/* OG Type */}
        <div className={styles.ogFormGroup}>
          <label className={styles.ogFormLabel}>OG Type:</label>
          <input
            type="text"
            name="ogType"
            value={formData.ogType}
            onChange={handleChange}
            style={{
              color: formData.ogType.length > ogTypeLimit ? "red" : "black",
            }}
            className={styles.ogFormInput}
            placeholder="Enter OG type (e.g., website, article)"
          />
          <small className={styles.ogFormInfoText}>
            {formData.ogType.length}/{ogTypeLimit} characters
          </small>
        </div>
        {/* OG Description */}
        <div className={styles.ogFormGroup}>
          <div className={styles.ogFormGroupTitle}>
            <label className={styles.ogFormLabel}>OG Description:</label>
            <button
              type="button"
              onClick={generateOgDescription}
              className={styles.ogGenerateButton}
              disabled={loading}
            >
              {loading ? "Generating..." : "Generate Description"}
            </button>
          </div>
          <div className={styles.ogInputContainer}>
            <textarea
              name="ogDescription"
              value={formData.ogDescription}
              onChange={handleChange}
              className={styles.ogFormTextarea}
              placeholder="Enter OG description"
              style={{
                color:
                  formData.ogDescription.length > ogDescriptionLimit
                    ? "red"
                    : "black",
              }}
            />
          </div>
          <small className={styles.ogFormInfoText}>
            {formData.ogDescription.length}/{ogDescriptionLimit} characters
          </small>
        </div>

        {/* Add OG Tag */}
        <button
          type="submit"
          className={styles.ogFormSubmitButton}
          disabled={loading}
        >
          Add OG Tag
        </button>
      </form>
    </div>
  );
};

export default AddOgDataForm;
