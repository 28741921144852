import React, { useState, useEffect } from "react";
import { useAuth } from "../Context/AuthContext";
import { useNavigate } from "react-router-dom";
import Header from "../componentbook/HeaderFilesBook/HeaderBook";
import "./DigitalContentBody/AllEbooks/AllEbook.css";
import "./PurchaseBook.css";
import LoadingSpinner from "../Ebook/LoadingSpinner";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function PurchaseBook() {
  const [purchaseHistory, setPurchaseHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [trackingData, setTrackingData] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [error, setError] = useState(null);
  const [currentOrderId, setCurrentOrderId] = useState(null);
  const { isLoggedIn, user } = useAuth();
  const navigate = useNavigate();

  const fetchTrackingData = async (orderId) => {
    try {
      const response = await fetch(
        `https://auth.ssccglpinnacle.com/api/track-order/${orderId}`
      );
  
      if (!response.ok) {
        throw new Error(
          `Failed to fetch tracking data. Status: ${response.status}`
        );
      }
  
      const rawData = await response.json();
  
      const shipmentData = rawData[0]?.tracking_data?.shipment_track[0] || null;
      setTrackingData(shipmentData);
      setCurrentOrderId(orderId);
      setShowDialog(true); 
  
      
      await downloadInvoice(shipmentData?.order_id);
    } catch (error) {
      console.error("Error fetching tracking data:", error);
      setError("Failed to fetch tracking data. Please try again later.");
    }
  };
  

  const downloadInvoice = async (orderId = trackingData?.order_id) => {
    try {
      if (!orderId) {
        alert("Order Confirm but Not Shipped  Yet. Cannot download the invoice.");
        return;
      }
  
      const payload = {
        ids: [orderId],
      };
  
      console.log("Attempting to download invoice with payload:", payload);
  
      const response = await fetch(
        "https://apiv2.shiprocket.in/v1/external/orders/print/invoice",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
         Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjQ5NDAyNDUsInNvdXJjZSI6InNyLWF1dGgtaW50IiwiZXhwIjoxNzQ0ODU5MjU1LCJqdGkiOiJHNTQ4M3VDMjFmVFBBa1VSIiwiaWF0IjoxNzQzOTk1MjU1LCJpc3MiOiJodHRwczovL3NyLWF1dGguc2hpcHJvY2tldC5pbi9hdXRob3JpemUvdXNlciIsIm5iZiI6MTc0Mzk5NTI1NSwiY2lkIjoxMzQxNDY5LCJ0YyI6MzYwLCJ2ZXJib3NlIjpmYWxzZSwidmVuZG9yX2lkIjowLCJ2ZW5kb3JfY29kZSI6InVuaWNvbW1lcmNlIn0.5b_f5XN7yH4RppT4NEkuuc8vVO4_aiaurEO8FvWaPjY`, // Your token
          },
          body: JSON.stringify(payload),
        }
      );
  
      if (!response.ok) {
        const errorText = await response.text();
        console.error("Error response from server:", errorText);
        throw new Error(
          `Failed to download invoice. Status: ${response.status}, Error: ${errorText}`
        );
      }
  
      const result = await response.json();
      const invoiceUrl = result.invoice_url;
  
      if (!invoiceUrl) {
        throw new Error("Invoice URL is missing from the response.");
      }
  
      const pdfResponse = await fetch(invoiceUrl);
      if (!pdfResponse.ok) {
        throw new Error(
          `Failed to fetch the PDF. Status: ${pdfResponse.status}`
        );
      }
  
      const blob = await pdfResponse.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `invoice_${orderId}.pdf`;
      a.click();
      window.URL.revokeObjectURL(url);
  
      console.log("Invoice downloaded successfully.");
    } catch (error) {
      console.error("Error downloading invoice:", error.message);
      alert(
        error.message ||
          "Failed to download the invoice. Please try again later."
      );
    }
  };
  
  useEffect(() => {
    const fetchPurchasedBooks = async () => {
      try {
        if (isLoggedIn && user?.email_id) {
          const response = await fetch(
            `https://auth.ssccglpinnacle.com/api/purchased-books/${user.email_id}`
          );
          if (!response.ok) {
            throw new Error(
              `Failed to fetch purchased books. Status: ${response.status}`
            );
          }

          const purchasedBooks = await response.json();
          setPurchaseHistory(purchasedBooks);
        }
      } catch (error) {
        console.error("Error fetching purchased books:", error);
        setError(
          "Failed to load your purchase history. Please try again later."
        );
      } finally {
        setLoading(false);
      }
    };

    fetchPurchasedBooks();
  }, [isLoggedIn, user]);

  if (loading) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <>
      <Header />
      <h2 className="purchase-heading-books">
        Your purchased books{user?.full_name ? `, ${user.full_name}` : ""}
      </h2>
      {error && <div className="error-message">{error}</div>}
      <div className="container-digital-view-all-link-boks">
        {purchaseHistory.length === 0 ? (
          <div className="no-purchases-container">
            <h3 className="no-purchases-heading">
              You don't have any purchased books yet.
            </h3>
            <p className="no-purchases-text">
              Browse our collection and find your next great read!
            </p>
            <button
              className="browse-books-button"
              onClick={() => navigate("/books")}
            >
              Browse Books
            </button>
          </div>
        ) : (
          <ul className="cards-digital-view-all-link">
            {purchaseHistory.map((purchase) => {
              const { bookDetails, orderId } = purchase;
              if (!bookDetails) return null;

              return (
                <li className="card-digital-view-all-link" key={purchase._id}>
                  <div className="upper-card-digital-view-all-link">
                    <div className="card-content-digital-view-all-link">
                      <div className="for-image-size-view-all-link">
                        <div className="for-giving-margin">
                          <img
                            className="card-title-digital-view-all-link"
                            src={bookDetails.image1}
                            alt={bookDetails.title}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="card-link-wrapper-digital-view-all-link">
                      <p>{bookDetails.title}</p>

                      <div className="card-link-wrapper-digital-view-all-link">
                      <div
  style={{
    display: "flex",
    justifyContent: "center", 
    alignItems: "center",    
    height: "100%",          
  }}
>
  <Button
    className="track-button"
    style={{
      fontSize: "12px",
      padding: "5px 10px",
      minWidth: "80px",
    }}
    onClick={() => fetchTrackingData(orderId)}
  >
    Track Order & Download Invoice
  </Button>
</div>

                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        )}
      </div>

      <Modal
        show={showDialog}
        onHide={() => setShowDialog(false)}
        centered
        size="lg"
        backdrop="static"
        className="tracking-modal"
      >
        <Modal.Header closeButton>
        
        </Modal.Header>
        <Modal.Body>
  {trackingData ? (
    <div className="tracking-container">
      <div className="tracking-header">
        <h2>Track Your Order</h2>
        <div className="current-status">
          <strong>Current Status:</strong> {trackingData.current_status || "N/A"}
        </div>
      </div>
      <div className="tracking-timeline">
        <div
          className={`timeline-step ${
            trackingData.current_status === "AWB Assigned" ? "active" : ""
          }`}
        >
          <div className="timeline-icon">📦</div>
          <div className="timeline-content">
            <p className="timeline-title">Order Placed</p>
            <p className="timeline-description">
              Order ID: {trackingData.order_id || "N/A"}
            </p>
          </div>
        </div>
        <div
          className={`timeline-step ${
            trackingData.current_status === "Shipped" ? "active" : ""
          }`}
        >
          <div className="timeline-icon">🚚</div>
          <div className="timeline-content">
            <p className="timeline-title">Courier Name</p>
            <p className="timeline-description">
              {trackingData.courier_name || "N/A"}
            </p>
          </div>
        </div>
        <div
          className={`timeline-step ${
            trackingData.current_status === "In Transit" ? "active" : ""
          }`}
        >
          <div className="timeline-icon">📍</div>
          <div className="timeline-content">
            <p className="timeline-title">Origin</p>
            <p className="timeline-description">
              {trackingData.origin || "N/A"}
            </p>
          </div>
        </div>
        <div
          className={`timeline-step ${
            trackingData.current_status === "Out for Delivery" ? "active" : ""
          }`}
        >
          <div className="timeline-icon">🏠</div>
          <div className="timeline-content">
            <p className="timeline-title">Destination</p>
            <p className="timeline-description">
              {trackingData.destination || "N/A"}
            </p>
          </div>
        </div>
        <div
          className={`timeline-step ${
            trackingData.current_status === "Delivered" ? "active" : ""
          }`}
        >
          <div className="timeline-icon">✅</div>
          <div className="timeline-content">
            <p className="timeline-title">Delivered To</p>
            <p className="timeline-description">
              {trackingData.delivered_to || "N/A"}
            </p>
          </div>
        </div>
      </div>
      <div className="tracking-summary">
        <h3>Additional Details</h3>
        <p>
          <strong>Estimated Delivery:</strong> {trackingData.edd || "N/A"}
        </p>
        <p>
          <strong>Weight:</strong> {trackingData.weight || "N/A"} kg
        </p>
        <p>
          <strong>POD:</strong> {trackingData.pod || "N/A"}
        </p>
        <p>
          <strong>POD Status:</strong> {trackingData.pod_status || "N/A"}
        </p>
        <p>
          <strong>Invoice:</strong>{" "}
          {trackingData.invoice_url ? (
            <a href={trackingData.invoice_url} target="_blank" rel="noopener noreferrer">
              Download Invoice
            </a>
          ) : (
            "Invoice not available"
          )}
        </p>
      </div>
    </div>
  ) : (
    <p className="no-tracking-data">Ship Not Yet</p>
  )}
</Modal.Body>


        <Modal.Footer>
        <Button
  variant="secondary"
  onClick={() => {
    setShowDialog(false); 
    window.location.reload();
  }}
>
  Close
</Button>

         
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PurchaseBook;











// import React, { useState, useEffect } from "react";
// import { useAuth } from "../Context/AuthContext";
// import { useNavigate } from "react-router-dom";
// import Header from "../componentbook/HeaderFilesBook/HeaderBook";
// import "./DigitalContentBody/AllEbooks/AllEbook.css";
// import "./PurchaseBook.css";
// import LoadingSpinner from "../Ebook/LoadingSpinner";

// function PurchaseBook() {
//   const [purchaseHistory, setPurchaseHistory] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const { isLoggedIn, user } = useAuth();
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchPurchasedBooks = async () => {
//       try {
//         if (isLoggedIn && user && user.email_id) {
//           const response = await fetch(
//             `https://auth.ssccglpinnacle.com/api/purchased-books/${user.email_id}`
//           );
//           if (!response.ok) {
//             throw new Error(`Failed to fetch purchased books. Status: ${response.status}`);
//           }

//           const purchasedBooks = await response.json();
//           setPurchaseHistory(purchasedBooks);
//         }
//       } catch (error) {
//         console.error("Error fetching purchased books:", error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchPurchasedBooks();
//   }, [isLoggedIn, user]);

//   // const handleBookClick = (bookId) => {
//   //   navigate(`/intro-book/${bookId}`);
//   // };

//   if (loading) {
//     return <div><LoadingSpinner/></div>;
//   }

//   return (
//     <>
//       <Header />
//       <h2 className="purchase-heading-books">
//         Your purchased books{user?.full_name ? `, ${user.full_name}` : ""}
//       </h2>
//       <div className="container-digital-view-all-link-boks">
//         {purchaseHistory.length === 0 ? (
//           <div className="no-purchases-container">
//             <h3 className="no-purchases-heading">You don't have any purchased books yet.</h3>
//             <p className="no-purchases-text">Browse our collection and find your next great read!</p>
//             <button 
//               className="browse-books-button" 
//               onClick={() => navigate("/books")}
//             >
//               Browse Books
//             </button>
//           </div>
//         ) : (
//           <ul className="cards-digital-view-all-link">
//             {purchaseHistory.map((purchase) => {
//               const { bookDetails } = purchase;
//               if (!bookDetails) return null;

//               return (
//                 <li className="card-digital-view-all-link" key={purchase._id}>
//                   <div className="upper-card-digital-view-all-link" >
//                     <div className="card-content-digital-view-all-link">
//                       <div className="for-image-size-view-all-link">
//                         <div className="for-giving-margin">
//                         <img
//                           className="card-title-digital-view-all-link"
//                           src={bookDetails.image1}
//                           alt={bookDetails.title}
//                         />
//                       </div></div>
//                     </div>

//                     <div className="card-link-wrapper-digital-view-all-link">
//                       <p>{bookDetails.title} </p>
//                     </div>
//                   </div>
//                 </li>
//               );
//             })}
//           </ul>
//         )}
//       </div>
//     </>
//   );
// }

// export default PurchaseBook;
