import React, { useState, useEffect } from "react";
import { useCart } from "../../Context/CartContext";
import "./Intro.css";
import { useParams, useNavigate } from "react-router-dom";
import Row2intro from "./Row2intro";
import Row3intro from "./Row3intro";
import Row4intro from "./Row4intro";
import ShareModal from "./ShareModal";
import { GiCheckMark } from "react-icons/gi";
import RatingReview from "./RatingReview";
import Row7intro from "./Row7intro";

import Footer from "../../FooterMain/Footer"
import Header from "../../component/HeaderFilesEbook/Header";
import Swal from "sweetalert2";
import { useAuth } from "../../Context/AuthContext";
import LoadingSpinner from "../LoadingSpinner";
import { useCookies } from "react-cookie";
import ReactImageMagnify from 'react-image-magnify';



function Intro({ onClose, onAddToCart }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [cookies] = useCookies(["token", "email_id"]);
  const [loading, setLoading] = useState(true);
  const { cartItems, cartCount, removeItem } = useCart();
  const [isAddingToCart, setIsAddingToCart] = useState(false);
  const { isLoggedIn, user } = useAuth();
  const [isZoomed, setIsZoomed] = useState(false);
  const [infoData, setInfoData] = useState([]);
  const [cardData, setCardData] = useState([]);
  const [selectedInfoData, setselectedInfoData] = useState([]);
  const [selectedCardData, setselectedCardData] = useState([]);
  const [purchase, setPurchase] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(
    "SSC CGL Tier 2 English | 40 Tests"
  );
  const { cardId } = useParams();

  const { category, subject, title } = useParams();
  const { addToCart } = useCart();
  const navigate = useNavigate();

  const formatForComparison = (value) => {
    return value?.toLowerCase().replace(/[-\s]/g, '') || ''; 
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        // Fetch the ebook data
        const ebookResponse = await fetch(
          "https://auth.ssccglpinnacle.com/api/ebooksforactive?active=true"
        );
        if (!ebookResponse.ok) {
          throw new Error(
            `Failed to fetch ebooks. Status: ${ebookResponse.status}`
          );
        }
        const ebookData = await ebookResponse.json();
        setCardData(ebookData);

        // Fetch the book data
        const bookResponse = await fetch(
          "https://auth.ssccglpinnacle.com/api/booksforactive?active=true"
        );
        if (!bookResponse.ok) {
          throw new Error(
            `Failed to fetch books. Status: ${bookResponse.status}`
          );
        }
        const bookData = await bookResponse.json();

// Format the parameters for comparison
const formattedCategory = formatForComparison(category);
const formattedSubject = formatForComparison(subject);
const formattedTitle = formatForComparison(title);

// Find the ebook that matches the parameters
const selectedEbook = ebookData.find((item) => {
  const formattedItemCategory = formatForComparison(item.category);
  const formattedItemSubject = formatForComparison(item.subject);
  const formattedItemTitle = formatForComparison(item.title);

  return (
    formattedItemCategory === formattedCategory &&
    formattedItemSubject === formattedSubject &&
    formattedItemTitle === formattedTitle
  );
});

// Set the selected ebook data
setselectedCardData(selectedEbook ? [selectedEbook] : []);

        // Find the book with matching SKU
        if (selectedEbook) {
          const matchedBook = bookData.find(
            (book) => book.sku === selectedEbook.sku
          );
          if (matchedBook) {
            setselectedInfoData([matchedBook]);
          } else {
            setselectedInfoData([]);
          }
        }

        // console.log("my info data extracted from book", selectedInfoData);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [title]);

  const [imagesfor, setImagesfor] = useState([]);

  useEffect(() => {
    const imagesArray = [
      selectedInfoData[0]?.image1,
      selectedInfoData[0]?.image2,
      selectedInfoData[0]?.image3,
      selectedInfoData[0]?.image4,
      selectedInfoData[0]?.image5,
    ].filter((image) => image !== undefined);

    setImagesfor(imagesArray);
  }, [selectedInfoData]);

  const [selectedImagefor, setSelectedImagefor] = useState(null);

  useEffect(() => {
    setSelectedImagefor(imagesfor[0] || null);
  }, [imagesfor]);

  const handleThumbnailClick = (image1) => {
    setSelectedImagefor(image1);
  };

  const handleAddToCart = async (ebookId) => {
    try {
      if (!isLoggedIn || !user || !user._id) {
        Swal.fire({
          icon: "info", // You can change this to 'info', 'success', 'warning', etc.
          title: "Note",
          text: "Please log in to add items to your cart.",
        });
        return;
      }

      setIsAddingToCart(true);

      const response = await fetch(
        `https://auth.ssccglpinnacle.com/api/add-to-cart-ebook/${user.email_id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.token}`,
          },
          body: JSON.stringify({ ebookId }),
        }
      );

      if (response.ok) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Item added to cart successfully!",
        });
      } else {
        const errorMessage = await response.json();
        console.error("Error adding to cart:", errorMessage);

        if (errorMessage.error === "Item already in the cart") {
          navigate("/ebooks/cart");
        // } else {
        //   Swal.fire({
        //     icon: "error",
        //     title: "Error",
        //     text: `Failed to add item to cart. Error: ${errorMessage.error}`,
        //   });
        }
      }
    } catch (error) {
      console.error("Error adding to cart:", error);
      Swal.fire({
        icon: "info", // You can change this to 'info', 'success', 'warning', etc.
        title: "Note",
        text: "Failed to add item to cart. Please try again.",
      });
    } finally {
      setIsAddingToCart(false);
    }
  };

  useEffect(() => {
    const fetchPurchaseHistory = async () => {
      try {
        const purchaseHistoryResponse = await fetch(
          `https://auth.ssccglpinnacle.com/api/purchase-history-ebook/${user.email_id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${cookies.token}`,
            },
          }
        );
        const purchaseHistoryData = await purchaseHistoryResponse.json();
        setPurchase(purchaseHistoryData.ebooksPurchased || []); // Ensure it's always an array
      } catch (error) {
        console.error("Error fetching purchase history:", error);
        setPurchase([]); // Ensure it's always an array in case of error
      }
    };

    fetchPurchaseHistory();
  }, [user]);

  console.log("mypurchase", purchase);

  if (loading) {
    return <LoadingSpinner />;
  }
  const calculateDiscount = (sellingPrice, printingPrice) => {
    const savingAmount = printingPrice - sellingPrice;
    const discountPercentage = (savingAmount / printingPrice) * 100;
    return { savingAmount, discountPercentage };
  };

  const twelveMonthSubscription =
    selectedCardData.length > 0
      ? selectedCardData[0].subscriptionPrices.find(
          (sub) => sub.duration === "12 Months"
        )
      : { printingPrice: 0, sellingPrice: 0 };

  const { savingAmount, discountPercentage } = calculateDiscount(
    twelveMonthSubscription.sellingPrice || 0,
    twelveMonthSubscription.printingPrice || 0
  );

  const currentOriginalPrice = twelveMonthSubscription.printingPrice;
  const currentDiscountedPrice = twelveMonthSubscription.sellingPrice;

  let valuePrice = currentOriginalPrice - currentDiscountedPrice;

  const handleMouseEnter = () => {
    setIsZoomed(true);
  };

  const handleMouseLeave = () => {
    setIsZoomed(false);
  };

  return (
    <>
      <div className="main-body-container">
        <Header />
        <div className="container-body-for-intro">
          <div className="for-intro-row1-image-introproduct">
            <div className="row1-for-intro">
              <div className="for-image-intro">
                <div className="image-container">
                  <div className="image-container-book-show">
                    {selectedImagefor ? (
                      <ReactImageMagnify
                        {...{
                          smallImage: {
                            alt: "Selected",
                            isFluidWidth: true,
                            src: selectedImagefor,
                          },
                          largeImage: {
                            src: selectedImagefor,
                            width: 1200,
                            height: 1800,
                          },
                          enlargedImageContainerDimensions: {
                            width: "250%",
                            height: "200%",
                          },
                        }}
                      />
                    ) : (
                      <div className="placeholder">Select an image</div>
                    )}
                  </div>
                </div>
                <div className="thumbnails">
                  {imagesfor.map((image, index) => (
                    <img
                      key={index}
                      src={image}
                      alt={`Thumbnail ${index + 1}`}
                      className="thumbnail"
                      onClick={() => handleThumbnailClick(image)}
                    />
                  ))}
                </div>
              </div>
            </div>
            <div className="row2-for-intro">
              <div className="name-of-the-product">
                <h2>
                  {selectedCardData.length > 0 && selectedCardData[0].title}{" "}
                  {/* {selectedCardData.length > 0 && selectedCardData[0].edition}{" "}
                  edition {selectedCardData.length > 0 && selectedCardData[0].medium}{" "}
                  medium */}
                </h2>
              </div>
              <div className="price-details-intro">
                <div className="price-for-billing">
                  <span className="price-for-billing__selling-price">
                    ₹{currentDiscountedPrice} incl. GST
                  </span>
                  <div className="price-for-billing__details">
                    <span className="price-for-billing__mrp">
                      M.R.P.: <del>₹{currentOriginalPrice}</del>
                    </span>
                    <span className="price-for-billing__saving-amount">
                      Save: ₹{savingAmount.toFixed(2)} (
                      {discountPercentage.toFixed(0)}%)
                    </span>
                  </div>
                </div>

                <div className="Value-deal">
                  <p>
                    VALUE DEAL! Save &nbsp;
                    <span
                      className="money"
                      doubly-currency-usd="15500"
                      doubly-currency-inr="1281207.5290036453"
                      doubly-currency="INR"
                    >
                      ₹{valuePrice}
                    </span>
                  </p>
                </div>
                <div className="share-product">
                  <ShareModal />
                </div>
              </div>
              <div className="intro-of-the-product">
                <p className="intro-1">
                  {selectedInfoData.length > 0 && selectedInfoData[0].para1}
                </p>
                <div className="intro-2">
                  <GiCheckMark className="tick" />
                  {selectedInfoData.length > 0 && selectedInfoData[0].para2}
                </div>
                <div className="intro-2">
                  <GiCheckMark className="tick" />
                  {selectedInfoData.length > 0 && selectedInfoData[0].para3}
                </div>
                <div className="intro-2">
                  <GiCheckMark className="tick" />
                  {selectedInfoData.length > 0 && selectedInfoData[0].para4}
                </div>
              </div>
              <div className="for-duration-buy-modal-intro">
                {Array.isArray(purchase) &&
                purchase.some((item) => item._id === cardId) ? (
                  <div className="for-purchase-card-info">
                    <p>This product is already purchased</p>
                  </div>
                ) : (
                  <>
                    <div
                      className="add-to-cart-buy-modal-intro"
                      onClick={() => handleAddToCart(cardId)}
                    >
                      Add to cart
                    </div>
                    <div className="buy-it-now-from-buy-model-intro">
                      <button
                        id="buyButton-intro"
                        onClick={() => handleAddToCart(cardId)}
                      >
                        Buy It Now
                      </button>
                    </div>
                  </>
                )}
              </div>
              <div className="show-preview-pdf-book">
                <div className="preview-book-pdf-title">Preview</div>
                <div className="show preview">
                  <div className="pdf-showpreview">
                    <div className="pdf1-preview">
                      {selectedInfoData.length > 0 && selectedInfoData[0].pdf1 && (
                        <a
                          href={selectedInfoData[0].pdf1}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Preview 1
                        </a>
                      )}
                    </div>
                    <div className="pdf2-preview">
                      {selectedInfoData.length > 0 && selectedInfoData[0].pdf2 && (
                        <a
                          href={selectedInfoData[0].pdf2}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Preview 2
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Row2intro />
        </div>
        <Row3intro
          currentOriginalPrice={currentOriginalPrice}
          currentDiscountedPrice={currentDiscountedPrice}
          selectedCardData={selectedCardData}
        />
        <Row4intro />
        <RatingReview />
        <Row7intro />
        <Footer />
      </div>
    </>
  );
}

export default Intro;
