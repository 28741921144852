import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./NoResults.module.css";

const NoResults = ({ query }) => {
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <div className={styles.noResultsContainer}>
      <div className={styles.mainContent}>
        <div className={styles.imageContainer}>
          <img
            src="https://d3m4h509gttb94.cloudfront.net/frontend_images/noResultFound.webp"
            alt="No Results Found"
            className={styles.noResultsImage}
          />
        </div>

        <div className={styles.mainMessage}>
          <h2>
            No results found for <span className={styles.query}>{query}</span>
          </h2>
          <p className={styles.suggestionText}>
            Unfortunately, we couldn’t find any matches for <strong>{query}</strong>. <br />
            Please refine your search or explore the suggestions below.
          </p>
        </div>
      </div>
      <div className={styles.refineSearch}>
        <h3>Refine your search:</h3>
        <ul>
          <li>Make sure that all words are spelled correctly.</li>
          <li>Search for one product at a time.</li>
          <li>Use two to three keywords for better results.</li>
          <li>Try using different keywords.</li>
          <li>Use filters for better results.</li>
        </ul>
      </div>

      <div className={styles.suggestions}>
        <h3>You may be interested in:</h3>
        <div className={styles.suggestionCards}>
          <div
            className={styles.card}
            onClick={() => window.open("https://testportal.ssccglpinnacle.com/", "_blank")}
          >
            <img
              src="https://d3m4h509gttb94.cloudfront.net/frontend_images/testPortal.webp"
              alt="Test Portal"
            />
            <p>Test Portal</p>
          </div>
          <div className={styles.card} onClick={() => handleNavigate("/books-catalog")}>
            <img
              src="https://d3m4h509gttb94.cloudfront.net/frontend_images/booksImage.webp"
              alt="Books"
            />
            <p>Books</p>
          </div>
          <div className={styles.card} onClick={() => handleNavigate("/ebooks")}>
            <img
              src="https://d3m4h509gttb94.cloudfront.net/frontend_images/eBooksImage.webp"
              alt="Ebooks"
            />
            <p>Ebooks</p>
          </div>
          <div className={styles.card} onClick={() => handleNavigate("/videos")}>
            <img
              src="https://d3m4h509gttb94.cloudfront.net/frontend_images/videoCourseImage.webp"
              alt="Video Courses"
            />
            <p>Video Courses</p>
          </div>
          <div className={styles.card} onClick={() => handleNavigate("/typing")}>
            <img
              src="https://d3m4h509gttb94.cloudfront.net/frontend_images/typingImage.webp"
              alt="Typing Software"
            />
            <p>Typing Software</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoResults;


// import React from "react";
// import { useNavigate } from "react-router-dom";
// import styles from "./NoResults.module.css";

// const NoResults = ({ query }) => {
//   const navigate = useNavigate();

//   const handleNavigate = (path) => {
//     navigate(path);
//   };

//   return (
//     <div className={styles.noResultsContainer}>
//       {/* Image Section */}
//       <div className={styles.imageContainer}>
//         <img
//           src="https://d3m4h509gttb94.cloudfront.net/frontend_images/noResultFound.webp" // Replace with a meaningful image URL
//           alt="No Results Found"
//           className={styles.noResultsImage}
//         />
//       </div>

//       {/* Main Message */}
//       <div className={styles.mainMessage}>
//         <h2>No results found for <span className={styles.query}>{query}</span></h2>
//         <p className={styles.suggestionText}>
//           Unfortunately, we couldn’t find any matches for <strong>{query}</strong>. <br />
//           Please refine your search or explore the suggestions below.
//         </p>
//       </div>

//       {/* Refine Your Search Steps */}
//       <div className={styles.refineSearch}>
//         <h3>Refine your search:</h3>
//         <ul>
//           <li>Make sure that all words are spelled correctly.</li>
//           <li>Search for one product or service at a time.</li>
//           <li>Use two to three keywords for better results.</li>
//           <li>Try using different keywords.</li>
//           <li>Avoid overly specific keywords (e.g., "20x25 mm stone tiles").</li>
//         </ul>
//       </div>

//       {/* Suggested Categories */}
//       <div className={styles.suggestions}>
//         <h3>You may be interested in:</h3>
//         <div className={styles.suggestionCards}>
//           <div
//             className={styles.card}
//             onClick={() => window.open("https://testportal.ssccglpinnacle.com/", "_blank")}
//           >
//             <img src="https://d3m4h509gttb94.cloudfront.net/frontend_images/testPortal.webp" alt="Test Portal" />
//             <p>Test Portal</p>
//           </div>
//           <div className={styles.card} onClick={() => handleNavigate("/books-catalog")}>
//             <img src="https://d3m4h509gttb94.cloudfront.net/frontend_images/booksImage.webp" alt="Books" />
//             <p>Books</p>
//           </div>
//           <div className={styles.card} onClick={() => handleNavigate("/ebooks")}>
//             <img src="https://d3m4h509gttb94.cloudfront.net/frontend_images/eBooksImage.webp" alt="Ebooks" />
//             <p>Ebooks</p>
//           </div>
//           <div className={styles.card} onClick={() => handleNavigate("/videos")}>
//             <img src="https://d3m4h509gttb94.cloudfront.net/frontend_images/videoCourseImage.webp" alt="Video Courses" />
//             <p>Video Courses</p>
//           </div>
//           <div className={styles.card} onClick={() => handleNavigate("/typing")}>
//             <img src="https://d3m4h509gttb94.cloudfront.net/frontend_images/typingImage.webp" alt="Typing Software" />
//             <p>Typing Software</p>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default NoResults;
