// import React, { useState, useEffect } from 'react';
// import './ReviewCard.css';
// import CustomStarRating from './CustomStarRating';
// import { useParams } from 'react-router-dom';

// const ReviewCard = ({ filterOption }) => {
//   const { cardId } = useParams();
//   const [reviewData, setReviewData] = useState([]);
//   const [visibleReviews, setVisibleReviews] = useState(6); // Initially display 6 reviews
//   const [filteredReviews, setFilteredReviews] = useState([]);
//   const [transformedReviews, setTransformedReviews] = useState([]); // Declare here

//   useEffect(() => {
//     const fetchReviews = async (cardId) => {
//       try {
//         const response = await fetch(`https://auth.ssccglpinnacle.com/api/reviews-ebook/${cardId}`);
//         if (!response.ok) {
//           throw new Error(`Failed to fetch reviews. Status: ${response.status}`);
//         }
//         const data = await response.json();
//         setReviewData(data.ratings);
//       } catch (error) {
//         console.error('Error fetching reviews:', error);
//       }
//     };

//     fetchReviews(cardId);
//   }, [cardId]);

//   useEffect(() => {
//     const transformedReviews = reviewData.map((review) => ({
//       userName: `${review.firstName} ${review.lastName}`,
//       reviewDate: new Date(review.ratingDate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }),
//       starRating: review.starRate,
//       reviewCourse: 'SSC Aptitude Book',
//       reviewText: review.writtenReview,
//     }));

//     setTransformedReviews(transformedReviews);
//     setFilteredReviews(transformedReviews);
//   }, [reviewData]);

//   useEffect(() => {
//     filterReviews(filterOption);
//   }, [filterOption]);

//   const filterReviews = (option) => {
//     let filteredReviewsCopy = [...transformedReviews];

//     if (option === 'Newest') {
//       filteredReviewsCopy.sort((a, b) => new Date(b.reviewDate) - new Date(a.reviewDate));
//     } else if (option === 'Highest Rating') {
//       filteredReviewsCopy.sort((a, b) => b.starRating - a.starRating);
//     } else if (option === 'Lowest Rating') {
//       filteredReviewsCopy.sort((a, b) => a.starRating - b.starRating);
//     } else if (option === 'Featured') {
//       const featuredReviews = transformedReviews.filter((review) => review.starRating === 5);
//       filteredReviewsCopy = [...featuredReviews];
//     }

//     setFilteredReviews(filteredReviewsCopy);
//   };

//   const handleShowMore = () => {
//     // Increase the number of visible reviews by 2 (or any other desired increment)
//     setVisibleReviews((prevVisibleReviews) => prevVisibleReviews + 2);
//   };

//   return (
//     <div className="app-for">
//      {filteredReviews.slice(0, 8 + visibleReviews).map((review, index) => (
//         <div className="review-card" key={index}>
//           <div className="contain">
//             <div className="user-review-name"><p>{review.userName}</p></div>
//             <div className="user-review-date"><p>{review.reviewDate}</p></div>
//             <div className="user-review-star-rate">
//               <CustomStarRating rating={review.starRating} />
//             </div>
//             <div className="user-given-reviews"><p>{review.reviewText}</p></div>
//           </div>
//         </div>
//       ))}
//       {visibleReviews < filteredReviews.length && (
//         <button className="show-more-button" onClick={handleShowMore}>
//           Show More
//         </button>
//       )}
//     </div>
//   );
// };

// export default ReviewCard;


import React, { useState, useEffect } from 'react';
import './ReviewCard.css';
import CustomStarRating from './CustomStarRating';
import { useParams } from 'react-router-dom';

const ReviewCard = ({ filterOption }) => {
  const { category, subject, title } = useParams(); // Get parameters from the URL
  const [reviewData, setReviewData] = useState([]);
  const [visibleReviews, setVisibleReviews] = useState(6); // Initially display 6 reviews
  const [filteredReviews, setFilteredReviews] = useState([]);
  const [transformedReviews, setTransformedReviews] = useState([]);
  const [cardId, setCardId] = useState(null); // Dynamically resolve cardId

  // Utility function to format strings for comparison
  const formatForComparison = (value) =>
    value?.toLowerCase().replace(/[-\s]/g, '') || '';

  // Fetch ebook data and resolve cardId
  useEffect(() => {
    const fetchEbookData = async () => {
      try {
        const ebookResponse = await fetch(
          "https://auth.ssccglpinnacle.com/api/ebooksforactive?active=true"
        );
        if (!ebookResponse.ok) {
          throw new Error(
            `Failed to fetch ebooks. Status: ${ebookResponse.status}`
          );
        }

        const ebookData = await ebookResponse.json();

        // Format URL parameters for comparison
        const formattedCategory = formatForComparison(category);
        const formattedSubject = formatForComparison(subject);
        const formattedTitle = formatForComparison(title);

        // Find the matching ebook
        const matchingEbook = ebookData.find((item) => {
          const formattedItemCategory = formatForComparison(item.category);
          const formattedItemSubject = formatForComparison(item.subject);
          const formattedItemTitle = formatForComparison(item.title);

          return (
            formattedItemCategory === formattedCategory &&
            formattedItemSubject === formattedSubject &&
            formattedItemTitle === formattedTitle
          );
        });

        if (matchingEbook) {
          setCardId(matchingEbook._id); // Set the resolved cardId
        }
      } catch (error) {
        console.error("Error fetching ebook data:", error);
      }
    };

    fetchEbookData();
  }, [category, subject, title]);

  // Fetch reviews based on resolved cardId
  useEffect(() => {
    const fetchReviews = async (resolvedCardId) => {
      try {
        const response = await fetch(
          `https://auth.ssccglpinnacle.com/api/reviews-ebook/${resolvedCardId}`
        );
        if (!response.ok) {
          throw new Error(`Failed to fetch reviews. Status: ${response.status}`);
        }
        const data = await response.json();
        setReviewData(data.ratings);
      } catch (error) {
        console.error('Error fetching reviews:', error);
      }
    };

    if (cardId) {
      fetchReviews(cardId);
    }
  }, [cardId]);

  // Transform review data
  useEffect(() => {
    const transformedReviews = reviewData.map((review) => ({
      userName: `${review.firstName} ${review.lastName}`,
      reviewDate: new Date(review.ratingDate).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }),
      starRating: review.starRate,
      reviewCourse: 'SSC Aptitude Book',
      reviewText: review.writtenReview,
    }));

    setTransformedReviews(transformedReviews);
    setFilteredReviews(transformedReviews);
  }, [reviewData]);

  // Filter reviews based on filter option
  useEffect(() => {
    filterReviews(filterOption);
  }, [filterOption]);

  const filterReviews = (option) => {
    let filteredReviewsCopy = [...transformedReviews];

    if (option === 'Newest') {
      filteredReviewsCopy.sort(
        (a, b) => new Date(b.reviewDate) - new Date(a.reviewDate)
      );
    } else if (option === 'Highest Rating') {
      filteredReviewsCopy.sort((a, b) => b.starRating - a.starRating);
    } else if (option === 'Lowest Rating') {
      filteredReviewsCopy.sort((a, b) => a.starRating - b.starRating);
    } else if (option === 'Featured') {
      const featuredReviews = transformedReviews.filter(
        (review) => review.starRating === 5
      );
      filteredReviewsCopy = [...featuredReviews];
    }

    setFilteredReviews(filteredReviewsCopy);
  };

  const handleShowMore = () => {
    // Increase the number of visible reviews by 2 (or any other desired increment)
    setVisibleReviews((prevVisibleReviews) => prevVisibleReviews + 2);
  };

  return (
    <div className="app-for">
      {filteredReviews.slice(0, visibleReviews).map((review, index) => (
        <div className="review-card" key={index}>
          <div className="contain">
            <div className="user-review-name">
              <p>{review.userName}</p>
            </div>
            <div className="user-review-date">
              <p>{review.reviewDate}</p>
            </div>
            <div className="user-review-star-rate">
              <CustomStarRating rating={review.starRating} />
            </div>
            <div className="user-given-reviews">
              <p>{review.reviewText}</p>
            </div>
          </div>
        </div>
      ))}
      {visibleReviews < filteredReviews.length && (
        <button className="show-more-button" onClick={handleShowMore}>
          Show More
        </button>
      )}
    </div>
  );
};

export default ReviewCard;
