import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const RedirectToApp = () => {
  const location = useLocation();

  useEffect(() => {
    const pathname = location.pathname + location.search;
    const appIntentLink = `intent://ssccglpinnacle.com${pathname}#Intent;scheme=https;package=com.ssccgl.pinnacle.testportal;end`;

    window.location.href = appIntentLink;

    // Fallback after 2 seconds if nothing happens
    setTimeout(() => {
      window.location.href =
        'https://play.google.com/store/apps/details?id=com.ssccgl.pinnacle.testportal';
    }, 2000);
  }, [location]);

  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <h3>Redirecting...</h3>
      <p>
        If nothing happens,{" "}
        <a
          href="https://play.google.com/store/apps/details?id=com.ssccgl.pinnacle.testportal"
        >
          click here to download the app
        </a>
        .
      </p>
    </div>
  );
};

export default RedirectToApp;


// import { useEffect } from 'react';

// const RedirectToApp = () => {
//   useEffect(() => {
//     window.location.href =
//       'https://play.google.com/store/apps/details?id=com.ssccgl.pinnacle.testportal';
//   }, []);

//   return null;
// };

// export default RedirectToApp;


// import { useEffect } from 'react';
// import { useLocation } from 'react-router-dom';

// const RedirectToApp = () => {
//   const location = useLocation();

//   useEffect(() => {
//     const appLink = `https://ssccglpinnacle.com${location.pathname}${location.search}`;

//     const playStoreLink = 'https://play.google.com/store/apps/details?id=com.ssccgl.pinnacle.testportal';

//     const start = Date.now();

//     // Attempt to open the app via deep-link
//     window.location.href = appLink;

//     // Fallback if app not installed after 2 seconds
//     setTimeout(() => {
//       if (Date.now() - start < 2100) {
//         window.location.href = playStoreLink;
//       }
//     }, 2000);
//   }, [location]);

//   return (
//     <div style={{ textAlign: "center", marginTop: "50px" }}>
//       <h3>Redirecting...</h3>
//       <p>If nothing happens, <a href="https://play.google.com/store/apps/details?id=com.ssccgl.pinnacle.testportal">click here to download the app</a>.</p>
//     </div>
//   );
// };

// export default RedirectToApp;
