import React, { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { AiFillStar, AiFillHeart } from "react-icons/ai";
import { FcApproval } from "react-icons/fc";
import { useAuth } from "../../../Context/AuthContext";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import "./Row3Digital.css";
import "./NewRow4.css";
import { useCookies } from "react-cookie";
import Rate from "../../../component/Star/Rate";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import pic from '../../i/book.jpg';
import gsap from "gsap";



function Row4Digital({ filteredEbookData }) {
  let scrl = useRef(null);
  const [scrollX, setscrollX] = useState(10);
  const [scrolEnd, setscrolEnd] = useState(false);

  const slide = (shiftMultiplier) => {
    const cardWidth = scrl.current.querySelector('.card-digital-row4-horizontal-ebook').offsetWidth; // Width of one card
    const shift = cardWidth * 5 * shiftMultiplier; // Calculate the scroll distance for 4 cards
    let targetScroll = scrl.current.scrollLeft + shift;
    
    // Calculate the maximum scrollable position
    const maxScroll = scrl.current.scrollWidth - scrl.current.offsetWidth;
    
    // Clamp the target scroll to not exceed the maximum
    if (targetScroll > maxScroll) {
      targetScroll = maxScroll;
    }
    
    if (targetScroll < 0) {
      targetScroll = 0;
    }
    
    // Apply GSAP animation to scroll to the target position smoothly
    gsap.to(scrl.current, {
      scrollLeft: targetScroll,
      duration: 0.6,
      ease: "power2.inOut",
      onComplete: () => {
        setscrollX(scrl.current.scrollLeft);
    
        // Update the state to determine if the scroll has reached the end
        if (scrl.current.scrollLeft >= maxScroll) {
          setscrolEnd(true);
        } else {
          setscrolEnd(false);
        }
      },
    });
  };
  
  
  
  
  
  const scrollCheck = () => {
    setscrollX(scrl.current.scrollLeft);
  
    // Check if the scroll has reached the end
    if (Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <= scrl.current.offsetWidth) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };
  
  const [cardData, setCardData] = useState([]);
  const [cookies] = useCookies(["email_id", "token"]);
  const { isLoggedIn, user } = useAuth();
  const [purchaseHistory, setPurchaseHistory] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [showBuyNowModal, setShowBuyNowModal] = useState(false);
  const [hoveredId, setHoveredId] = useState(null);
  const [StarData, setStarData] = useState([]);
  const [isAddingToCart, setIsAddingToCart] = useState(false);
  const [wishlist, setWishlist] = useState([]);
  const [successMessage1, setSuccessMessage1] = useState(null);
  const [cartItems, setCartItems] = useState([]);

  const handleMouseOver = useCallback((_id) => {
    setHoveredId(_id);
  }, []);

  const handleMouseOut = useCallback(() => {
    setHoveredId(null);
  }, []);

  useEffect(() => {
    console.log("my user data book", user);
  }, [user]);

  useEffect(() => {
    const fetchEbooksData = async () => {
      try {
        const ebookResponse = await fetch("https://auth.ssccglpinnacle.com/api/ebooksforactive?active=true");
        if (!ebookResponse.ok) {
          throw new Error(`Failed to fetch ebooks. Status: ${ebookResponse.status}`);
        }
        console.log("ebookResponse",ebookResponse);
        // const ebookData = await ebookResponse.json();
        // setCardData(filteredEbookData && filteredEbookData.length > 0 ? filteredEbookData : ebookData);
        const ebookData = await ebookResponse.json();
        // setCardData(filteredEbookData && filteredEbookData.length > 0 ? filteredEbookData : ebookData);
        const dataToProcess = filteredEbookData && filteredEbookData.length > 0 ? filteredEbookData : ebookData;
        const sortedData = [...dataToProcess].sort((a, b) => a.order - b.order);
       console.log("sortedData",sortedData);
       console.log("dataToProcess",dataToProcess);
       console.log("ebookData",ebookData);
  
        setCardData(sortedData);
        setStarData(ebookData.map((ebook) => ebook.rating || []).flat());
      } catch (error) {
        console.error("Error fetching ebook data:", error);
      }
    };
    fetchEbooksData();
  }, [filteredEbookData]);

  useEffect(() => {
    const fetchPurchaseHistory = async () => {
      if (isLoggedIn && user && user._id) {
        try {
          const purchaseHistoryResponse = await fetch(`https://auth.ssccglpinnacle.com/api/purchase-history-ebook/${user.email_id}`, {
            headers: {
              "Content-Type": "application/json",
              "Accept": "application/json",
              "Authorization": `Bearer ${cookies.token}`
            }
          });
          if (!purchaseHistoryResponse.ok) {
            throw new Error(`Failed to fetch purchase history. Status: ${purchaseHistoryResponse.status}`);
          }
          const purchaseHistoryData = await purchaseHistoryResponse.json();
          setPurchaseHistory(purchaseHistoryData.ebooksPurchased || []);
        } catch (error) {
          console.error("Error fetching purchase history:", error);
        }
      }
    };
    fetchPurchaseHistory();
  }, [isLoggedIn, user, cookies.token]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const queryCardId = queryParams.get("cardId");

    const checkUserPurchased = async () => {
      if (isLoggedIn && user && user._id && queryCardId) {
        try {
          const purchaseHistoryResponse = await fetch(`https://auth.ssccglpinnacle.com/api/purchase-history-ebook/${user.email_id}`, {
            headers: {
              "Content-Type": "application/json",
              "Accept": "application/json",
              "Authorization": `Bearer ${cookies.token}`
            }
          });
          if (purchaseHistoryResponse.ok) {
            const purchaseHistoryData = await purchaseHistoryResponse.json();
            const hasPurchased = purchaseHistoryData.some((purchase) => purchase.ebooksPurchased.ebook._id === queryCardId);

            setShowBuyNowModal(!hasPurchased);
            document.body.style.overflow = hasPurchased ? "" : "hidden";
          }
        } catch (error) {
          console.error("Error checking user purchase history:", error);
        }
      }
    };
    checkUserPurchased();
  }, [isLoggedIn, user, location]);

  const closeModal = () => {
    setShowBuyNowModal(false);
    document.body.style.overflow = "";
  };

  const formatTitleForUrl = (title) => {
    return title.toLowerCase().replace(/\s+/g, '-');
  };

  const info = (category, subject, title) => {
    const formattedExamCategory = formatTitleForUrl(category);
    const formattedSubjects = formatTitleForUrl(subject);
    const formattedTitle = formatTitleForUrl(title);
    navigate(`/ebooks/${formattedExamCategory}/${formattedSubjects}/${formattedTitle}`);
};

  const viewAll = () => {
    navigate("/view-all");
  };

  const isEbookPurchased = useCallback((ebookId) => {
    return isLoggedIn && Array.isArray(purchaseHistory) && purchaseHistory.some((ebook) => ebook._id === ebookId);
  }, [isLoggedIn, purchaseHistory]);

  const handleAddToCart = async (ebookId) => {
    if (!isLoggedIn || !user || !user._id) {
      Swal.fire({
        icon: "info", // You can change this to 'info', 'success', 'warning', etc.
        title: "Note",
        text: "Please log in to add items to your cart.",
      });
      return;
    }

    setIsAddingToCart(true);

    try {
      const response = await fetch(`https://auth.ssccglpinnacle.com/api/add-to-cart-ebook/${user.email_id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": `Bearer ${cookies.token}`
        },
        body: JSON.stringify({ ebookId, fullName: user.email_id }),
      });

      if (response.ok) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Item added to cart successfully!",
        });
        navigate("/ebooks/cart");
      } else {
        const errorMessage = await response.json();
        console.error("Error adding to cart:", errorMessage);

        if (errorMessage.error === "Item already in the cart") {
          navigate("/ebooks/cart");
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `Failed to add item to cart. Error: ${errorMessage.error}`,
          });
        }
      }
    } catch (error) {
      console.error("Error adding to cart:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to add item to cart. Please try again.",
      });
    } finally {
      setIsAddingToCart(false);
    }
  };

  const handleProductDetailsClick = (card) => {
    const matchingPurchase = purchaseHistory.find((purchase) => purchase._id === card._id);

    if (matchingPurchase) {
      navigate(`/ebook/${card._id}/digitalMainPage`);
    } else {
      Swal.fire({
        icon: "info",
        title: "Message",
        text: "You must buy an ebook to see the details.",
      });
    }
  };

  const isTryForFree = useCallback((ebookId) => {
    const ebook = cardData.find((card) => card._id === ebookId);
    return ebook?.free?.some((entry) => entry.user === user?._id && entry.tryForFree);
  }, [cardData, user]);

  const getRatingForCard = useCallback((cardId) => {
    const card = cardData.find((card) => card._id === cardId);
    return card && card.rating && card.rating.length > 0 ? calculateAverageRating(card.rating) : 0;
  }, [cardData]);

  const calculateAverageRating = (ratings) => {
    const totalRating = ratings.reduce((sum, rating) => sum + rating.starRate, 0);
    return totalRating / ratings.length;
  };

  const handleWishlistClick = async (event, cardId) => {
    event.stopPropagation();

    if (!isLoggedIn || !user || !user._id) {
      Swal.fire({
        icon: "info", // You can change this to 'info', 'success', 'warning', etc.
        title: "Note",
        text: "Please log in to add items to your wishlist.",
      });
      return;
    }

    const updatedWishlist = isWishlistItem(cardId) ? wishlist.filter(id => id !== cardId) : [...wishlist, cardId];
    setWishlist(updatedWishlist);

    try {
      const response = await fetch(`https://auth.ssccglpinnacle.com/api/add-to-wishlist-ebook/${user.email_id}`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": `Bearer ${cookies.token}`
        },
        body: JSON.stringify({ cardId }),
      });

      if (!response.ok) {
        console.error('Failed to update wishlist. Server response:', await response.text());
      }
    } catch (error) {
      console.error('Error updating wishlist:', error);
    }
  };

  useEffect(() => {
    const fetchWishlist = async () => {
      if (!user || !user._id) {
        console.error('User is null or _id is undefined.');
        return;
      }

      try {
        const response = await fetch(`https://auth.ssccglpinnacle.com/api/get-wishlist-ebook/${user.email_id}`, {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": `Bearer ${cookies.token}`
          },
        });

        if (response.ok) {
          const data = await response.json();
          setWishlist(data.wishlist || []);
        } else {
          console.error('Failed to fetch wishlist:', await response.text());
        }
      } catch (error) {
        console.error('Error fetching wishlist:', error);
      }
    };
    fetchWishlist();
  }, [user, cardData]);

  const handleRemoveFromWishlist = async (event, cardId) => {
    event.stopPropagation();

    if (!isLoggedIn || !user || !user._id) {
      Swal.fire({
        icon: "info", // You can change this to 'info', 'success', 'warning', etc.
        title: "Note",
        text: "Please log in to remove items from your wishlist.",
      });
      return;
    }

    const updatedWishlist = wishlist.filter(id => id !== cardId);
    setWishlist(updatedWishlist);

    try {
      const response = await fetch(`https://auth.ssccglpinnacle.com/api/remove-from-wishlist-ebook/${user.email_id}`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": `Bearer ${cookies.token}`
        },
        body: JSON.stringify({ cardId }),
      });

      if (!response.ok) {
        console.error('Failed to remove from wishlist. Server response:', await response.text());
      }
    } catch (error) {
      console.error('Error removing from wishlist:', error);
    }
  };

  const isWishlistItem = useCallback((cardId) => wishlist.includes(cardId), [wishlist]);

  useEffect(() => {
    const userId = user?.id;
    const useremail = user?.email_id;
    if (userId) {
      fetch(`https://auth.ssccglpinnacle.com/api/cart-ebook/${useremail}`, {
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": `Bearer ${cookies.token}`
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setCartItems(Array.isArray(data) ? data : []);
        })
        .catch((error) => console.error("Error fetching cart data:", error));
    }
  }, [user]);

  const isItemInCart = useCallback((productId) => {
    return Array.isArray(cartItems) && cartItems.some((item) => item.product && item.product._id === productId);
  }, [cartItems]);

  useEffect(() => {
    // Clean up success message after 3 seconds
    const timeoutId = setTimeout(() => {
      setSuccessMessage1(null);
    }, 2000);

    return () => clearTimeout(timeoutId);
  }, [successMessage1]);


  const calculateDiscount = (sellingPrice, printingPrice) => {
    const savingAmount = printingPrice - sellingPrice;
    const discountPercentage = (savingAmount / printingPrice) * 100;
    return { savingAmount, discountPercentage };
  };

  return (
    <>
      <div className="section-suggest-book-p-recent-horizontal-ebook">
        <div className="container-fluid-book-recent-horizontal-ebook">
          <div className="foruppersectionebook-horizontal-ebook">
            <div className="col-md-8 section-suggest-book-col-8-horizontal-ebook">
              <p className="col-md-8 section-suggest-book-p">Most Popular Ebook</p>
            </div>
            <div className="col-md-4 section-suggest-book-col-4-horizontal-ebook">
              <div className="book-viewall-button-horizontal-ebook">
                <div className="View-all-digital-ebook"> <a href="">View All </a></div>
              </div>
            </div>
          </div>
          {scrollX !== 5 && (
             <button className="prev-horizontal-ebook" onClick={() => slide(-1)}>
             <AiOutlineLeft className="next-book-scroll" color="white" />
           </button>
            )} 
          <div className="container-for-button-horizontal-ebook">
                                                                                                                                                
            <div className="container-digital-ebook-horizontal-ebook">
              <ul className="cards-digital-ebook-horizontal-ebook" ref={scrl} onScroll={scrollCheck}>
              {Array.isArray(cardData) &&
            cardData.map((card) => (
              <li className="card-digital-row4-horizontal-ebook" key={card._id}>
                <div className="upper-card-digital-horizontal-ebook">
                  
                  <div className="card-content-digital-row4-horizontal-ebook" >
 

                  <div className="for-image-size-ebook-horizontal-ebook" >
                  <div className="wishlist-ebook-for-image-horizontal-ebook">
                  <div
  className={`wishlistrecent ${isWishlistItem(card._id) ? 'wishlist-active' : ''}`}
  onClick={(e) => {
    if (!isWishlistItem(card._id)) {
      setSuccessMessage1(
        "Added to your Wishlist")
      handleWishlistClick(e, card._id);
    } else {
      setSuccessMessage1("Removed from your Wishlist");
      handleRemoveFromWishlist(e, card._id);
    }
  }}
  // title={isWishlistItem(card._id) ? 'Remove from wishlist' : 'Add to wishlist'}  style={{ transition: 'color 0.2s ease-in-out' }}
>
  {isWishlistItem(card._id) ? (
    <AiFillHeart className="wishlist-heart-first-horizontal-ebook"  style={{transition: 'color 0.2s ease-in-out' }} />
  ) : (
    <AiFillHeart className="wishlist-heart-horizontal-ebook"  style={{  transition: 'color 0.2s ease-in-out' }}/>
  )}
</div></div>{isLoggedIn && successMessage1 && (
  <div className="success-message-wishlist-horizontal-ebook">
    <div className="for-sm-wish-horizontal-ebook">
      <div className="tick-icon-wish-horizontal-ebook">
        <FcApproval size={26} />
      </div>
      <p className="sm-wish">{successMessage1}</p>
    </div>
  </div>
)}
                      <img
                        className="card-title-digital-row4-horizontal-ebook"
                        src={card.image}
                        alt={card.title}
                        onClick={() => info(card.category, card.subject, card.title)}
                      />
                    </div>
                  </div>

                  <div className="card-link-wrapper-digital-horizontal-ebook">
                    <p>{card.title} </p>
                  </div>

                  <div className="buttons-for-buying-products-horizontal-ebook">
                    <div className="try-for-free-horizontal-ebook">
                      <div
                        className="Rating"
                        onMouseOver={() => handleMouseOver(card._id)}
                        onMouseOut={handleMouseOut}
                      >
                        <div className="Star-Rating">{getRatingForCard(card._id).toFixed(1)}
                          {Array.from({ length: 1 }).map((_, index) => (
                            <AiFillStar key={index} className="Star-Rating-1" />
                          ))}
                        </div>
                        <div className="Total-Rating">({card.rating.length})</div>
                      </div>
                      <div className="button-for-try-view-horizontal-ebook">
                      <Link 
  to={isLoggedIn ? `/ebook/${card._id}/digitalMainPage` : "#"} 
  onClick={() => {
    if (!isLoggedIn) {
      window.location.href = 'https://testportal.ssccglpinnacle.com/login';
    }
  }}
>
                          <button
                            className="button-for-digital-horizontal-ebook button5-horizontal-ebook"
                            style={{
                              display: isEbookPurchased(card._id) || isTryForFree(card._id)
                                ? "none"
                                : "",
                            }}
                          >
                            Try for free now
                          </button>
                          <button
                            className="button-for-digital-horizontal-ebook button6-horizontal-ebook"
                            onClick={() => handleProductDetailsClick(card)}
                            style={{
                              display: isEbookPurchased(card._id) ? "" : "none",
                            }}
                          >
                            View Details
                          </button>
                        </Link>
                      </div>
                    </div>
                    <div className="StarRating">
                      {hoveredId === card._id && <Rate cardData={card} />}
                    </div>
                    {!isEbookPurchased(card._id) && (
  <>
    <div className="price-of-product-horizontal-ebook">
      <div className="price-for-billing-horizontal-ebook">
        <span className="price-for-billing__selling-price-horizontal-ebook">
          ₹ {card.subscriptionPrices[0].sellingPrice} incl. GST
        </span>
        <div className="price-for-billing__details-horizontal-ebook">
          <span className="price-for-billing__mrp-horizontal-ebook">
            M.R.P.: <del>₹ {card.subscriptionPrices[0].printingPrice}</del>
          </span>
          <span className="price-for-billing__saving-amount-horizontal-ebook">
            Save: ₹ {calculateDiscount(card.subscriptionPrices[0].sellingPrice, card.subscriptionPrices[0].printingPrice).savingAmount.toFixed(2)} 
            ({calculateDiscount(card.subscriptionPrices[0].sellingPrice, card.subscriptionPrices[0].printingPrice).discountPercentage.toFixed(0)}%)
          </span>
        </div>
      </div>
    </div>
  </>
)}

                    {!isEbookPurchased(card._id) && isLoggedIn && (
                      <>
                        <div className="buttons-for-important-horizontal-ebook">
                          <button
                            className="buynow-for-user-ebook-horizontal-ebook"
                            onClick={() => handleAddToCart(card._id)}
                          >
                            {isItemInCart(card._id) ? "Go to Cart" : "Add to Cart"}
                          </button>
                          <button
                            className="buynow-for-user1-ebook-horizontal-ebook"
                            onClick={() => handleAddToCart(card._id)}
                          >
                            Buy Now
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </li>
            ))}
              </ul>
            </div>
    
          </div>
          {!scrolEnd && (
             <button className="next-horizontal-ebook" onClick={() => slide(1)}>
             <AiOutlineRight className="prev-book-scroll"color="white" />
           </button>
            )}
        </div>
      </div>
    </>
  );
}

export default Row4Digital;
