import React, { useState, useEffect } from "react";
import axios from "axios";
import Styles from "./HoverCart.module.css";
import { BsCart3 } from "react-icons/bs";
import { Link } from "react-router-dom";
import { useAuth } from "../../../Context/AuthContext";

export default function HoverCart() {
  const [cartData, setCartData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { isLoggedIn, user } = useAuth();
  const emailId = user?.email_id || null;

  useEffect(() => {
    if (!emailId) {
      setLoading(false);
      return;
    }
    setLoading(true);
    const fetchCartCourses = async () => {
      try {
        const response = await axios.get(
          `https://auth.ssccglpinnacle.com/api/usercart/${emailId}`
        );
        if (response.data?.data && Array.isArray(response.data.data)) {
          setCartData(response.data.data);
        } else {
          throw new Error("Failed to load cart courses.");
        }
      } catch (error) {
        console.error("Error fetching cart data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCartCourses();
  }, [emailId]);

  return (
    <div className={Styles.learning}>
      <div className={Styles.dropdown}>
        <button className={Styles.dropbtn}>
          <BsCart3 size={30} className={Styles.cart_icon} />
          {cartData.length > 0 && (
            <span className={Styles.badge}>{cartData.length}</span>
          )}
        </button>
        <div className={Styles.dropdown_content}>
          {loading ? (
            <p>Loading...</p>
          ) : cartData.length > 0 ? (
            <div className={Styles.myLearningContainer}>
              {cartData.map((item, index) => (
                <div key={index} className={Styles["CartList-ul"]}>
                  <div
                    className={Styles["both-ImageSection-descriptionSection"]}
                  >
                    <div className={Styles["CartList-ImageSection"]}>
                      {item.product.englishCoverImage ? (
                        <img
                          src={item.product.englishCoverImage}
                          alt="course image"
                          className={Styles["Image"]}
                        />
                      ) : (
                        <p>No image available</p>
                      )}
                    </div>
                    <div className={Styles["CartList-descriptionSection"]}>
                      <div className={Styles["courseName"]}>
                        {item.product.courseTitle || "Title not available"}
                      </div>
                      <div className={Styles["coursePrice"]}>
                        ₹
                        {item.product.price?.toFixed(2) ||
                          "Price not available"}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <Link to={"/videos/cart"} className={Styles["GoToCart-btn"]}>
                Go to Cart
              </Link>
            </div>
          ) : (
            <div className={Styles.dropdown_content}>
              <div className={Styles["CartList-ul"]}>
                <div className={Styles["both-ImageSection-descriptionSection"]}>
                  <div className={Styles["CartList-descriptionSection"]}>
                    No items in Cart
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
