
// import React, { useState, useEffect } from "react";
// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
// import "./Row1Digital.css";

// function Row1Digital({ onDataFiltered }) {
//   const [cardData, setCardData] = useState([]);
//   const [cardDataexam, setCardDataexam] = useState([]);
//   const [filters, setFilters] = useState({
//     ssc: false,
//     railway: false,
//     teaching: false,
//     general: false,      // New category
//     cet: false,          // New category
//     police: false,       // New category
//     csat: false,         // New category
//     cbse10thclass: false // New category
//   });
  
//   const [examFilters, setExamFilters] = useState({});
//   const [examNames, setExamNames] = useState([]);
//   const [examNamesa, setExamNamesa] = useState([]);
//   const [examNameFilters, setExamNameFilters] = useState(false);
//   const [examCategoryFilters, setExamCategoryFilters] = useState(false);

//   const [previousData, setPreviousData] = useState([]);
//   const [firstfilterdata, setfirstfilterdata] = useState(true);
//   const [secondfilterdata, setsecondfilterdata] = useState([]);
//   const [languageFilters, setLanguageFilters] = useState({
//     english: false,
//     hindi: false,
//     bilingual: false,
//     other: false,
//   });
//   const [subjectFilters, setSubjectFilters] = useState({
//     maths: false,
//     english: false,
//     reasoning: false,
//     ga: false,
//     currentAffairs: false,
//     // science: false,
//     all: false,
//   });

//   // console.log("first filter:" , firstfilterdata);
//   // console.log("second filter:" ,secondfilterdata);

  

//   // const fetchData = async (updateExamNames = false) => {
//   //   try {
//   //     const ebookResponse = await fetch(
//   //       "https://auth.ssccglpinnacle.com/api/ebooksforactive?active=true"
//   //     );
//   //     const examResponse = await fetch("https://auth.ssccglpinnacle.com/api/examnames-ebook");
    
//   //     if (!ebookResponse.ok || !examResponse.ok) {
//   //       throw new Error(`Failed to fetch data`);
//   //     }
    
//   //     const ebookData = await ebookResponse.json();
//   //     const examData = await examResponse.json();
//   //     setCardDataexam(ebookData);
    
//   //     const filteredData = ebookData.filter((item) => {
//   //       const categoryFilterMatch =
//   //         !Object.values(filters).some(Boolean) ||
//   //         Object.entries(filters).some(
//   //           ([key, value]) =>
//   //             value && item.category.toLowerCase().includes(key.toLowerCase())
//   //         );
    
//   //       const examFilterMatch =
//   //         !Object.values(examFilters).some(Boolean) ||
//   //         Object.entries(examFilters).some(([key, value]) => {
//   //           if (value) {
//   //             const formattedKey = key.replace(/\s/g, "").toLowerCase();
//   //             const formattedExamName = item.examName
//   //               .replace(/\s/g, "")
//   //               .toLowerCase();
//   //             return formattedExamName.includes(formattedKey);
//   //           }
//   //           return false;
//   //         });
    
//   //       const languageFilterMatch =
//   //         !Object.values(languageFilters).some(Boolean) ||
//   //         Object.entries(languageFilters).some(([key, value]) => {
//   //           if (value) {
//   //             return item.medium.toLowerCase() === key.toLowerCase();
//   //           }
//   //           return false;
//   //         });
    
//   //         const subjectFilterMatch =
//   //       !Object.values(subjectFilters).some(Boolean) ||
//   //       Object.entries(subjectFilters).some(([key, value]) => {
//   //         if (value && item.subject) {
//   //           const subjectString = item.subject.toLowerCase();
//   //           return (
//   //             subjectString.includes(key.toLowerCase()) ||
//   //             (key === "ga" &&
//   //               (subjectString.includes("gk") ||
//   //                 subjectString.includes("general studies") ||
//   //                 subjectString.includes("gs"))) ||
//   //             (key === "science" && subjectString.includes("science"))
//   //           );
//   //         }
//   //         return false;
//   //       });
    
//   //       // Custom filtering for Railway category
//   //       if (filters.railway) {
//   //         return (
//   //           categoryFilterMatch &&
//   //           examFilterMatch &&
//   //           languageFilterMatch &&
//   //           subjectFilterMatch &&
//   //           item.examName.split(',').some((name) => {
//   //             const formattedName = name.trim().toLowerCase();
//   //             return formattedName.startsWith("r") || formattedName.includes("n");
//   //           })
//   //         );
//   //       }
    
//   //       // Custom filtering for SSC category
//   //       if (filters.ssc) {
//   //         return (
//   //           categoryFilterMatch &&
//   //           examFilterMatch &&
//   //           languageFilterMatch &&
//   //           subjectFilterMatch &&
//   //           item.examName.split(',').some((name) => {
//   //             const formattedName = name.trim().toLowerCase();
//   //             return formattedName.includes("ssc") || formattedName.startsWith("m");
//   //           })
//   //         );
//   //       }
    
//   //       // Custom filtering for Teaching category
//   //       if (filters.teaching) {
//   //         return (
//   //           categoryFilterMatch &&
//   //           examFilterMatch &&
//   //           languageFilterMatch &&
//   //           subjectFilterMatch &&
//   //           item.category.toLowerCase().includes("teaching")
//   //         );
//   //       }
    
//   //       return categoryFilterMatch && examFilterMatch && languageFilterMatch && subjectFilterMatch;
//   //     });
    
//   //     setCardData(filteredData);
    
//   //     // Update exam names only if categories change
//   //     if (updateExamNames) {
//   //       const uniqueExamNames = [
//   //         ...new Set(filteredData.flatMap((item) => {
//   //           return item.examName.split(",").filter((name) => {
//   //             const formattedName = name.trim().toLowerCase();
//   //             if (filters.railway) {
//   //               return formattedName.startsWith("r") || formattedName.includes("n");
//   //             } else if (filters.ssc) {
//   //               return formattedName.includes("ssc") || formattedName.startsWith("m");
//   //             } else {
//   //               return true;
//   //             }
//   //           });
//   //         })),
//   //       ].map((name) => name.trim());
    
//   //       setExamNames(uniqueExamNames);
//   //     }
    
//   //     if (firstfilterdata) {
//   //       setfirstfilterdata(false);
//   //     }
//   //   } catch (error) {
//   //     console.error("Error fetching data:", error);
//   //   }
//   // };



//   const fetchData = async (updateExamNames = false) => {
//     try {
//       const ebookResponse = await fetch(
//         "https://auth.ssccglpinnacle.com/api/ebooksforactive?active=true"
//       );
//       const examResponse = await fetch("https://auth.ssccglpinnacle.com/api/examnames-ebook");
  
//       if (!ebookResponse.ok || !examResponse.ok) {
//         throw new Error(`Failed to fetch data`);
//       }
  
//       const ebookData = await ebookResponse.json();
//       const examData = await examResponse.json();
//       setCardDataexam(ebookData);
  
//       // Apply filters
//       const filteredData = ebookData.filter((item) => {
//         // Match by category filter
//         const categoryFilterMatch =
//         !Object.values(filters).some(Boolean) ||
//         Object.entries(filters).some(([key, value]) => {
//           if (value) {
//             const filterKeyToCategory = {
//               ssc: "ssc",
//               railway: "railway",
//               teaching: "teaching",
//               general: "general",
//               cet: "cet",
//               police: "police",
//               csat: "csat",
//               cbse10thclass: "CBSE 10th exam", // Map internal key to actual category
//             };
      
//             // Match category using the mapped value
//             const categoryName = filterKeyToCategory[key]?.toLowerCase().replace(/\s+/g, "");
//             return categoryName && item.category.toLowerCase().replace(/\s+/g, "").includes(categoryName);
//           }
//           return false;
//         });

      
  
//         // Match by exam filter, only if `examName` exists
//         const examFilterMatch =
//           !Object.values(examFilters).some(Boolean) ||
//           (item.examName &&
//             Object.entries(examFilters).some(([key, value]) => {
//               if (value) {
//                 const formattedKey = key.replace(/\s/g, "").toLowerCase();
//                 const formattedExamName = item.examName
//                   .replace(/\s/g, "")
//                   .toLowerCase();
//                 return formattedExamName.includes(formattedKey);
//               }
//               return false;
//             }));
  
//         // Match by language filter
//         const languageFilterMatch =
//           !Object.values(languageFilters).some(Boolean) ||
//           Object.entries(languageFilters).some(([key, value]) => {
//             if (value) {
//               return item.medium.toLowerCase() === key.toLowerCase();
//             }
//             return false;
//           });
  
//         // Match by subject filter
//         const subjectFilterMatch =
//           !Object.values(subjectFilters).some(Boolean) ||
//           Object.entries(subjectFilters).some(([key, value]) => {
//             if (value && item.subject) {
//               const subjectString = item.subject.toLowerCase();
//               return (
//                 subjectString.includes(key.toLowerCase()) ||
//                 (key === "ga" &&
//                   (subjectString.includes("gk") ||
//                     subjectString.includes("general studies") ||
//                     subjectString.includes("gs"))) ||
//                 (key === "science" && subjectString.includes("science"))
//               );
//             }
//             return false;
//           });
  
//         // Final condition: Match by category regardless of examName presence
//         return (
//           categoryFilterMatch &&
//           (item.examName ? examFilterMatch : true) &&
//           languageFilterMatch &&
//           subjectFilterMatch
//         );
//       });
  
//       setCardData(filteredData);
  
//       // Update exam names only if categories change
//       if (updateExamNames) {
//         const uniqueExamNames = [
//           ...new Set(
//             filteredData.flatMap((item) => {
//               return item.examName
//                 ? item.examName.split(",").filter((name) => {
//                     const formattedName = name.trim().toLowerCase();
//                     if (filters.railway) {
//                       return formattedName.includes("railway") || formattedName.includes("rrb");
//                     } else if (filters.ssc) {
//                       return formattedName.includes("ssc");
//                     } else if (filters.teaching) {
//                       return formattedName.includes("teaching");
//                     } else {
//                       return true;
//                     }
//                   })
//                 : []; // Skip if `examName` is absent
//             })
//           ),
//         ].map((name) => name.trim());
  
//         setExamNames(uniqueExamNames);
//       }
  
//       if (firstfilterdata) {
//         setfirstfilterdata(false);
//       }
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };
  

// //   if (updateExamNames) {
// //     const uniqueExamNames = [
// //       ...new Set(filteredData.flatMap((item) => item.examName?.split(",") || [])),
// //     ].map((name) => name.trim());
// //     setExamNames(uniqueExamNames);
// //   }
// // } catch (error) {
// //   console.error("Error fetching data:", error);
// // }
// // };
  
//   useEffect(() => {
//     fetchData(false);  // Fetch data but do not update exam names
//   }, [languageFilters, subjectFilters, examFilters]);
  
//   useEffect(() => {
//     fetchData(true);  // Update exam names when the category filter changes
//   }, [filters]);  // Only trigger exam name update on category change
  
  
  
 
  
//   const handleCheckboxChange = async (filter, isCategoryFilter = true) => {
//     if (isCategoryFilter) {
//       setFilters((prevFilters) => ({
//         ...prevFilters,
//         [filter]: !prevFilters[filter],
//       }));
//       setfirstfilterdata(isCategoryFilter);
//       fetchData(true);  // Update exam names only when category changes
//     } else {
//       fetchData();  // Do not update exam names for other changes
//     }
//   };
  
  
//   const handleExamNameCheckboxChange = (examName) => {
//     // Toggle the filter state for the selected exam name
//     setExamFilters((prevExamFilters) => ({
//       ...prevExamFilters,
//       [examName.toLowerCase().replace(/\s+/g, "")]:
//         !prevExamFilters[examName.toLowerCase().replace(/\s+/g, "")],
//     }));
  
//     // Re-fetch filtered data but do not update the exam names list
//     fetchData(false);
//   };
  
  
  
  
  
// // useEffect(() => {
// //     fetchData();
// //   }, [filters, examFilters, languageFilters, subjectFilters]);// Include both filters in the dependency array

//   // const handleCheckboxChange = async (filter, isCategoryFilter = true) => {
//   //   if (isCategoryFilter) {
//   //     setFilters((prevFilters) => ({
//   //       ...prevFilters,
//   //       [filter]: !prevFilters[filter],
//   //     }));
//   //     setfirstfilterdata(isCategoryFilter);
//   //     fetchData();
//   //   } else {
//   //     // Handle other checkbox changes if needed
//   //   }
//   // };

//   // const handleExamNameCheckboxChange = (examName) => {
//   //   // Toggle the filter state for the selected exam name
//   //   setExamFilters((prevExamFilters) => ({
//   //     ...prevExamFilters,
//   //     [examName.toLowerCase().replace(/\s+/g, "")]:
//   //       !prevExamFilters[examName.toLowerCase().replace(/\s+/g, "")],
//   //   }));
//   // };

//   const handleLanguageCheckboxChange = (language) => {
//     setLanguageFilters((prevLanguageFilters) => ({
//       ...prevLanguageFilters,
//       [language.toLowerCase()]: !prevLanguageFilters[language.toLowerCase()],
//     }));
//   };

//   const handleSubjectCheckboxChange = (subject) => {
//     setSubjectFilters((prevSubjectFilters) => ({
//       ...prevSubjectFilters,
//       [subject.toLowerCase()]: !prevSubjectFilters[subject.toLowerCase()],
//     }));
//   };
//   // Log cardData when it changes
//   useEffect(() => {
//     setPreviousData(cardData);
//    console.log(cardData)
//    onDataFiltered(cardData)
//   }, [cardData]);

//   return (
//     <>
//     <div className="container-digital">
//       <p className="breadcrumb">Home | E-books</p>
//       <div className="filters-wrapper">
//         <div className="filter-section">
//           <h4>Category</h4>
//           <div className="filter-items">
//           {Object.keys(filters).map((filter, index) => {
//       // Map keys to display-friendly names
//       const displayName = {
//         ssc: "SSC",
//         railway: "Railway",
//         teaching: "Teaching",
//         general: "General",
//         cet: "CET",
//         police: "Police",
//         csat: "CSAT",
//         cbse10thexam: "CBSE 10th exam" // Custom display name
//       }[filter] || filter.toUpperCase();

//       return (
//         <div className="for-padding-input" key={index}>
//           <input
//             type="checkbox"
//             id={`filter_${index}`}
//             name={`filter_${index}`}
//             checked={filters[filter]}
//             onChange={() => handleCheckboxChange(filter)}
//           />
//           <label htmlFor={`filter_${index}`}>{displayName}</label>
//         </div>
//       );
//     })}
//   </div>
// </div>
  
//         <div className="filter-section">
//           <h4>Exam Name</h4>
//           <div className="filter-items">
//             {[...new Set(examNames)]
//               .filter(
//                 (examName) =>
//                   examName && examName !== "undefined" && examName !== "null"
//               )
//               .map((examName, index) => (
//                 <div className="for-padding-input" key={index}>
//                   <input
//                     type="checkbox"
//                     id={`exam_${index}`}
//                     name={`exam_${index}`}
//                     checked={
//                       examFilters[examName.toLowerCase().replace(/\s+/g, "")]
//                     }
//                     onChange={() => handleExamNameCheckboxChange(examName)}
//                   />
//                   <label htmlFor={`exam_${index}`}>{examName}</label>
//                 </div>
//               ))}
//           </div>
//         </div>
  
//         <div className="filter-section">
//           <h4>Language</h4>
//           <div className="filter-items">
//             {["English", "Hindi", "Bilingual", "Other"].map((language, index) => (
//               <div className="for-padding-input" key={index}>
//                 <input
//                   type="checkbox"
//                   id={language.toLowerCase()}
//                   name={language.toLowerCase()}
//                   onChange={() => handleLanguageCheckboxChange(language)}
//                 />
//                 <label htmlFor={language.toLowerCase()}>{language}</label>
//               </div>
//             ))}
//           </div>
//         </div>
  
//         <div className="filter-section">
//           <h4>Subject</h4>
//           <div className="filter-items">
//             {["Maths", "English", "Reasoning", "GA", "Current Affairs", "Science"].map(
//               (subject, index) => (
//                 <div className="for-padding-input" key={index}>
//                   <input
//                     type="checkbox"
//                     id={subject.toLowerCase()}
//                     name={subject.toLowerCase()}
//                     onChange={() => handleSubjectCheckboxChange(subject)}
//                   />
//                   <label htmlFor={subject.toLowerCase()}>{subject}</label>
//                 </div>
//               )
//             )}
//           </div>
//         </div>
//       </div>
//     </div>
//     </>
//   );
// }

// export default Row1Digital;



import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./Row1Digital.css";

function Row1Digital({ onDataFiltered }) {
  const [cardData, setCardData] = useState([]);
  const [cardDataexam, setCardDataexam] = useState([]);
  const [filters, setFilters] = useState({
    ssc: false,
    railway: false,
    teaching: false,
    general: false,      // New category
    cet: false,          // New category
    police: false,       // New category
    csat: false,         // New category
    cbse10thclass: false // New category
  });
  
  const [examFilters, setExamFilters] = useState({});
  const [examNames, setExamNames] = useState([]);
  const [examNamesa, setExamNamesa] = useState([]);
  const [examNameFilters, setExamNameFilters] = useState(false);
  const [examCategoryFilters, setExamCategoryFilters] = useState(false);

  const [previousData, setPreviousData] = useState([]);
  const [firstfilterdata, setfirstfilterdata] = useState(true);
  const [secondfilterdata, setsecondfilterdata] = useState([]);
  const [languageFilters, setLanguageFilters] = useState({
    english: false,
    hindi: false,
    bilingual: false,
    other: false,
  });
  const [subjectFilters, setSubjectFilters] = useState({
    maths: false,
    english: false,
    reasoning: false,
    ga: false,
    currentAffairs: false,
    // science: false,
    all: false,
  });

  // console.log("first filter:" , firstfilterdata);
  // console.log("second filter:" ,secondfilterdata);

  

  


  const fetchData = async (updateExamNames = false) => {
    try {
      const ebookResponse = await fetch(
        "https://auth.ssccglpinnacle.com/api/ebooksforactive?active=true"
      );
      const examResponse = await fetch("https://auth.ssccglpinnacle.com/api/examnames-ebook");
  
      if (!ebookResponse.ok || !examResponse.ok) {
        throw new Error(`Failed to fetch data`);
      }
  
      const ebookData = await ebookResponse.json();
      const examData = await examResponse.json();
      setCardDataexam(ebookData);
  
      // Apply filters
      const filteredData = ebookData.filter((item) => {
        // Match by category filter
        const categoryFilterMatch =
        !Object.values(filters).some(Boolean) ||
        Object.entries(filters).some(([key, value]) => {
          if (value) {
            const filterKeyToCategory = {
              ssc: "ssc",
              railway: "railway",
              teaching: "teaching",
              general: "general",
              cet: "cet",
              police: "police",
              csat: "csat",
              cbse10thclass: "CBSE 10th exam", // Map internal key to actual category
            };
      
            // Match category using the mapped value
            const categoryName = filterKeyToCategory[key]?.toLowerCase().replace(/\s+/g, "");
            return categoryName && item.category.toLowerCase().replace(/\s+/g, "").includes(categoryName);
          }
          return false;
        });

      
  
        // Match by exam filter, only if `examName` exists
        const examFilterMatch =
          !Object.values(examFilters).some(Boolean) ||
          (item.examName &&
            Object.entries(examFilters).some(([key, value]) => {
              if (value) {
                const formattedKey = key.replace(/\s/g, "").toLowerCase();
                const formattedExamName = item.examName
                  .replace(/\s/g, "")
                  .toLowerCase();
                return formattedExamName.includes(formattedKey);
              }
              return false;
            }));
  
        // Match by language filter
        const languageFilterMatch =
          !Object.values(languageFilters).some(Boolean) ||
          Object.entries(languageFilters).some(([key, value]) => {
            if (value) {
              return item.medium.toLowerCase() === key.toLowerCase();
            }
            return false;
          });
  
        // Match by subject filter
        const subjectFilterMatch =
          !Object.values(subjectFilters).some(Boolean) ||
          Object.entries(subjectFilters).some(([key, value]) => {
            if (value && item.subject) {
              const subjectString = item.subject.toLowerCase();
              return (
                subjectString.includes(key.toLowerCase()) ||
                (key === "ga" &&
                  (subjectString.includes("gk") ||
                    subjectString.includes("general studies") ||
                    subjectString.includes("gs"))) ||
                (key === "science" && subjectString.includes("science"))
              );
            }
            return false;
          });
  
        // Final condition: Match by category regardless of examName presence
        return (
          categoryFilterMatch &&
          (item.examName ? examFilterMatch : true) &&
          languageFilterMatch &&
          subjectFilterMatch
        );
      });
  
      setCardData(filteredData);
  
      // Update exam names only if categories change
      if (updateExamNames) {
        const uniqueExamNames = [
          ...new Set(
            filteredData.flatMap((item) => {
              return item.examName
                ? item.examName.split(",").filter((name) => {
                    const formattedName = name.trim().toLowerCase();
                    if (filters.railway) {
                      return formattedName.includes("railway") || formattedName.includes("rrb");
                    } else if (filters.ssc) {
                      return formattedName.includes("ssc");
                    } else if (filters.teaching) {
                      return formattedName.includes("teaching");
                    } else {
                      return true;
                    }
                  })
                : []; // Skip if `examName` is absent
            })
          ),
        ].map((name) => name.trim());
  
        setExamNames(uniqueExamNames);
      }
  
      if (firstfilterdata) {
        setfirstfilterdata(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  


  
  useEffect(() => {
    fetchData(false);  // Fetch data but do not update exam names
  }, [languageFilters, subjectFilters, examFilters]);
  
  useEffect(() => {
    fetchData(true);  // Update exam names when the category filter changes
  }, [filters]);  // Only trigger exam name update on category change
  
  
  
 
  
  const handleCheckboxChange = async (filter, isCategoryFilter = true) => {
    if (isCategoryFilter) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [filter]: !prevFilters[filter],
      }));
      setfirstfilterdata(isCategoryFilter);
      fetchData(true);  // Update exam names only when category changes
    } else {
      fetchData();  // Do not update exam names for other changes
    }
  };
  
  
  const handleExamNameCheckboxChange = (examName) => {
    // Toggle the filter state for the selected exam name
    setExamFilters((prevExamFilters) => ({
      ...prevExamFilters,
      [examName.toLowerCase().replace(/\s+/g, "")]:
        !prevExamFilters[examName.toLowerCase().replace(/\s+/g, "")],
    }));
  
    // Re-fetch filtered data but do not update the exam names list
    fetchData(false);
  };
  
  
  
  


  const handleLanguageCheckboxChange = (language) => {
    setLanguageFilters((prevLanguageFilters) => ({
      ...prevLanguageFilters,
      [language.toLowerCase()]: !prevLanguageFilters[language.toLowerCase()],
    }));
  };

  const handleSubjectCheckboxChange = (subject) => {
    setSubjectFilters((prevSubjectFilters) => ({
      ...prevSubjectFilters,
      [subject.toLowerCase()]: !prevSubjectFilters[subject.toLowerCase()],
    }));
  };
  // Log cardData when it changes
  useEffect(() => {
    setPreviousData(cardData);
   console.log(cardData)
   onDataFiltered(cardData)
  }, [cardData]);

  return (
    <>
    <div className="container-digital">
      <p className="breadcrumb">Home | E-books</p>
      <div className="filters-wrapper">
        <div className="filter-section">
          <h4>Category</h4>
          <div className="filter-items">
          {Object.keys(filters).map((filter, index) => {
      // Map keys to display-friendly names
      const displayName = {
        ssc: "SSC",
        railway: "Railway",
        teaching: "Teaching",
        general: "General",
        cet: "CET",
        police: "Police",
        csat: "CSAT",
        cbse10thclass: "CBSE 10th exam", // Correctly mapped display name
      }[filter] || filter.toUpperCase();

      return (
        <div className="for-padding-input" key={index}>
          <input
            type="checkbox"
            id={`filter_${index}`}
            name={`filter_${index}`}
            checked={filters[filter]}
            onChange={() => handleCheckboxChange(filter)}
          />
          <label htmlFor={`filter_${index}`}>{displayName}</label>
        </div>
      );
    })}
  </div>
</div>
  
        <div className="filter-section">
          <h4>Exam Name</h4>
          <div className="filter-items">
            {[...new Set(examNames)]
              .filter(
                (examName) =>
                  examName && examName !== "undefined" && examName !== "null"
              )
              .map((examName, index) => (
                <div className="for-padding-input" key={index}>
                  <input
                    type="checkbox"
                    id={`exam_${index}`}
                    name={`exam_${index}`}
                    checked={
                      examFilters[examName.toLowerCase().replace(/\s+/g, "")]
                    }
                    onChange={() => handleExamNameCheckboxChange(examName)}
                  />
                  <label htmlFor={`exam_${index}`}>{examName}</label>
                </div>
              ))}
          </div>
        </div>
  
        <div className="filter-section">
          <h4>Language</h4>
          <div className="filter-items">
            {["English", "Hindi", "Bilingual", "Other"].map((language, index) => (
              <div className="for-padding-input" key={index}>
                <input
                  type="checkbox"
                  id={language.toLowerCase()}
                  name={language.toLowerCase()}
                  onChange={() => handleLanguageCheckboxChange(language)}
                />
                <label htmlFor={language.toLowerCase()}>{language}</label>
              </div>
            ))}
          </div>
        </div>
  
        <div className="filter-section">
          <h4>Subject</h4>
          <div className="filter-items">
            {["Maths", "English", "Reasoning", "GA", "Current Affairs", "Science"].map(
              (subject, index) => (
                <div className="for-padding-input" key={index}>
                  <input
                    type="checkbox"
                    id={subject.toLowerCase()}
                    name={subject.toLowerCase()}
                    onChange={() => handleSubjectCheckboxChange(subject)}
                  />
                  <label htmlFor={subject.toLowerCase()}>{subject}</label>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default Row1Digital;
