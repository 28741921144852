import React, { useEffect, useState } from "react";
import axios from "axios";
import styles from "./RefundPolicy.module.css";
import Header from "../component/HeaderFiles/Header";
import Footer from "./Footer";
import MetaTags from "../DynamicMetaData/DynamicMetadata";

const RefundPolicy = () => {
  const [refundContent, setRefundContent] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRefundContent = async () => {
      try {
        const response = await axios.get(
          "https://auth.ssccglpinnacle.com/api/footer"
        );

        if (response.data && response.data.length > 0) {
          console.log(
            "Fetched Refund Policy Data:",
            response.data[0].refund_policy
          );
          setRefundContent(response.data[0].refund_policy || "");
        } else {
          setError("Failed to load Refund Policy content");
        }
      } catch (error) {
        console.error("Error fetching Refund Policy content:", error);
        setError("An error occurred while fetching Refund Policy content");
      } finally {
        setLoading(false);
      }
    };

    fetchRefundContent();
  }, []);

  return (
    <>
      <MetaTags type="refund-policy" />
      <Header />
      <div className={styles.refundContainer}>
        {loading && <p>Loading Refund Policy...</p>}
        {error && <p className={styles.errorMessage}>{error}</p>}
        {!loading && !error && (
          <div dangerouslySetInnerHTML={{ __html: refundContent }} />
        )}
      </div>
      <Footer />
    </>
  );
};

export default RefundPolicy;
