import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import styles from "./Cart.module.css";
import Header from "../../HomePage01/Header/Header";
import { useNavigate } from "react-router-dom";
import Footer from "../../Footer/Footer";
import VideoBuyButton from "./VideosBuyButton";
import { useAuth } from "../../../Context/AuthContext";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

function CartPage() {
  const [courseDetails, setCourseDetails] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [recommendedCourses, setRecommendedCourses] = useState([]);
  const navigate = useNavigate();
  const scrollRef = useRef(null);
  const { isLoggedIn, user } = useAuth();
  const emailId = user?.email_id || null;

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const [cartResponse, recommendedResponse] = await Promise.all([
          axios.get(`https://auth.ssccglpinnacle.com/api/usercart/${emailId}`),
          axios.get(`https://auth.ssccglpinnacle.com/api/recent-courses`),
        ]);

        // Handle cart data
        if (cartResponse.status === 200 && cartResponse.data.success) {
          const validCourses = cartResponse.data.data.map((course) => ({
            ...course,
            product: {
              ...course.product,
              price: course.product?.price || 0,
              mrp: course.product?.mrp || 0,
            },
          }));
          setCourseDetails(validCourses);
          calculateTotalPrice(validCourses);
        } else {
          setCourseDetails([]);
          setTotalPrice(0);
        }

        // Handle recommended courses
        if (
          recommendedResponse.status === 200 &&
          Array.isArray(recommendedResponse.data)
        ) {
          setRecommendedCourses(recommendedResponse.data);
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
        setError("Failed to load data. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    if (emailId) {
      fetchData();
    }
  }, [emailId]);

  const calculateTotalPrice = (courses) => {
    const total = courses.reduce(
      (acc, course) => acc + (course.product.price || 0),
      0
    );
    setTotalPrice(Number(total.toFixed(2)));
  };

  const handleRemove = async (courseId) => {
    try {
      await axios.delete(
        `https://auth.ssccglpinnacle.com/api/usercart/${emailId}/${courseId}`
      );

      const updatedCourses = courseDetails.filter(
        (course) => course.product._id.toString() !== courseId
      );

      setCourseDetails(updatedCourses);
      calculateTotalPrice(updatedCourses);

      Swal.fire(
        "Removed!",
        "Course removed from cart successfully.",
        "success"
      );
    } catch (err) {
      console.error(
        "Failed to remove course from cart:",
        err.response?.data || err
      );
      Swal.fire(
        "Error",
        err.response?.data?.message || "Failed to remove course from cart.",
        "error"
      );
    }
  };

  const wishlistHandler = async (courseId) => {
    try {
      const response = await axios.post(
        `https://auth.ssccglpinnacle.com/api/userwishlist/${emailId}/${courseId}`
      );

      if (response.status === 201) {
        Swal.fire(
          "Success!",
          "Course moved to wishlist successfully.",
          "success"
        );
        const updatedCourses = courseDetails.filter(
          (course) => course.product._id.toString() !== courseId
        );
        setCourseDetails(updatedCourses);
        calculateTotalPrice(updatedCourses);
      } else if (response.status === 200) {
        Swal.fire("Info", "Course is already in your wishlist.", "info");
      }
    } catch (error) {
      console.error("Failed to add course to wishlist:", error);
      Swal.fire(
        "Error",
        error.response?.data?.message || "Failed to add course to wishlist.",
        "error"
      );
    }
  };

  const addToCartHandler = async (course) => {
    try {
      const response = await axios.post(
        `https://auth.ssccglpinnacle.com/api/usercart/${emailId}/${course._id}`
      );

      if (response.status === 201) {
        Swal.fire("Success!", "Added to Cart", "success");

        const updatedCourses = [...courseDetails, { product: course }];
        setCourseDetails(updatedCourses);
        calculateTotalPrice(updatedCourses);
      } else {
        Swal.fire("Failed", "Failed to add course to cart.", "error");
      }
    } catch (error) {
      console.error("Error adding course to cart:", error);
      Swal.fire("Error", "Failed to add course to cart.", "error");
    }
  };

  const handleButtonClick = () => {
    navigate("/videos");
  };

  const scroll = (direction) => {
    if (direction === "left") {
      scrollRef.current.scrollLeft -= 300;
    } else {
      scrollRef.current.scrollLeft += 300;
    }
  };

  if (loading) {
    return (
      <div className={styles["loading-div"]}>
        <img
          src="https://d3m4h509gttb94.cloudfront.net/Image/Bubble-Preloader.gif"
          alt="loading"
        />
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }
  const validProductIds = courseDetails.map((course) => course.product._id);

  return (
    <>
      <Header />
      <div className={styles.cartContainer}>
        <h2 className={styles.cartHeader}>Shopping Cart</h2>
        <p className={styles.cartSubHeader}>
          {courseDetails.length} Courses in Cart
        </p>
        {courseDetails.length === 0 ? (
          <div className={styles.emptyCart}>
            <img
              src="/shoppingImage.webp"
              alt="Empty Cart"
              className={styles.emptyCartImage}
            />
            <p className={styles.emptyCartPara}>
              Your cart is empty. Keep shopping to find a course!
            </p>
            <button
              className={styles.keepShoppingButton}
              onClick={handleButtonClick}
            >
              Keep shopping
            </button>
          </div>
        ) : (
          <div className={styles.mainContent}>
            <div className={styles.courseContainer}>
              {courseDetails.map((course) => (
                <div key={course.product._id} className={styles.courseCard}>
                  <div className={styles.courseImageContainer}>
                    <img
                      src={course.product.hindiCoverImage}
                      alt="Hindi Cover"
                      className={styles.coverImage}
                    />
                    <img
                      src={course.product.englishCoverImage}
                      alt="English Cover"
                      className={styles.coverImage}
                    />
                  </div>
                  <div className={styles.courseDetails}>
                    <h3 className={styles.courseTitle}>
                      {course.product.courseTitle}
                    </h3>
                    <p className={styles.courseInstructor}>
                      By {course.product.instructorName}
                    </p>
                    <div className={styles.courseMeta}>
                      <span className={styles.rating}>
                        {course.product.rating} ★
                      </span>
                      <span>({course.product.reviewCount} ratings)</span>
                    </div>
                    <div className={styles.courseActions}>
                      <button
                        onClick={() => handleRemove(course.product._id)} // Corrected to access the product ID
                        className={styles.removeLink}
                      >
                        Remove
                      </button>

                      <button
                        className={styles.wishlistLink}
                        onClick={() => wishlistHandler(course.product._id)} // Corrected property access
                      >
                        Move to Wishlist
                      </button>
                    </div>

                    <div className={styles.coursePrice}>
                      <p>₹{(course.product.price || 0).toFixed(2)}</p>
                      <p className={styles.oldPrice}>
                        ₹{(course.product.mrp || 0).toFixed(2)}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className={styles.checkoutSection}>
              <div className={styles.totalSection}>
                <h3>Total:</h3>
                <p className={styles.totalPrice}>₹{totalPrice}</p>
                <p className={styles.duration}>Duration: 12 Months</p>
                <p className={styles.line1}>18% GST has been included.</p>
                <p className={styles.line2}>
                  Your cart does not require shipping.
                </p>
                <VideoBuyButton
                  productId={validProductIds}
                  userDetails={user}
                  buttonText="Proceed To Pay"
                  duration="12 Months"
                />
              </div>
            </div>
          </div>
        )}
        <div className={styles.recommendationSection}>
          <h3 className={styles.recommendationSectionheader}>
            You might also like
          </h3>
          <div className={styles.scrollContainerWrapper}>
            <button
              className={styles.scrollButton}
              style={{ left: "10px" }}
              onClick={() => scroll("left")}
            >
              <FaChevronLeft />
            </button>

            <div className={styles.scrollContainer} ref={scrollRef}>
              {recommendedCourses.map((course) => (
                <div key={course._id} className={styles.recommendationCard}>
                  <div className={styles.recommendationImageContainer}>
                    <img
                      src={course.hindiCoverImage}
                      alt={course.title}
                      className={styles.recommendationImage}
                    />
                    <img
                      src={course.englishCoverImage}
                      alt={course.title}
                      className={styles.recommendationImage}
                    />
                  </div>
                  <div className={styles.recommendationDetails}>
                    <h4 className={styles.recommendationTitle}>
                      {course.courseTitle}
                    </h4>
                    <p className={styles.recommendationInstructor}>
                      By {course.instructorName}
                    </p>
                    <div className={styles.recommendationMeta}>
                      <span className={styles.rating}>{course.rating} ★</span>
                      <span>({course.reviewCount} ratings)</span>
                    </div>
                    <div className={styles.recommendationPriceSection}>
                      <p className={styles.recommendationPrice}>
                        ₹{course.price}
                      </p>
                      <p className={styles.recommendationOldPrice}>
                        ₹{course.mrp}
                      </p>
                    </div>
                    <button
                      className={styles.addToCartButton}
                      onClick={() => addToCartHandler(course)}
                      disabled={courseDetails.some(
                        (item) => item.product._id === course._id
                      )}
                    >
                      {courseDetails.some(
                        (item) => item.product._id === course._id
                      )
                        ? "Already in Cart"
                        : "Add to Cart"}
                    </button>
                  </div>
                </div>
              ))}
            </div>

            <button
              className={styles.scrollButton}
              style={{ right: "10px" }}
              onClick={() => scroll("right")}
            >
              <FaChevronRight />
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default CartPage;
