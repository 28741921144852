import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const TrackPageView = () => {
  const location = useLocation();

  useEffect(() => {
    // Ensure clevertap is available globally
    if (typeof window.clevertap !== "undefined") {
      window.clevertap.event.push("Page Visited", {
        PageName: document.title, // The page title
        URL: window.location.href, // The full URL
        Pathname: location.pathname, // The route path
        Timestamp: new Date().toISOString(), // The time of the visit
      });

      // Log for debugging purposes (optional)
      console.log("CleverTap Page Visited Event Sent: ", {
        PageName: document.title,
        URL: window.location.href,
        Pathname: location.pathname,
      });
    } else {
      console.error("CleverTap is not initialized.");
    }
  }, [location]); // Re-run whenever the route changes

  return null; // This component doesn’t render anything
};

export default TrackPageView;
