import React, { useEffect, useState } from "react";
import axios from "axios";
import styles from "./TermsConditions.module.css";
import Header from "../component/HeaderFiles/Header";
import Footer from "./Footer";
import MetaTags from "../DynamicMetaData/DynamicMetadata";

const TermsConditions = () => {
  const [termsContent, setTermsContent] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTermsContent = async () => {
      try {
        const response = await axios.get(
          "https://auth.ssccglpinnacle.com/api/footer"
        );

        if (response.data && response.data.length > 0) {
          console.log(
            "Fetched Terms and Conditions Data:",
            response.data[0].terms_conditions
          );
          setTermsContent(response.data[0].terms_conditions || "");
        } else {
          setError("Failed to load Terms and Conditions content");
        }
      } catch (error) {
        console.error("Error fetching Terms and Conditions content:", error);
        setError(
          "An error occurred while fetching Terms and Conditions content"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchTermsContent();
  }, []);

  return (
    <>
      <MetaTags type="terms-and-conditions" />
      <Header />
      <div className={styles.termsContainer}>
        {loading && <p>Loading Terms and Conditions...</p>}
        {error && <p className={styles.errorMessage}>{error}</p>}
        {!loading && !error && (
          <div dangerouslySetInnerHTML={{ __html: termsContent }} />
        )}
      </div>
      <Footer />
    </>
  );
};

export default TermsConditions;
