import React, { useEffect, useState } from "react";
import Header from "../../component/HeaderFilesEbook/Header";
import Footer from "../../FooterMain/Footer";
import classes from "../../PagesBook/DigitalContentBody/BookMyOrder.module.css";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../Context/AuthContext";

const BookMyOrder = () => {
  const [purchaseHistory, setPurchaseHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const { isLoggedIn, user } = useAuth();
  const navigate = useNavigate(); // Move useNavigate here

  useEffect(() => {
    const fetchPurchasedBooks = async () => {
      try {
        if (isLoggedIn && user && user.email_id) {
          console.log("Fetching purchased books for user:", user.email_id);

          const response = await fetch(
            `https://auth.ssccglpinnacle.com/api/purchased-books/${user.email_id}`
          );
          console.log("Response from API:", response);

          if (!response.ok) {
            throw new Error(`Failed to fetch purchased books. Status: ${response.status}`);
          }

          const purchasedBooks = await response.json();
          console.log("Purchased Books Data:", purchasedBooks);

          setPurchaseHistory(purchasedBooks || []);
        }
      } catch (error) {
        console.error("Error fetching purchased books:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPurchasedBooks();
  }, [isLoggedIn, user]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const clickHandlerBook = () => {
    navigate(`/purchase-book`);
  };

  return (
    <>
      <Header />
      <div className={classes.book_purchased_main_div}>
        <div className={classes.heading_div}>
          <h2>Purchased Book Course</h2>
        </div>
        <div className={classes.all_button}>
          <button className={classes.order_btn}>
            <Link
              to="https://testportal.ssccglpinnacle.com/myorders"
              target="_blank"
              rel="noopener noreferrer"
            >
              Purchased Test Series
            </Link>
          </button>
          <button className={classes.order_btn}>
            <Link
              to="https://ssccglpinnacle.com/myorders_typing"
              target="_blank"
              rel="noopener noreferrer"
            >
              Purchased Typing Software
            </Link>
          </button>
          <button className={classes.order_btn}>
            <Link
              to="https://ssccglpinnacle.com/videos/myorders"
              target="_blank"
              rel="noopener noreferrer"
            >
              Purchased Video Courses
            </Link>
          </button>
          <button className={classes.order_btn}>
          <Link
              to="https://ssccglpinnacle.com/ebook/myorder"
              target="_blank"
              rel="noopener noreferrer"
            >
              Purchased E-book
            </Link>
          </button>
        </div>
      </div>
      <table className={classes.purchase_table}>
        <thead>
          <tr>
            <th>Order ID</th>
            <th>Product Name</th>
            <th>Product Price</th>
            <th>Editions</th>
          </tr>
        </thead>
        <tbody>
          {purchaseHistory.length > 0 ? (
            purchaseHistory.map((book, index) => (
              <tr key={index}>
                <td>{book.orderId || "N/A"}</td>
                <td
                  style={{ cursor: "pointer", color: "blue" }}
                  onClick={() => clickHandlerBook()}
                >
                  {book.bookDetails?.examName || "N/A"}
                </td>
                <td>{book.bookDetails?.BookSellingPrice}</td>
                <td>{book.bookDetails?.edition || "N/A"}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="3" className={classes.no_data}>
                No purchased books found.
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <div className={classes.myorder_footer}>
        <Footer />
      </div>
    </>
  );
};

export default BookMyOrder;


