// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddKnowledgeBaseForm_container__coODm {
    margin: 20px;
  }
  
  .AddKnowledgeBaseForm_form__k\\+7Q5 {
    display: flex;
    flex-direction: column;
  }
  
  .AddKnowledgeBaseForm_input__SNwVR, .AddKnowledgeBaseForm_textarea__R5yGZ {
    margin-bottom: 10px;
    padding: 8px;
    font-size: 14px;
  }
  
  .AddKnowledgeBaseForm_button__Ug3Ck {
    padding: 10px;
    background-color: #4F6EC6;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .AddKnowledgeBaseForm_button__Ug3Ck:hover {
    background-color:#4F6EC6;
  }
  `, "",{"version":3,"sources":["webpack://./src/chatBot/AddKnowledgeBaseForm.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;EACd;;EAEA;IACE,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,mBAAmB;IACnB,YAAY;IACZ,eAAe;EACjB;;EAEA;IACE,aAAa;IACb,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,eAAe;EACjB;;EAEA;IACE,wBAAwB;EAC1B","sourcesContent":[".container {\n    margin: 20px;\n  }\n  \n  .form {\n    display: flex;\n    flex-direction: column;\n  }\n  \n  .input, .textarea {\n    margin-bottom: 10px;\n    padding: 8px;\n    font-size: 14px;\n  }\n  \n  .button {\n    padding: 10px;\n    background-color: #4F6EC6;\n    color: white;\n    border: none;\n    cursor: pointer;\n  }\n  \n  .button:hover {\n    background-color:#4F6EC6;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `AddKnowledgeBaseForm_container__coODm`,
	"form": `AddKnowledgeBaseForm_form__k+7Q5`,
	"input": `AddKnowledgeBaseForm_input__SNwVR`,
	"textarea": `AddKnowledgeBaseForm_textarea__R5yGZ`,
	"button": `AddKnowledgeBaseForm_button__Ug3Ck`
};
export default ___CSS_LOADER_EXPORT___;
