import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { FaMicrophone } from "react-icons/fa";
import { FaSpinner } from "react-icons/fa"; 
import styles from "./Chatbot.module.css";

function Chatbot() {
  const [query, setQuery] = useState("");
  const [chat, setChat] = useState([{ role: "bot", message: "⚠️ Important Note for Students ⚠️ Our ChatBot is currently facing technical difficulties and may not function as expected. We apologize for any inconvenience this may cause.For urgent assistance or queries, please reach out to us via email at support@ssccglpinnacle.com.Team Pinnacle Coaching 🌟" }]);
  const [isListening, setIsListening] = useState(false); 
  const [isLoading, setIsLoading] = useState(false); 
  const chatWindowRef = useRef(null); 

  const handleSend = async () => {
    if (!query.trim()) return;
    const newChat = [...chat, { role: "user", message: query }];
    setChat(newChat);
    setQuery("");
    setIsLoading(true);

    try {
      const response = await axios.post("https://auth.ssccglpinnacle.com/chat", {
        query,
      });
      setChat([...newChat, { role: "bot", message: response.data.response }]);
    } catch (error) {
      setChat([
        ...newChat,
        { role: "bot", message: "Error: Could not get a response." },
      ]);
    } finally {
      setIsLoading(false);
      scrollToBottom();
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !isLoading) {
      handleSend();
    }
  };

  const startListening = () => {
    if (!("webkitSpeechRecognition" in window)) {
      alert("Speech recognition is not supported in this browser. Please try Chrome.");
      return;
    }

    const SpeechRecognition = window.webkitSpeechRecognition || window.SpeechRecognition;
    const recognition = new SpeechRecognition();
    recognition.lang = "en-US";
    recognition.interimResults = false;

    recognition.start();
    setIsListening(true);

    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;
      setQuery(transcript);
      setIsListening(false);
    };

    recognition.onerror = () => {
      alert("Error with speech recognition. Please try again.");
      setIsListening(false);
    };

    recognition.onend = () => {
      setIsListening(false);
    };
  };

  const scrollToBottom = () => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [chat]);

  return (
    <div className={styles.chatbotContainer}>
      <div className={styles.chatWindow} ref={chatWindowRef}>
        {chat.map((entry, index) => (
          <div
            key={index}
            className={
              entry.role === "user"
                ? styles.userMessage
                : styles.botMessage
            }
          >
            <span className={styles.messageText}>{entry.message}</span>
          </div>
        ))}
      </div>
      <div className={styles.inputArea}>
        <div className={styles.micButton} onClick={startListening}>
          <FaMicrophone
            className={`${styles.micIcon} ${isListening ? styles.listening : ""}`}
            color={isListening ? "red" : "white"}
            fontSize="1.5em"
          />
        </div>
        <input
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onKeyPress={handleKeyPress} // Trigger handleSend on Enter key
          className={styles.inputBox}
          placeholder="Type your message..."
        />
        <button onClick={handleSend} className={styles.sendButton} disabled={isLoading}>
          {isLoading ? <FaSpinner className={styles.spinner} /> : "Send"}
        </button>
      </div>
    </div>
  );
}

export default Chatbot;



// import React, { useState, useRef, useEffect } from "react";
// import axios from "axios";
// import { FaMicrophone } from "react-icons/fa";
// import { FaSpinner } from "react-icons/fa"; 
// import styles from "./Chatbot.module.css";

// function Chatbot() {
//   const [query, setQuery] = useState("");
//   const [chat, setChat] = useState([{ role: "bot", message: "⚠️ Important Note for Students ⚠️ Our ChatBot is currently facing technical difficulties and may not function as expected. We apologize for any inconvenience this may cause.For urgent assistance or queries, please reach out to us via email at support@ssccglpinnacle.com.Team Pinnacle Coaching 🌟" }]);
//   const [isListening, setIsListening] = useState(false); 
//   const [isLoading, setIsLoading] = useState(false); 
//   const chatWindowRef = useRef(null); 

//   const handleSend = async () => {
//     if (!query.trim()) return;
//     const newChat = [...chat, { role: "user", message: query }];
//     setChat(newChat);
//     setQuery("");
//     setIsLoading(true);

//     try {
//       const response = await axios.post("https://auth.ssccglpinnacle.com/chat", {
//         query,
//       });
//       setChat([...newChat, { role: "bot", message: response.data.response }]);
//     } catch (error) {
//       setChat([
//         ...newChat,
//         { role: "bot", message: "Error: Could not get a response." },
//       ]);
//     } finally {
//       setIsLoading(false);
//       scrollToBottom();
//     }
//   };

//   const startListening = () => {
//     if (!("webkitSpeechRecognition" in window)) {
//       alert("Speech recognition is not supported in this browser. Please try Chrome.");
//       return;
//     }

//     const SpeechRecognition = window.webkitSpeechRecognition || window.SpeechRecognition;
//     const recognition = new SpeechRecognition();
//     recognition.lang = "en-US";
//     recognition.interimResults = false;

//     recognition.start();
//     setIsListening(true);

//     recognition.onresult = (event) => {
//       const transcript = event.results[0][0].transcript;
//       setQuery(transcript);
//       setIsListening(false);
//     };

//     recognition.onerror = () => {
//       alert("Error with speech recognition. Please try again.");
//       setIsListening(false);
//     };

//     recognition.onend = () => {
//       setIsListening(false);
//     };
//   };

//   const scrollToBottom = () => {
//     if (chatWindowRef.current) {
//       chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
//     }
//   };

//   useEffect(() => {
//     scrollToBottom();
//   }, [chat]);

//   return (
//     <div className={styles.chatbotContainer}>
//       <div className={styles.chatWindow} ref={chatWindowRef}>
//         {chat.map((entry, index) => (
//           <div
//             key={index}
//             className={
//               entry.role === "user"
//                 ? styles.userMessage
//                 : styles.botMessage
//             }
//           >
//             <span className={styles.messageText}>{entry.message}</span>
//           </div>
//         ))}
//       </div>
//       <div className={styles.inputArea}>
//         <div className={styles.micButton} onClick={startListening}>
//           <FaMicrophone
//             className={`${styles.micIcon} ${isListening ? styles.listening : ""}`}
//             color={isListening ? "red" : "white"}
//             fontSize="1.5em"
//           />
//         </div>
//         <input
//           type="text"
//           value={query}
//           onChange={(e) => setQuery(e.target.value)}
//           className={styles.inputBox}
//           placeholder="Type your message..."
//         />
//         <button onClick={handleSend} className={styles.sendButton} disabled={isLoading}>
//           {isLoading ? <FaSpinner className={styles.spinner} /> : "Send"}
//         </button>
//       </div>
//     </div>
//   );
// }

// export default Chatbot;


// import React, { useState, useRef } from "react";
// import axios from "axios";
// import { FaMicrophone } from "react-icons/fa";
// import { FaSpinner } from "react-icons/fa"; 
// import styles from "./Chatbot.module.css";

// function Chatbot() {
//   const [query, setQuery] = useState("");
//   const [chat, setChat] = useState([]);
//   const [isListening, setIsListening] = useState(false); 
//   const [isLoading, setIsLoading] = useState(false); 
//   const chatWindowRef = useRef(null); 
//   const handleSend = async () => {
//     if (!query.trim()) return;
//     const newChat = [...chat, { role: "user", message: query }];
//     setChat(newChat);
//     setQuery("");
//     setIsLoading(true);

//     try {
//       const response = await axios.post("https://auth.ssccglpinnacle.com/chat", {
//         query,
//       });
//       setChat([...newChat, { role: "bot", message: response.data.response }]);
//     } catch (error) {
//       setChat([
//         ...newChat,
//         { role: "bot", message: "Error: Could not get a response." },
//       ]);
//     } finally {
//       setIsLoading(false);
//       scrollToBottom();
//     }
//   };
//   const startListening = () => {
//     if (!("webkitSpeechRecognition" in window)) {
//       alert("Speech recognition is not supported in this browser. Please try Chrome.");
//       return;
//     }

//     const SpeechRecognition = window.webkitSpeechRecognition || window.SpeechRecognition;
//     const recognition = new SpeechRecognition();
//     recognition.lang = "en-US";
//     recognition.interimResults = false;

//     recognition.start();
//     setIsListening(true);

//     recognition.onresult = (event) => {
//       const transcript = event.results[0][0].transcript;
//       setQuery(transcript);
//       setIsListening(false);
//     };

//     recognition.onerror = () => {
//       alert("Error with speech recognition. Please try again.");
//       setIsListening(false);
//     };

//     recognition.onend = () => {
//       setIsListening(false);
//     };
//   };
//   const scrollToBottom = () => {
//     if (chatWindowRef.current) {
//       chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
//     }
//   };

//   return (
//     <div className={styles.chatbotContainer}>
//       <div className={styles.chatWindow} ref={chatWindowRef}>
//         {chat.map((entry, index) => (
//           <div
//             key={index}
//             className={
//               entry.role === "user"
//                 ? styles.userMessage
//                 : styles.botMessage
//             }
//           >
//             <span className={styles.messageText}>{entry.message}</span>
//           </div>
//         ))}
//       </div>
//       <div className={styles.inputArea}>
//         <div className={styles.micButton} onClick={startListening}>
//           <FaMicrophone
//             className={`${styles.micIcon} ${isListening ? styles.listening : ""}`}
//             color={isListening ? "red" : "white"}
//             fontSize="1.5em"
//           />
//         </div>
//         <input
//           type="text"
//           value={query}
//           onChange={(e) => setQuery(e.target.value)}
//           className={styles.inputBox}
//           placeholder="Type your message..."
//         />
//         <button onClick={handleSend} className={styles.sendButton} disabled={isLoading}>
//           {isLoading ? <FaSpinner className={styles.spinner} /> : "Send"}
//         </button>
//       </div>
//     </div>
//   );
// }

// export default Chatbot;