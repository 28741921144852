import React, { useState, useEffect } from "react";
import { useAuth } from "../Context/AuthContext";
import "../InfoBook/Intro.css";
import ShareModal from "../InfoBook/ShareModalBook";
import { GiCheckMark } from "react-icons/gi";
import Footer from "../FooterMain/Footer";
import Header from "../componentbook/HeaderFilesBook/HeaderBook";
import LoadingSpinner from "../LoadingSpinner";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import MetaTags from "../DynamicMetaData/DynamicMetadata";
// Image imports
import typing1 from "../Ebook/i/typing1.png";
import typing2 from "../Ebook/i/typing2.png";
import typing3 from "../Ebook/i/typing3.png";
import typing4 from "../Ebook/i/typing4.png";
import typing5 from "../Ebook/i/typing5.png";
import typing6 from "../Ebook/i/typing6.png";
import typing7 from "../Ebook/i/typing7.png";
import typing8 from "../Ebook/i/typing8.png";
import typing9 from "../Ebook/i/typing9.png";

import BuyButtonTyping from "./BuyButtonTyping"; // Import the BuyButtonTyping component

function Typing1() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [currentSlide, setCurrentSlide] = useState(0);
  const { user } = useAuth(); // Get user details from Auth context
  const [cookies] = useCookies(["token"]);

  const images = [
    typing1,
    typing2,
    typing3,
    typing4,
    typing5,
    typing6,
    typing7,
    typing8,
    typing9,
  ];

  useEffect(() => {
    // Slider logic
    const slideInterval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
    }, 3000);

    return () => clearInterval(slideInterval);
  }, [images.length]);

  useEffect(() => {
    // Simulate loading completion
    setLoading(false);
  }, []);

  useEffect(() => {
    // Check product access on component load
    const checkAccessTypingProduct = async () => {
      try {
        const response = await fetch(
          "https://auth.ssccglpinnacle.com/api/checkAccessTypingProduct",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${cookies.token}`,
            },
            body: JSON.stringify({ product_id: "999" }), // Product ID is 999
          }
        );

        if (response.ok) {
          const { access: productAccess } = await response.json();
          if (productAccess === "access") {
            navigate("/typingexamselection");
          }
        } else {
          console.log("Product access check failed");
        }
      } catch (error) {
        console.error("Error checking product access:", error);
      }
    };

    checkAccessTypingProduct();
    setLoading(false); // Stop loading after access check
  }, [navigate]);

  if (loading) {
    return <LoadingSpinner />;
  }

  // Debug log to check user details

  return (
    <>
      <MetaTags type="typing" />
      <Header />
      <div className="container-body-intro">
        <div className="container-body-for-intro">
          <div className="for-intro-row1-image-introproduct">
            <div className="row2-for-intro-product-typing">
              <div className="for-image-intro-product-typing">
                <div className="image-container-typing">
                  <img
                    className="image-contaner-book-image-typing"
                    src={images[currentSlide]}
                    alt={`Slide ${currentSlide + 1}`}
                  />
                </div>
                <div className="buy-it-now-from-buy-model-intro">
                  <BuyButtonTyping userDetails={user} />{" "}
                  {/* Passing user details to BuyButtonTyping */}
                </div>
              </div>
            </div>
            <div className="row2-for-intro-product-typing">
              <div className="name-of-the-product">
                <h2>
                  Pinnacle Typing Software based on TCS Pattern: SSC CGL I CHSL
                  Tier 2
                </h2>
              </div>
              <div className="price-details-intro">
                <p className="original-price-intro">₹999</p>
                <p className="discounted-price-intro"> ₹299</p>
                <div className="Value-deal">
                  <p>VALUE DEAL! Save &nbsp;</p>
                </div>
                <div className="share-product">
                  <ShareModal />
                </div>
              </div>
              <div className="intro-of-the-product">
                <p className="intro-1-typing">
                  This is unique typing software designed specially for SSC CGL
                  Tier 2 and SSC CHSL Tier 2 exam. Important features of this
                  typing software are:
                </p>
                <p className="intro-2">
                  <span
                    style={{
                      color: "rgb(224, 62, 45)",
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    Please note: Rs 299 fee is for one year subscription as a
                    special offer. For those who could not purchase till today
                    11:59 pm, fee will be Rs 999/- for one year i.e. yearly
                    subscription.&nbsp;
                  </span>
                </p>
                <div className="intro-2">
                  <GiCheckMark className="tick" />
                  <a
                    href="https://testseries.ssccglpinnacle.com"
                    target="_blank"
                    rel="noopener"
                  >
                    <strong>TCS like interface typing software</strong>
                  </a>
                </div>
                <div className="intro-2">
                  <GiCheckMark className="tick" />
                  Exam wise typing like SSC CGL Tier 2, SSC CHSL Tier 2
                </div>
                <div className="intro-2">
                  <GiCheckMark className="tick" />
                  Category wise report like UR, OBC, SC etc
                </div>
                <div className="intro-2">
                  <GiCheckMark className="tick" />
                  Previous year papers /paragraph for typing
                </div>
                <div className="intro-2">
                  <GiCheckMark className="tick" />
                  Practice sets based on real exam pattern typing
                </div>
                <div className="intro-2">
                  <GiCheckMark className="tick" />8 types analysis report
                </div>
                <div className="intro-2">
                  <GiCheckMark className="tick" />
                  Timing, error as per ssc syllabus and instructions.
                </div>
                <div className="intro-2">
                  <GiCheckMark className="tick" />
                  Can do typing through{" "}
                  <a
                    href="https://play.google.com/store/apps/details?id=com.ssccgl.pinnacle"
                    target="_blank"
                    rel="noopener"
                  >
                    Pinnacle exam preparation mobile app
                  </a>{" "}
                  also if anybody does not have laptop/desktop
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Typing1;
