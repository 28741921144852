import React, { useState } from "react";
import { useAuth } from "../Context/AuthContext";
import { useNavigate, Link } from "react-router-dom";
import Header from "../component/HomePage01/Header/Header";
import Footer from "../component/Footer/Footer";
import axios from "axios";
import classes from "./MyOrders.module.css";

const MyOrders = () => {
  const { isLoggedIn, user } = useAuth();
  const navigate = useNavigate();
  const [productData, setProductData] = useState({ purchasedVideos: [] });
  const [coursesData, setCoursesData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState("");

  // Redirect if not logged in
  if (!isLoggedIn) {
    navigate("/");
    return null; // Return null to avoid rendering the rest of the component
  }

  // Fetch data if user exists
  const fetchOrders = async () => {
    if (!user) return;

    try {
      const response = await axios.get(
        `https://auth.ssccglpinnacle.com/api/purchased_videos/${user._id}`
      );

      const coursesResponse = await axios.get(
        "https://auth.ssccglpinnacle.com/api/courses"
      );

      if (response.data.purchasedVideos.length === 0) {
        setMessage("You have not bought any product yet.");
      } else {
        setProductData(response.data);
        setCoursesData(coursesResponse.data);
      }
    } catch (error) {
      console.error("An internal server error occurred:", error);
      setMessage("An error occurred while fetching data.");
    } finally {
      setIsLoading(false);
    }
  };
//   console.log("coursesData:", coursesData);
//   console.log("productData:", productData);

  if (isLoading) {
    // Trigger fetchOrders only once when loading is true
    fetchOrders();
    return <div className={classes.loader}>Loading...</div>;
  }

  const getCourseDetails = (productId) => {
    const course = coursesData.find((course) => course._id === productId);
    return course ? course : { courseTitle: "Unknown Product", price: "N/A", duration: "N/A" };
  };

  const handleOrderClick = (productId) => {
    navigate(`/youtube_learning/${productId}`);
  };

  return (
    <>
    <Header />
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.heading_title}>
        <h1 className={classes.title}>Purchased Video Course</h1>
        </div>
        <div className={classes.btn_main_div}>
        <button className={classes.button_link}>
          <Link
            to="https://testportal.ssccglpinnacle.com/myorders"
            target="_blank"
            rel="noopener noreferrer"
          >
            Purchased Test Series
          </Link>
        </button>
        <button className={classes.button_link}>
          <Link
            to="https://ssccglpinnacle.com/typing"
            target="_blank"
            rel="noopener noreferrer"
          >
            Purchased Typing Software
          </Link>
        </button>
        <button className={classes.button_link}>
          <Link
            to="https://ssccglpinnacle.com/ebook/myorder"
            target="_blank"
            rel="noopener noreferrer"
          >
            Purchased E-Books
          </Link>
        </button>
        <button className={classes.button_books}>
          <Link
            to="https://ssccglpinnacle.com/book/myorder"
            target="_blank"
            rel="noopener noreferrer"
          >
            Purchased Books
          </Link>
        </button>
        </div>
      </div>
      {message && <p className={classes.message}>{message}</p>}
      <table className={classes.table}>
        <thead>
          <tr>
            <th>Order ID</th>
            <th>Product Name</th>
            <th>Product Price</th>
            <th>Product Validation Period</th>
            <th>Expiry Date</th>
          </tr>
        </thead>
        <tbody>
          {productData.purchasedVideos.map((purchasedVideo) => {
            const courseDetails = getCourseDetails(purchasedVideo.productId);
            return (
              <tr key={purchasedVideo.productId}>
                <td>{purchasedVideo.productId}</td>
                <td onClick={() => handleOrderClick(purchasedVideo.productId)} style={{ cursor: "pointer", color: "blue" }}>{courseDetails.courseTitle}</td>
                <td>{courseDetails.price}</td>
                <td>{purchasedVideo.duration}</td>
                <td>
                  {new Date(purchasedVideo.expirationDate).toLocaleDateString()}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
    <Footer />
    </>
  );
};

export default MyOrders;


