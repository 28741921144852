import React, { useEffect } from "react";
import Header from "../HomePage01/Header/Header";
import PromotionSlider from "../../PromotionSlider/PramotionSlider";
import Body from "./Body/Body";
import "./Home.css";
import Footer from "../Footer/Footer";
import VideoTimer from "./Header/Timer";
import MetaTags from "../../DynamicMetaData/DynamicMetadata";

export default function HomePage01() {
  useEffect(() => {
    fetch("https://auth.ssccglpinnacle.com/api/track-visit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        page: "/videos", // Tracking this page visit
      }),
    })
      .then((response) => response.json())
      .then((data) => console.log("Visit tracked:", data)) // Log successful tracking
      .catch((error) => console.error("Error tracking visit:", error)); // Log errors
  }, []);

  return (
    <>
      <MetaTags type="videos" />
      <VideoTimer />
      <div className="main-body-container">
        <Header />
        <div>
          <PromotionSlider />
        </div>
        <Body />
        <Footer />
      </div>
    </>
  );
}

// import React, { useEffect } from 'react';
// import Header from "../HomePage01/Header/Header";
// import PromotionSlider from "../../PromotionSlider/PramotionSlider";
// import Body from "./Body/Body";
// import "./Home.css";
// import Footer from "../Footer/Footer";
// import VideoTimer from "./Header/Timer";

// export default function HomePage01() {
//     useEffect(() => {
//         fetch('https://auth.ssccglpinnacle.com/api/track-visit', {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//             },
//             body: JSON.stringify({
//                 page: '/videos',
//             }),
//         })
//         .then(response => response.json())
//         .then(data => console.log('Visit tracked:', data))
//         .catch(error => console.error('Error tracking visit:', error));
//     }, []);

//     return (
//         <>
//             <VideoTimer />
//             <div className="main-body-container">
//                 <Header />
//                 <div>
//                     <PromotionSlider />
//                 </div>
//                 <Body />
//             </div>
//             <Footer />
//         </>
//     );
// }

// import React from "react";
// import Header from "../HomePage01/Header/Header"
// import PromotionSlider from "../../PromotionSlider/PramotionSlider";
// import Body from "./Body/Body";
// import "./Home.css";
// import Footer from "../Footer/Footer";
// import VideoTimer from "./Header/Timer";
// export default function HomePage01() {
//   return (
//     <>

//       <VideoTimer/>

//     <div className="main-body-container">
//       <Header/>
//       <div>
//         <PromotionSlider />
//       </div>
//       <Body />
//     </div>
//     <Footer/>
//     </>
//   );
// }
