import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import styles from "./ManageMetaDataForm.module.css";

// const baseURL = "http://localhost:5000/api";
const baseURL = "https://auth.ssccglpinnacle.com/api";

const ManageMetaDataForm = () => {
  const [metaData, setMetaData] = useState([]);
  const [editingMeta, setEditingMeta] = useState(null);
  const [editFormData, setEditFormData] = useState({
    type: "",
    title: "",
    description: "",
    keywords: "",
  });

  useEffect(() => {
    fetchMetaData();
  }, []);

  const fetchMetaData = async () => {
    try {
      const response = await fetch(`${baseURL}/meta`);
      const data = await response.json();
      setMetaData(data);
    } catch (error) {
      console.error("Error fetching metadata:", error);
    }
  };

  const handleDelete = async (type) => {
    try {
      const response = await fetch(`${baseURL}/meta/${type}`, {
        method: "DELETE",
      });

      if (response.ok) {
        Swal.fire("Success", "Metadata deleted successfully!", "success");
        fetchMetaData();
      } else {
        Swal.fire("Error", "Failed to delete metadata.", "error");
      }
    } catch (error) {
      Swal.fire("Error", "Something went wrong!", "error");
    }
  };

  const handleEdit = (meta) => {
    setEditingMeta(meta._id);
    setEditFormData({
      type: meta.type,
      title: meta.title,
      description: meta.description,
      keywords: meta.keywords,
    });
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSaveEdit = async () => {
    try {
      const response = await fetch(`${baseURL}/meta/${editFormData.type}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(editFormData),
      });

      if (response.ok) {
        Swal.fire("Success", "Metadata updated successfully!", "success");
        setEditingMeta(null);
        fetchMetaData();
      } else {
        Swal.fire("Error", "Failed to update metadata.", "error");
      }
    } catch (error) {
      Swal.fire("Error", "Something went wrong!", "error");
    }
  };

  const handleCancelEdit = () => {
    setEditingMeta(null);
    setEditFormData({
      type: "",
      title: "",
      description: "",
      keywords: "",
    });
  };

  return (
    <div className={styles.ogManageContainer}>
      <h2 className={styles.ogManageTitle}>Manage Metadata</h2>
      {metaData.length > 0 ? (
        <table className={styles.ogManageTable}>
          <thead>
            <tr>
              <th>Type</th>
              <th>Title</th>
              <th>Description</th>
              <th>Keywords</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {metaData.map((meta) =>
              editingMeta === meta._id ? (
                <tr key={meta._id}>
                  <td>
                    <input
                      type="text"
                      name="type"
                      value={editFormData.type}
                      onChange={handleEditChange}
                      className={styles.editInput}
                      disabled
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="title"
                      value={editFormData.title}
                      onChange={handleEditChange}
                      className={styles.editInput}
                    />
                  </td>
                  <td>
                    <textarea
                      name="description"
                      value={editFormData.description}
                      onChange={handleEditChange}
                      className={styles.editTextarea}
                    ></textarea>
                  </td>
                  <td>
                    <input
                      type="text"
                      name="keywords"
                      value={editFormData.keywords}
                      onChange={handleEditChange}
                      className={styles.editInput}
                    />
                  </td>
                  <td>
                    <button
                      className={styles.saveButton}
                      onClick={handleSaveEdit}
                    >
                      Save
                    </button>
                    <button
                      className={styles.cancelButton}
                      onClick={handleCancelEdit}
                    >
                      Cancel
                    </button>
                  </td>
                </tr>
              ) : (
                <tr key={meta._id}>
                  <td>{meta.type}</td>
                  <td>{meta.title}</td>
                  <td>{meta.description}</td>
                  <td>{meta.keywords}</td>
                  <td>
                    <button
                      className={styles.editButton}
                      onClick={() => handleEdit(meta)}
                    >
                      Edit
                    </button>
                    <button
                      className={styles.deleteButton}
                      onClick={() => handleDelete(meta.type)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      ) : (
        <p className={styles.noDataMessage}>No metadata available.</p>
      )}
    </div>
  );
};

export default ManageMetaDataForm;
