import React, { useRef, useEffect, useState } from "react";
import styles from "./sscCategory.module.css";
import { useNavigate, useParams } from "react-router-dom";
import NavbarVC from "./NavBarVC";
import { FaAnglesDown, FaAnglesUp } from "react-icons/fa6";
import Footer from "../FooterMain/Footer";

export default function VCCategory() {
  const [selectedExam, setSelectedExam] = useState(null);
  const [exams, setExams] = useState([]);
  const [categoryDetails, setCategoryDetails] = useState(null);
  const [courses, setCourses] = useState([]);
  const scrollRef = useRef(null);
  const courseRef = useRef(null);

  const { categoryId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (categoryId) {
      fetch(`https://auth.ssccglpinnacle.com/exams/category/${categoryId}`)
        .then((response) => response.json())
        .then((data) => {
          setExams(Array.isArray(data) ? data : []);
          if (data.length > 0) {
            setCategoryDetails(data[0].categoryId);
            setSelectedExam(data[0]); // Automatically select the first exam
            fetchCourses(data[0].categoryId.categoryTitle);
          }
        })
        .catch((error) => console.error("Error fetching exams:", error));
    }
  }, [categoryId]);

  const fetchCourses = (categoryTitle) => {
    fetch(`https://auth.ssccglpinnacle.com/api/videoCourses/${categoryTitle}`)
      .then((response) => response.json())
      .then((data) => setCourses(data))
      .catch((error) => console.error("Error fetching courses:", error));
  };

  // const scroll = (shift) => {
  //   const targetScroll = scrollRef.current.scrollLeft + shift;
  //   gsap.to(scrollRef.current, {
  //     scrollLeft: targetScroll,
  //     duration: 0.6,
  //     ease: "power2.inOut",
  //   });
  // };

  const scrollToCourses = () => {
    courseRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const scrollToBottom = () => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
  };

  const selectExamHandler = (exam) => {
    setSelectedExam(exam);
  };

  return (
    <>
      <NavbarVC />
      <div className={styles["ssc-category-div"]}>
        <div className={styles["EM-header"]}>
          <div className={styles["EM-header-container01"]}>
            {categoryDetails ? (
              <img
                src={categoryDetails.categoryImage}
                alt={categoryDetails.categoryTitle}
                className={styles["EM-image"]}
              />
            ) : (
              <img
                src="https://d3m4h509gttb94.cloudfront.net/Image/ssc-logo.webp"
                alt="Default SSC Logo"
                className={styles["EM-image"]}
              />
            )}
          </div>
          <div className={styles["EM-header-container02"]}>
            <div className={styles["EM-header-heading"]}>
              {categoryDetails
                ? `${categoryDetails.categoryTitle}${
                    categoryDetails.categoryFullForm
                      ? ` (${categoryDetails.categoryFullForm})`
                      : ""
                  }`
                : "SSC (Staff Selection Commission)"}
            </div>
            <button
              onClick={scrollToCourses}
              className={styles["allCourses-scrollButton"]}
            >
              Show All {categoryDetails ? categoryDetails.categoryTitle : "SSC"}{" "}
              Courses
            </button>
            <button
              onClick={scrollToBottom}
              className={styles["allCourses-scrollButton"]}
            >
              <FaAnglesDown />
            </button>

            <div className={styles["EM-header-para"]}>
              {categoryDetails
                ? categoryDetails.categoryDescription
                : "SSC is a highly competitive exam in India because lakhs of students appear for the exam to fulfill thousands of vacancies. The level of competition is quite high. Hence the preparation should be done beforehand to ace the exam."}
            </div>
          </div>
        </div>

        {/* Render the list of exams dynamically */}
        <div className={styles["EM-body"]}>
          <div className={styles["EM-body-container1"]}>
            {exams.map((exam) => (
              <div key={exam._id} className={styles["EM-body-buttons"]}>
                <button
                  className={styles["EM-header-indi-butBtn"]}
                  onClick={() => selectExamHandler(exam)}
                >
                  {exam.examName}
                </button>
              </div>
            ))}
          </div>

          <div className={styles["EM-body-container2"]}>
            {selectedExam && (
              <div className={styles["EM-header-para"]}>
                <strong>{selectedExam.examName}</strong>
                <div
                  className={styles["EM-header-para"]}
                  dangerouslySetInnerHTML={{
                    __html: selectedExam.examDescription,
                  }}
                ></div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Related courses section */}
      <div className={styles["related-course"]}>
        <div className={styles["mostPopular-div"]}>
          <div className={styles["Course_Heading"]}>
            All {categoryDetails ? categoryDetails.categoryTitle : "SSC"}
            Courses
          </div>
          <div className={styles["scroll-container"]} ref={scrollRef}>
            <div className={styles["card-wrapper"]} ref={courseRef}>
              {courses.map((course) => (
                <div
                  key={course._id}
                  className={styles["card"]}
                  onClick={() => navigate(`/course/description/${course._id}`)}
                >
                  <div className={styles["Image-section"]}>
                    <img
                      className={styles.imagecard}
                      src={course.hindiCoverImage}
                      alt="Hindi Cover"
                    />
                    <img
                      className={styles.imagecard}
                      src={course.englishCoverImage}
                      alt="English Cover"
                    />
                  </div>
                  <div className={styles["description-section"]}>
                    <div className={styles["title"]}>{course.courseTitle}</div>
                    <div className={styles["gst"]}>18% GST included</div>
                    <div className={styles["price-fprice-div"]}>
                      <div className={styles["price"]}>₹ {course.price}</div>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <div className={styles["mrp"]}>
                        <s>₹{course.mrp}</s>
                      </div>
                      <div className={styles["savings"]}>
                        Save
                        {Math.round(
                          ((course.mrp - course.price) / course.mrp) * 100
                        )}
                        %
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className={styles["scroll-buttons"]}>
        <button
          onClick={scrollToTop}
          className={styles["allCourses-scrollButton"]}
        >
          <FaAnglesUp />
        </button>
      </div>
      <Footer/>
    </>
  );
}


// import React, { useRef, useEffect, useState } from "react";
// import styles from "./sscCategory.module.css";
// import gsap from "gsap";
// import { useNavigate, useParams } from "react-router-dom";
// import NavbarVC from "./NavBarVC";
// import { FaAnglesDown, FaAnglesUp } from "react-icons/fa6";

// export default function VCCategory() {
//   const [selectedExam, setSelectedExam] = useState(null);
//   const [exams, setExams] = useState([]);
//   const [categoryDetails, setCategoryDetails] = useState(null);
//   const [courses, setCourses] = useState([]);
//   const scrollRef = useRef(null);
//   const courseRef = useRef(null);

//   const { categoryId } = useParams();
//   const navigate = useNavigate();
//   useEffect(() => {
//     if (categoryId) {
//       fetch(`https://auth.ssccglpinnacle.com/exams/category/${categoryId}`)
//         .then((response) => response.json())
//         .then((data) => {
//           setExams(Array.isArray(data) ? data : []);
//           if (data.length > 0) {
//             setCategoryDetails(data[0].categoryId);
//             fetchCourses(data[0].categoryId.categoryTitle);
//           }
//         })
//         .catch((error) => console.error("Error fetching exams:", error));
//     }
//   }, [categoryId]);
//   const fetchCourses = (categoryTitle) => {
//     fetch(`https://auth.ssccglpinnacle.com/api/videoCourses/${categoryTitle}`)
//       .then((response) => response.json())
//       .then((data) => setCourses(data))
//       .catch((error) => console.error("Error fetching courses:", error));
//   };

//   const scroll = (shift) => {
//     const targetScroll = scrollRef.current.scrollLeft + shift;
//     gsap.to(scrollRef.current, {
//       scrollLeft: targetScroll,
//       duration: 0.6,
//       ease: "power2.inOut",
//     });
//   };

//   const scrollToCourses = () => {
//     courseRef.current.scrollIntoView({ behavior: "smooth" });
//   };

//   const scrollToTop = () => {
//     window.scrollTo({ top: 0, behavior: "smooth" });
//   };

//   const scrollToBottom = () => {
//     window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
//   };

//   const selectExamHandler = (exam) => {
//     setSelectedExam(exam);
//   };

//   return (
//     <>
//       <NavbarVC />
//       <div className={styles["ssc-category-div"]}>
//         <div className={styles["EM-header"]}>
//           <div className={styles["EM-header-container01"]}>
//             {categoryDetails ? (
//               <img
//                 src={categoryDetails.categoryImage}
//                 alt={categoryDetails.categoryTitle}
//                 className={styles["EM-image"]}
//               />
//             ) : (
//               <img
//                 src="https://d3m4h509gttb94.cloudfront.net/Image/ssc-logo.webp"
//                 alt="Default SSC Logo"
//                 className={styles["EM-image"]}
//               />
//             )}
//           </div>
//           <div className={styles["EM-header-container02"]}>
//             <div className={styles["EM-header-heading"]}>
//               {categoryDetails
//                 ? `${categoryDetails.categoryTitle}${
//                     categoryDetails.categoryFullForm
//                       ? ` (${categoryDetails.categoryFullForm})`
//                       : ""
//                   }`
//                 : "SSC (Staff Selection Commission)"}
//             </div>
//             <button
//               onClick={scrollToCourses}
//               className={styles["allCourses-scrollButton"]}
//             >
//               Show All {categoryDetails ? categoryDetails.categoryTitle : "SSC"}{" "}
//               Courses
//             </button>
//             <button
//               onClick={scrollToBottom}
//               className={styles["allCourses-scrollButton"]}
//             >
//               <FaAnglesDown />
//             </button>

//             <div className={styles["EM-header-para"]}>
//               {categoryDetails
//                 ? categoryDetails.categoryDescription
//                 : "SSC is a highly competitive exam in India because lakhs of students appear for the exam to fulfill thousands of vacancies. The level of competition is quite high. Hence the preparation should be done beforehand to ace the exam."}
//             </div>
//           </div>
//         </div>

//         {/* Render the list of exams dynamically */}
//         <div className={styles["EM-body"]}>
//           <div className={styles["EM-body-container1"]}>
//             {exams.map((exam) => (
//               <div key={exam._id} className={styles["EM-body-buttons"]}>
//                 <button
//                   className={styles["EM-header-indi-butBtn"]}
//                   onClick={() => selectExamHandler(exam)}
//                 >
//                   {exam.examName}
//                 </button>
//               </div>
//             ))}
//           </div>

//           <div className={styles["EM-body-container2"]}>
//             {selectedExam && (
//               <div className={styles["EM-header-para"]}>
//                 <strong>{selectedExam.examName}</strong>
//                 <div
//                   className={styles["EM-header-para"]}
//                   dangerouslySetInnerHTML={{
//                     __html: selectedExam.examDescription,
//                   }}
//                 ></div>
//               </div>
//             )}
//           </div>
//         </div>
//       </div>

//       {/* Related courses section */}
//       <div className={styles["related-course"]}>
//         <div className={styles["mostPopular-div"]}>
//           <div className={styles["Course_Heading"]}>
//             All {categoryDetails ? categoryDetails.categoryTitle : "SSC"}
//             Courses
//           </div>
//           <div className={styles["scroll-container"]} ref={scrollRef}>
//             <div className={styles["card-wrapper"]} ref={courseRef}>
//               {courses.map((course) => (
//                 <div
//                   key={course._id}
//                   className={styles["card"]}
//                   onClick={() => navigate(`/course/description/${course._id}`)}
//                 >
//                   <div className={styles["Image-section"]}>
//                     <img
//                       className={styles.imagecard}
//                       src={course.hindiCoverImage}
//                       alt="Hindi Cover"
//                     />
//                     <img
//                       className={styles.imagecard}
//                       src={course.englishCoverImage}
//                       alt="English Cover"
//                     />
//                   </div>
//                   <div className={styles["description-section"]}>
//                     <div className={styles["title"]}>{course.courseTitle}</div>
//                     <div className={styles["gst"]}>18% GST included</div>
//                     <div className={styles["price-fprice-div"]}>
//                       <div className={styles["price"]}>₹ {course.price}</div>
//                       &nbsp;&nbsp;&nbsp;&nbsp;
//                       <div className={styles["mrp"]}>
//                         <s>₹{course.mrp}</s>
//                       </div>
//                       <div className={styles["savings"]}>
//                         Save{" "}
//                         {Math.round(
//                           ((course.mrp - course.price) / course.mrp) * 100
//                         )}
//                         %
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               ))}
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className={styles["scroll-buttons"]}>
//         <button
//           onClick={scrollToTop}
//           className={styles["allCourses-scrollButton"]}
//         >
//           <FaAnglesUp />
//         </button>
//       </div>
//     </>
//   );
// }
