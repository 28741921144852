import React, { useEffect, useState } from "react";
import styles from "./Sitemap.module.css";
import MetaTags from "../../../DynamicMetaData/DynamicMetadata";

const SitemapPage = () => {
  const [sitemapData, setSitemapData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  // const baseURL = "http://localhost:5000/api";
  const baseURL = "https://auth.ssccglpinnacle.com/api";

  useEffect(() => {
    fetch(`${baseURL}/sitemap-data`)
      .then((res) => res.json())
      .then((data) => {
        setSitemapData(data || []);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching sitemap data:", err);
        setError(true);
        setLoading(false);
      });
  }, []);

  if (loading) return <p>Loading sitemap...</p>;
  if (error) return <p>Error loading sitemap. Please try again later.</p>;

  return (
    <>
      <MetaTags type="sitemap" />
      <div className={styles.sitemapContainer}>
        <h1 className={styles.sitemapTitle}>Sitemap</h1>
        <div className={styles.sitemapContent}>
          {Array.isArray(sitemapData) && sitemapData.length > 0 ? (
            sitemapData.map((category, categoryIndex) => (
              <div key={categoryIndex} className={styles.sitemapCategory}>
                <h2 className={styles.categoryTitle}>
                  {category.category || "Uncategorized"}
                </h2>
                <ul className={styles.linkList}>
                  {Array.isArray(category.links) &&
                  category.links.length > 0 ? (
                    category.links.map((link, linkIndex) => (
                      <li key={linkIndex} className={styles.linkItem}>
                        <a
                          href={link.url || "/"}
                          className={styles.sitemapLink}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {link.title || "Untitled"}
                        </a>
                      </li>
                    ))
                  ) : (
                    <li className={styles.linkItem}>
                      <span>No links available</span>
                    </li>
                  )}
                </ul>
              </div>
            ))
          ) : (
            <p>No sitemap data available.</p>
          )}
        </div>
      </div>
    </>
  );
};

export default SitemapPage;
