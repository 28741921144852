import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../Context/AuthContext";
import Swal from "sweetalert2";
import {
  Typography,
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Autocomplete,
  CircularProgress,
} from "@mui/material";
import { MaterialReactTable } from "material-react-table";
import styles from "./ManagePdfListing.module.css";

const ManagePDFs = () => {
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(
    localStorage.getItem("lastSelectedCourse") || ""
  );
  const [pdfs, setPdfs] = useState([]);
  const { token } = useAuth();

  const [isLoadingCourses, setIsLoadingCourses] = useState(true);
  const [isLoadingPdfs, setIsLoadingPdfs] = useState(false);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [newTitle, setNewTitle] = useState("");
  const [newPdfId, setNewPdfId] = useState("");
  const [editingPdf, setEditingPdf] = useState(null);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await axios.get(
          "https://auth.ssccglpinnacle.com/api/courses",
          {
            headers: {},
          }
        );
        setCourses(response.data);
        if (!selectedCourse && response.data.length > 0) {
          setSelectedCourse(response.data[0]._id);
        }
      } catch (error) {
        console.error("Error fetching courses:", error);
      } finally {
        setIsLoadingCourses(false);
      }
    };

    fetchCourses();
  }, [token]);

  useEffect(() => {
    fetchPdfs();
  }, [selectedCourse, token]);

  const fetchPdfs = async () => {
    if (selectedCourse) {
      setIsLoadingPdfs(true);
      try {
        const response = await axios.get(
          `https://auth.ssccglpinnacle.com/api/video-pdfs/${selectedCourse}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setPdfs(response.data);
      } catch (error) {
        console.error("Error fetching PDFs:", error);
      } finally {
        setIsLoadingPdfs(false);
      }
    }
  };

  const handleCourseChange = (event, value) => {
    if (value) {
      const courseId = value._id;
      setSelectedCourse(courseId);
      localStorage.setItem("lastSelectedCourse", courseId);
      setPdfs([]);
    }
  };

  const handleDeletePdf = async (pdfId) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Do you really want to delete this PDF? This action cannot be undone!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    });

    if (result.isConfirmed) {
      try {
        const response = await axios.delete(
          `https://auth.ssccglpinnacle.com/api/delete-pdfs/${pdfId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data.success) {
          setPdfs((prevPdfs) => prevPdfs.filter((pdf) => pdf._id !== pdfId));
          Swal.fire({
            title: "Deleted!",
            text: "The PDF has been deleted.",
            icon: "success",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        console.error("Error deleting PDF:", error);
        Swal.fire({
          title: "Failed",
          text: "Failed to delete PDF",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    }
  };

  const handleEditPdf = async (pdfId, newTitle, newId) => {
    try {
      const response = await axios.put(
        `https://auth.ssccglpinnacle.com/api/pdfs/${pdfId}`,
        { originalname: newTitle, newId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.success) {
        setPdfs((prevPdfs) =>
          prevPdfs.map((pdf) =>
            pdf._id === pdfId
              ? { ...pdf, originalname: newTitle, _id: newId }
              : pdf
          )
        );
        setEditingPdf(null);
        Swal.fire({
          title: "Success!",
          text: "PDF title and ID updated successfully",
          icon: "success",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      console.error("Error updating PDF title and ID:", error);
      Swal.fire({
        title: "Failed",
        text: "Failed to update PDF title and ID",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  const handleOpenDialog = (pdf) => {
    setEditingPdf(pdf);
    setNewTitle(pdf.originalname);
    setNewPdfId(pdf._id);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setEditingPdf(null);
    setNewTitle("");
    setNewPdfId("");
  };

  const handleSubmitDialog = () => {
    if (newTitle.trim() && newPdfId.trim()) {
      handleEditPdf(editingPdf._id, newTitle, newPdfId).then(() => {
        fetchPdfs();
        handleCloseDialog();
      });
    }
  };

  const columns = [
    {
      accessorKey: "serialNumber",
      header: "S.No",
      size: 10,
    },
    {
      accessorKey: "_id",
      header: "ID",
      size: 40,
    },
    {
      accessorKey: "originalname",
      header: "Title",
      size: 250,
    },
    {
      accessorKey: "cloudFrontUrl",
      header: "URL",
      size: 100,
      Cell: ({ cell }) => (
        <a
          href={cell.getValue()}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            display: "block",
            maxWidth: "200px",
          }}
        >
          {cell.getValue()}
        </a>
      ),
    },
    {
      header: "Actions",
      size: 50,
      Cell: ({ row }) => (
        <div style={{ display: "flex" }}>
          <Button
            onClick={() => handleOpenDialog(row.original)}
            variant="contained"
            sx={{
              backgroundColor: "#4CAF50",
              color: "white",
              marginRight: "5px",
            }}
          >
            Edit
          </Button>
          <Button
            onClick={() => handleDeletePdf(row.original._id)}
            variant="contained"
            sx={{ backgroundColor: "#F44336", color: "white" }}
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <h2 className={styles.heading}>Manage PDF</h2>
      <Box className={styles.container}>
        {isLoadingCourses ? (
          <Box display="flex" justifyContent="center" marginTop={2}>
            <CircularProgress />
          </Box>
        ) : (
          <Autocomplete
            options={courses}
            getOptionLabel={(option) => option.courseTitle || ""}
            value={
              courses.find((course) => course._id === selectedCourse) || null
            }
            onChange={handleCourseChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Course"
                variant="outlined"
                fullWidth
              />
            )}
            isOptionEqualToValue={(option, value) => option._id === value._id}
          />
        )}

        {isLoadingPdfs ? (
          <Box display="flex" justifyContent="center" marginTop={4}>
            <CircularProgress />
          </Box>
        ) : (
          pdfs.length > 0 && (
            <Box
              className={styles.pdfList}
              marginTop={4}
              style={{ overflowX: "auto" }}
            >
              <Typography variant="h6" gutterBottom>
                Total PDFs: {pdfs.length}
              </Typography>
              <MaterialReactTable
                columns={columns}
                data={pdfs.map((pdf, index) => ({
                  ...pdf,
                  serialNumber: index + 1,
                }))}
                enablePagination={false}
              />
            </Box>
          )
        )}

        <Dialog
          open={isDialogOpen}
          onClose={handleCloseDialog}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>Edit PDF Details</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="New Title"
              type="text"
              fullWidth
              variant="outlined"
              value={newTitle}
              onChange={(e) => setNewTitle(e.target.value)}
            />
            <TextField
              margin="dense"
              label="New ID"
              type="text"
              fullWidth
              variant="outlined"
              value={newPdfId}
              onChange={(e) => setNewPdfId(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSubmitDialog} color="primary">
              Update
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

export default ManagePDFs;
