import React, { useState, useEffect } from "react";
import "./MylearningRow2.css";
import pic from "../i/book.jpg";
import { FaStar } from "react-icons/fa";
import LessonProgress from './LessonProgress';
import { RiShareBoxFill } from 'react-icons/ri';
import { BsArrowRight } from 'react-icons/bs';
import MylearningRow3 from "./MylearningRow3";
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../Context/AuthContext";
import BuyNowExpiration from "./BuyNowExpiration";
import { PiGraduationCapDuotone } from 'react-icons/pi';
import LoadingSpinner from "../LoadingSpinner";


function MylearningRow2() {
  const [chaptersData, setChaptersData] = useState([]);
  const [row2Data, setRow2Data] = useState([]);
  const [validityData, setValidityData] = useState([]);
  const [timeLeft, setTimeLeft] = useState({});
  const [purchaseHistory, setPurchaseHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const { isLoggedIn, user, token } = useAuth();
  const navigate = useNavigate();
  const [hoveredCard, setHoveredCard] = useState(null);
  const [showExpirationModal, setShowExpirationModal] = useState(false);
  const [unavailableEbooks, setUnavailableEbooks] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isLoggedIn && user) {
          const userId = user.id;
          const useremail = user.email_id;
          setLoading(true);

          const [chaptersRes, purchaseHistoryRes, validityDataRes] = await Promise.all([
            fetch('https://auth.ssccglpinnacle.com/api/chapters-ebook', {
              headers: {
                "Authorization": `Bearer ${token}`
              }
            }),
            fetch(`https://auth.ssccglpinnacle.com/api/purchase-history-ebook/${useremail}`, {
              headers: {
                "Authorization": `Bearer ${token}`
              }
            }),
            fetch(`https://auth.ssccglpinnacle.com/api/purchase-history-ebook-data/${useremail}`, {
              headers: {
                "Authorization": `Bearer ${token}`
              }
            }),
          ]);

          if (!chaptersRes.ok || !purchaseHistoryRes.ok || !validityDataRes.ok) {
            throw new Error('Network response was not ok');
          }

          const [chaptersData, purchaseHistoryData, validityData] = await Promise.all([
            chaptersRes.json(),
            purchaseHistoryRes.json(),
            validityDataRes.json(),
          ]);

          setChaptersData(chaptersData);
          setPurchaseHistory(purchaseHistoryData.ebooksPurchased);
          setValidityData(validityData.ebook_ids);

          
          const unavailable = purchaseHistoryData.ebooksPurchased.filter(ebook => {
            return !validityData.ebook_ids.some(validity => validity.ebook_id === ebook.id);
          });

          setUnavailableEbooks(unavailable);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [isLoggedIn, user, token]);

  useEffect(() => {
    const fetchEbooks = async () => {
      try {
        const response = await fetch('https://auth.ssccglpinnacle.com/api/ebooks');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const purchasedEbookIds = purchaseHistory.map(ebook => ebook._id);
        const userPurchasedEbooks = data.filter(ebook => purchasedEbookIds.includes(ebook._id));
        setRow2Data(userPurchasedEbooks);
      } catch (error) {
        console.error('Error fetching ebooks list:', error);
      }
    };

    if (purchaseHistory.length > 0) {
      fetchEbooks();
    }
  }, [purchaseHistory]);

  useEffect(() => {
    const interval = setInterval(() => {
      const newTimeLeft = {};
      purchaseHistory.forEach(card => {
        const relatedValidityEbook = validityData.find(validity => validity.ebook_id === card.id);
        if (relatedValidityEbook) {
          newTimeLeft[card._id] = calculateTimeLeft(relatedValidityEbook.expirationDate);
        }
      });
      setTimeLeft(newTimeLeft);
    }, 1000);

    return () => clearInterval(interval);
  }, [validityData, purchaseHistory]);

  const calculateTimeLeft = (expirationDate) => {
    const difference = +new Date(expirationDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      timeLeft = { expired: true };
    }

    return timeLeft;
  };

  const countTopics = (objects, currentUser) => {
    let totalChapters = 0;
    let completedChapters = 0;
    totalChapters = objects.length;

    for (const obj of objects) {
      for (const completed of obj.completed) {
        if (currentUser._id === completed.user && completed.userCompleted === "complete") {
          completedChapters += 1;
        }
      }
    }
    return { totalChapters, completedChapters };
  };

  const navigateToOtherPage = (cardId) => {
    const relatedValidityEbook = validityData.find(validity => validity.ebook_id === cardId);
    if (relatedValidityEbook && calculateTimeLeft(relatedValidityEbook.expirationDate).expired) {
      setShowExpirationModal(true);
    } else {
      navigate(`/Progress/${cardId}`);
    }
  };

  if (loading) {
    return <><LoadingSpinner/></>;
  }

  if (!isLoggedIn || !user) {
    return <p>User not logged in</p>;
  }

  const info = () => {
    navigate('/ebooks/show-all');
  };


  const handleBuyNowEbookprocess = () => {
    navigate('/ebooks'); // Replace with the actual path to your eBooks catalog page
  };
  // console.log("mypurchase",purchaseHistory)
  return (
    <div className="my-learning-row2">
    <div className="divider">
      <svg className="svg-divider" style={{ fill: "white" }}>
        <path d="M 0 31 C 313 -17 359 19 530 29 S 905 -20 1303 21 S 1677 -28 2537 29 L 2537 0 L 0 0 L 0 31"></path>
      </svg>
    </div>
    <div className="inprogress-header">
      <h2>In progress</h2>
      <div className="show-all" onClick={info}>
        <BsArrowRight size={20} color="black" />
        <p>Show all in progress</p>
      </div>
    </div>
    <div className="my-learning-inprogress-row1">
      {purchaseHistory.length === 0 ? (
        // <div className="no-purchase-message">
        //   <h4>You have not purchased any eBooks. Please buy an eBook to see your progress.</h4>
        //   <button className="buy-now-button-ebook-procee" onClick={handleBuyNowEbookprocess}>Buy Now ebook</button>
        // </div>
        <div className="not-completed-message-ebook">
        <div className="circle-icon-scolar">
          <PiGraduationCapDuotone size={50} />
        </div>
        <h4>You didn't purchase ebook yet.</h4>
        <p>Are there ebooks you miss? Reload the page.</p>
        <button className="browse-tutorial-button" onClick={handleBuyNowEbookprocess}>Browse all digital content</button>
      </div>
      ) : (
        <>
          <div className="card-for-progress">
            {purchaseHistory.slice(0, 2).map((card) => {
              const relatedValidityEbook = validityData.find(validity => validity.ebook_id === card.id);
              const relatedChapters = chaptersData.filter(chapter => chapter.ebook?._id === card._id);
              const { totalChapters, completedChapters } = countTopics(relatedChapters, user);

              return (
                <div
                  className="custom-card digital-content-card-progress"
                  key={card._id}
                  onMouseEnter={() => setHoveredCard(card._id)}
                  onMouseLeave={() => setHoveredCard(null)}
                  onClick={() => navigateToOtherPage(card._id)}
                >
                  <div className="class-for-img-progress">
                    <div className="classes-for-pro-img-showall">
                      <img className="card-image-progress-showall" src={card.image} alt="Book Cover" />
                    </div>
                  </div>
                  <div className="card-content-progress">
                    <div className="card-point-adder">
                      <div className="card-star-and-point">
                        <div className="star-count">
                          <p className="count-star-from-progress">0</p>
                          <FaStar className="star-for-progress-report" color="black" size={18} />
                        </div>
                        <div className="point-count">
                          <p className="POINTS">1</p>
                          <p className="points-para">points</p>
                        </div>
                      </div>
                    </div>
                    <div className="title-progress-report">
                      <h6>{card.title}</h6>
                    </div>
                    <div className="lesson-completed">
                      <p><b>{completedChapters}</b> of <b>{totalChapters}</b> lessons completed</p>
                    </div>
                    <div className="progress-bar-for-learning">
                      <LessonProgress totalLessons={totalChapters} completedLessons={completedChapters} />
                    </div>
                  </div>
                  {hoveredCard === card._id && relatedValidityEbook && (
                    <div className="validity-modal">
                      <div className="validity-duration">
                        Duration: {relatedValidityEbook.duration}
                      </div>
                      <div className="validity-expire-in">
                        Expires in: {new Date(relatedValidityEbook.expirationDate).toLocaleDateString()}
                      </div>
                      {timeLeft[card._id] && !timeLeft[card._id].expired ? (
                        <div className="validity-timer">
                          Time left: {timeLeft[card._id].days}d {timeLeft[card._id].hours}h {timeLeft[card._id].minutes}m {timeLeft[card._id].seconds}s
                        </div>
                      ) : (
                        <div className="validity-timer expired">
                          This eBook has expired
                        </div>
                      )}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          <div className="my-learning-inprogress-row2">
            <div className="tip-container">
              <div className="tip">Tip</div>
              <div className="collect-stars"><h4>Collect Stars</h4></div>
              <div className="give-info">
                <ul className="my-list-give-info">
                  <li className="list-item-give-info">Read all the lessons in a tutorial, and get a star.</li>
                  <li className="list-item-give-info">Score 100% on the quiz in a tutorial, and get a star.</li>
                  <li className="list-item-give-info">Complete all the exercises in a tutorial, and get another star.</li>
                </ul>
              </div>
              <div className="view-exercise">
                <div className="view-exercise-1-row">
                  <p className="para-view">View exercises</p>
                  <RiShareBoxFill size={20} color="black" />
                </div>
                <div className="one-height"></div>
                <div className="view-exercise-2-row">
                  <p className="para-quiz">Take a quiz</p>
                  <RiShareBoxFill size={20} color="black" />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
      <MylearningRow3 />
      <div className="divider-2">
        <svg className="svg-divider-2" style={{ fill: "#e0f5e8" }}>
          <path d="M 0 31 C 313 -17 359 19 530 29 S 905 -20 1303 21 S 1677 -28 2537 29 L 2537 0 L 0 0 L 0 31"></path>
        </svg>
      </div>
      {showExpirationModal && <BuyNowExpiration onClose={() => setShowExpirationModal(false)} />}
      {unavailableEbooks.length > 0 && (
        <div className="unavailable-ebooks-message">
          <h4>The following eBooks are not available:</h4>
          <ul>
            {unavailableEbooks.map(ebook => (
              <li key={ebook.id}>{ebook.title} - You must buy this eBook</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default MylearningRow2;
