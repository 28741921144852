import React, { useState, useEffect } from 'react';
import './BuyNow.css';
import { GrClose } from 'react-icons/gr';
import pic from "../iBook/book.jpg";
import { useNavigate } from 'react-router-dom'; 

import {  useLocation } from 'react-router-dom'; 
import { useAuth } from "../Context/AuthContext";
import { useCart } from "../Context/CartContext";


function BuyNow({ onClose, onAddToCart }) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const cardId = queryParams.get("cardId");
  const { isLoggedIn, user } = useAuth();
  const [loading, setLoading] = useState(true);
  
  // ... (other state and useEffect code)



  useEffect(() => {
    console.log("Card ID:", cardId);
    // Do something with the cardId, e.g., fetch data based on the cardId
  }, [cardId]);


  const [selectedDuration, setSelectedDuration] = useState('option1');
  const [selectedProduct, setSelectedProduct] = useState('SSC CGL Tier 2 English | 40 Tests'); 

  const { addToCart } = useCart();// Default product title
  const navigate = useNavigate();
  
  
  const handleDurationChange = (event) => {
    setSelectedDuration(event.target.value);
  };

  // Example prices for demonstration purposes
  let currentOriginalPrice = 50; // Default original price
  let currentDiscountedPrice = 30; // Default discounted price

  if (selectedDuration === 'option2') {
    currentOriginalPrice = 100; // Update with appropriate price
    currentDiscountedPrice = 70; // Update with appropriate price
  } else if (selectedDuration === 'option3') {
    currentOriginalPrice = 150; // Update with appropriate price
    currentDiscountedPrice = 100; // Update with appropriate price
  }

const handleAddToCart = () => {
    // Create an object to hold product information
    const selectedProductInfo = {
      title: selectedProduct,
      originalPrice: currentOriginalPrice,
      discountedPrice: currentDiscountedPrice,
      selectedDuration: selectedDuration,
    };

    // Use addToCart with the selected product information
    addToCart(selectedProductInfo);

    navigate('/cart-book');
  };


  const handleCloseButtonClick = async () => {
    try {
      if (!user || !user._id) {
        // Handle the case where the user is not authenticated
        console.error("User not authenticated");
        return;
      }

      const response = await fetch("https://auth.ssccglpinnacle.com/api/updateTryForFree-book", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: user._id,
          cardId: cardId,
          tryForFree: true,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Handle success response if needed
      const responseData = await response.json();
      console.log(responseData);

    } catch (error) {
      // Handle error
      console.error('Error updating try-for-free status:', error);
    }

   
    // Close the modal
    onClose();
    navigate("/books");
  };

 
  
  if (!isLoggedIn || !user) {
    return <p>User not logged in</p>; // Handle the case when the user is not logged in
  }




  return (
    <div className="modal-overlay-buy">
    
      <div className="modal-buy">
      
      
        <div className="modal-header-buy">
        <div className = "free-trail-over">
        "Your free trial has ended. Purchase a subscription to continue access."
        </div>
        <button className="close-button-buy" onClick={handleCloseButtonClick}>
            <GrClose />
          </button>
        </div>
       
        <div className="modal-content-buy-container">
          <div className="buy-modal-1">
            <img className="card-title-buy" src={pic} />
          </div>
          <div className="buy-modal-2">
            <div className="product-title">
              <p>SSC CGL Tier 2 English | 40 Tests</p>
            </div>
            <div className="by-pinnacle">
              <p>by pinnacle</p>
            </div>
            <div className="for-buy-price">
              <span className="sale" data-badge-sales="">
                <span data-badge-sales-range="">Sale</span>
              </span>
              <div className="price-details">
                <p className="original-price">₹{currentOriginalPrice}</p>
                <p className="discounted-price"> ₹{currentDiscountedPrice}</p>
              </div>
            </div>
            <div className ="for-duration-buy-modal">
              <select id="selectField" value={selectedDuration} onChange={handleDurationChange}>
                <option value="option1">1 Month</option>
                <option value="option2">3 Months</option>
                <option value="option3">6 Months</option>
              </select>
              <div className ="add-to-cart-buy-modal" onClick={handleAddToCart}>
                Add to cart
              </div>
            </div>
            <div className ="buy-it-now-from-buy-model">
              <button id="buyButton">Buy It Now</button>
            </div>
            <div className ="courses-available-from-buy-model">
              <p>Course also available in: </p>
            </div>
            <div className = "course-available-from-buy-model">
              <p className ="buy-model-SSC"> CHSL </p>
              <p className ="buy-model-SSC"> CPO </p>
              <p className ="buy-model-SSC"> MTS</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BuyNow;
