import React, { useState, useEffect } from "react";
import axios from "axios";
import styles from "./KnowledgeBaseList.module.css";

const KnowledgeBaseList = () => {
  const [entries, setEntries] = useState([]);
  const [filteredEntries, setFilteredEntries] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState({ id: "", question: "", answer: "" });
  const entriesPerPage = 5; 

  useEffect(() => {
    fetchEntries();
  }, []);

  const fetchEntries = async () => {
    try {
      const response = await axios.get("https://auth.ssccglpinnacle.com/api/knowledgebase/all");
      setEntries(response.data.data);
      setFilteredEntries(response.data.data);
    } catch (error) {
      console.error("Error fetching knowledge base entries:", error.message);
    }
  };
  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);
    setFilteredEntries(
      entries.filter((entry) =>
        entry.question.toLowerCase().includes(value)
      )
    );
    setCurrentPage(1);
  };

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = filteredEntries.slice(indexOfFirstEntry, indexOfLastEntry);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleDelete = async (id) => {
    if (!window.confirm("Are you sure you want to delete this entry?")) return;

    try {
      await axios.delete(`https://auth.ssccglpinnacle.com/api/knowledgebase/${id}`);
      alert("Entry deleted successfully!");
      fetchEntries();
    } catch (error) {
      console.error("Error deleting entry:", error.message);
      alert("Failed to delete entry. Check console for details.");
    }
  };
  const handleEdit = (entry) => {
    setFormData({ id: entry._id, question: entry.question, answer: entry.answer });
    setEditMode(true);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`https://auth.ssccglpinnacle.com/api/knowledgebase/${formData.id}`, {
        question: formData.question,
        answer: formData.answer,
      });
      alert("Entry updated successfully!");
      fetchEntries(); 
      setEditMode(false);
      setFormData({ id: "", question: "", answer: "" });
    } catch (error) {
      console.error("Error updating entry:", error.message);
      alert("Failed to update entry. Check console for details.");
    }
  };


  const handleCancelEdit = () => {
    setFormData({ id: "", question: "", answer: "" });
    setEditMode(false);
  };

  return (
    <div className={styles.container}>
      <h2>Knowledge Base List</h2>
      <input
        type="text"
        value={searchTerm}
        onChange={handleSearch}
        placeholder="Search by question..."
        className={styles.searchInput}
      />
      {editMode && (
        <form onSubmit={handleSubmit} className={styles.editForm}>
          <h3>Edit Entry</h3>
          <input
            type="text"
            name="question"
            value={formData.question}
            onChange={handleChange}
            placeholder="Edit question"
            required
            className={styles.input}
          />
          <textarea
            name="answer"
            value={formData.answer}
            onChange={handleChange}
            placeholder="Edit answer"
            required
            className={styles.textarea}
          />
          <button type="submit" className={styles.button}>
            Update Entry
          </button>
          <button
            type="button"
            onClick={handleCancelEdit}
            className={`${styles.button} ${styles.cancelButton}`}
          >
            Cancel
          </button>
        </form>
      )}
      <div className={styles.entries}>
        {currentEntries.map((entry) => (
          <div key={entry._id} className={styles.entry}>
            <div className={styles.entryContent}>
              <strong>Question:</strong> {entry.question}
              <br />
              <strong>Answer:</strong> {entry.answer}
            </div>
            <div className={styles.actions}>
              <button
                onClick={() => handleEdit(entry)}
                className={styles.editButton}
              >
                Edit
              </button>
              <button
                onClick={() => handleDelete(entry._id)}
                className={styles.deleteButton}
              >
                Delete
              </button>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.pagination}>
        {Array.from(
          { length: Math.ceil(filteredEntries.length / entriesPerPage) },
          (_, index) => (
            <button
              key={index + 1}
              onClick={() => handlePageChange(index + 1)}
              className={`${styles.pageButton} ${
                currentPage === index + 1 ? styles.active : ""
              }`}
            >
              {index + 1}
            </button>
          )
        )}
      </div>
    </div>
  );
};

export default KnowledgeBaseList;
