import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import styles from "./ManageOgData.module.css";

// const baseURL = "http://localhost:5000/api";
const baseURL = "https://auth.ssccglpinnacle.com/api";

const ManageOgDataForm = () => {
  const [ogTags, setOgTags] = useState([]);
  const [editFormData, setEditFormData] = useState({
    type: "",
    ogTitle: "",
    ogDescription: "",
    ogImage: "",
    ogType: "",
  });
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    fetchOgTags();
  }, []);

  const fetchOgTags = async () => {
    try {
      const response = await fetch(`${baseURL}/og`);
      const data = await response.json();
      setOgTags(data);
    } catch (error) {
      Swal.fire("Error", "Failed to fetch OG tags.", "error");
    }
  };

  const handleDelete = async (type) => {
    try {
      const response = await fetch(`${baseURL}/og/${type}`, {
        method: "DELETE",
      });

      if (response.ok) {
        Swal.fire("Success", "OG tag deleted successfully!", "success");
        fetchOgTags(); // Refresh list
      } else {
        Swal.fire("Error", "Failed to delete OG tag.", "error");
      }
    } catch (error) {
      Swal.fire("Error", "Something went wrong!", "error");
    }
  };

  const handleEdit = (tag) => {
    setEditFormData(tag);
    setIsEditing(true);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${baseURL}/og/${editFormData.type}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(editFormData),
      });

      if (response.ok) {
        Swal.fire("Success", "OG tag updated successfully!", "success");
        setIsEditing(false);
        fetchOgTags(); // Refresh list
      } else {
        Swal.fire("Error", "Failed to update OG tag.", "error");
      }
    } catch (error) {
      Swal.fire("Error", "Something went wrong!", "error");
    }
  };

  return (
    <div className={styles.ogManageContainer}>
      <h2 className={styles.ogManageTitle}>Manage OG Tags</h2>
      {isEditing ? (
        <form onSubmit={handleUpdate} className={styles.editForm}>
          <h3 className={styles.editTitle}>Edit OG Tag</h3>
          <input
            type="text"
            name="ogTitle"
            value={editFormData.ogTitle}
            onChange={handleEditChange}
            placeholder="Enter OG Title"
            className={styles.editInput}
            required
          />
          <textarea
            name="ogDescription"
            value={editFormData.ogDescription}
            onChange={handleEditChange}
            placeholder="Enter OG Description"
            className={styles.editTextarea}
            required
          />
          <input
            type="text"
            name="ogImage"
            value={editFormData.ogImage}
            onChange={handleEditChange}
            placeholder="Enter OG Image URL"
            className={styles.editInput}
          />
          <input
            type="text"
            name="ogType"
            value={editFormData.ogType}
            onChange={handleEditChange}
            placeholder="Enter OG Type"
            className={styles.editInput}
            required
          />
          <button type="submit" className={styles.saveButton}>
            Save
          </button>
          <button
            type="button"
            onClick={() => setIsEditing(false)}
            className={styles.cancelButton}
          >
            Cancel
          </button>
        </form>
      ) : (
        <table className={styles.ogManageTable}>
          <thead>
            <tr>
              <th>Type</th>
              <th>OG Title</th>
              <th>OG Description</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {ogTags.map((tag, index) => (
              <tr key={`${tag.type}-${index}`}>
                <td>{tag.type}</td>
                <td>{tag.ogTitle}</td>
                <td>{tag.ogDescription}</td>
                <td>
                  <button
                    className={styles.editButton}
                    onClick={() => handleEdit(tag)}
                  >
                    Edit
                  </button>
                  <button
                    className={styles.deleteButton}
                    onClick={() => handleDelete(tag.type)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default ManageOgDataForm;
