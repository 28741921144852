import Row1Digital from "./Row1Digital";

import Row2Digital from "./Row2Digital";
import Row4Digital from "./Row4Digital";
import Row5Digital from "./Row5Digital";

import Footer from "../../../FooterMain/Footer";
import "./Body.css";

import React, { useContext, useEffect, useState } from "react";
import BuyNow from "../../BuyNow/BuyNow";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Header from "../../../component/HeaderFilesEbook/Header";
import MetaTags from "../../../DynamicMetaData/DynamicMetadata";

function DigitalBody() {
  const [filteredEbookData, setFilteredEbookData] = useState([]);
  const [showBuyNowModal, setShowBuyNowModal] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  // Define a function to receive filtered ebook data from Row1Digital
  const receiveFilteredEbookData = (data) => {
    setFilteredEbookData(data);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // Check if the page has been reloaded before
    const hasReloaded = localStorage.getItem("hasReloaded");
    if (!hasReloaded) {
      // Perform actions that should only happen once
      // For example, you can trigger a reload
      localStorage.setItem("hasReloaded", "true");
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    // Check if cardId is present in the query parameter
    const queryParams = new URLSearchParams(location.search);
    const queryCardId = queryParams.get("cardId");

    if (queryCardId) {
      setShowBuyNowModal(true);
      document.body.style.overflow = "hidden";
    }
  }, [location]);

  const closeModal = () => {
    setShowBuyNowModal(false);
    document.body.style.overflow = "";
    navigate("/ebooks");
  };
  return (
    <>
      <MetaTags type="ebooks" />
      <Header />
      <div className="digital-products-page">
        {/* First Row: Filters and Product Grid */}
        <div className="top-row">
          <div className="filter-section-book">
            <Row1Digital onDataFiltered={receiveFilteredEbookData} />
          </div>
          <div className="product-section">
            <Row2Digital filteredEbookData={filteredEbookData} />
          </div>
        </div>

        {/* Second Row: Horizontal Scroller */}
        <div className="horizontal-row">
          <Row4Digital filteredEbookData={filteredEbookData} />
        </div>

        {/* Third Row: Bottom Product Grid */}
        <div className="bottom-row">
          <Row5Digital filteredEbookData={filteredEbookData} />
        </div>
      </div>
      <Footer />
      {showBuyNowModal && <BuyNow onClose={closeModal} />}
    </>
  );
}

export default DigitalBody;
