import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { MaterialReactTable } from "material-react-table";
import {
  Button,
  Modal,
  Box,
  Typography,
  IconButton,
  Grid,
  TextField,
  MenuItem,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./ManageCourseList.module.css";

const ManageCourseList = () => {
  const [courses, setCourses] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const [totalCourses, setTotalCourses] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [hindiCoverPreview, setHindiCoverPreview] = useState(null);
  const [englishCoverPreview, setEnglishCoverPreview] = useState(null);
  const [hindiCoverFile, setHindiCoverFile] = useState(null);
  const [englishCoverFile, setEnglishCoverFile] = useState(null);

  useEffect(() => {
    fetchCourses();
    fetchSubjects();
  }, [currentPage]);

  const fetchCourses = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://auth.ssccglpinnacle.com/api/courses"
      );
      if (Array.isArray(response.data)) {
        setCourses(response.data);
        setTotalCourses(response.data.length);
      } else {
        throw new Error("Unexpected response format");
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching courses:", error.message || error);
      setError("Could not fetch courses. Please try again later.");
      setLoading(false);
    }
  };

  const fetchSubjects = async () => {
    try {
      const response = await axios.get(
        "https://auth.ssccglpinnacle.com/api/subjects"
      );
      setSubjects(response.data.subjects || []);
    } catch (error) {
      console.error("Error fetching subjects:", error);
    }
  };

  const handleEditClick = (course) => {
    setSelectedCourse(course);
    setHindiCoverPreview(course.hindiCoverImage || null);
    setEnglishCoverPreview(course.englishCoverImage || null);
    setShowModal(true);
  };

  const handleFileChange = (e, type) => {
    const file = e.target.files[0];
    if (type === "hindi") {
      setHindiCoverFile(file);
      setHindiCoverPreview(URL.createObjectURL(file));
    } else {
      setEnglishCoverFile(file);
      setEnglishCoverPreview(URL.createObjectURL(file));
    }
  };

  const handleSave = async () => {
    try {
      const formData = new FormData();
      formData.append("courseTitle", selectedCourse.courseTitle);
      formData.append("shortDescription", selectedCourse.shortDescription);
      formData.append("longDescription", selectedCourse.longDescription);
      formData.append("category", selectedCourse.category);
      formData.append("subject", selectedCourse.subject);
      formData.append("price", selectedCourse.price);
      formData.append("mrp", selectedCourse.mrp);
      formData.append("instructorName", selectedCourse.instructorName);
      formData.append("SEOTitle", selectedCourse.SEOTitle);
      formData.append("SEOKeyword", selectedCourse.SEOKeyword);
      formData.append("SEODescription", selectedCourse.SEODescription);

      if (hindiCoverFile) formData.append("hindiCoverImage", hindiCoverFile);
      if (englishCoverFile)
        formData.append("englishCoverImage", englishCoverFile);

      // Debugging: Log form data
      console.log("Form Data:", Array.from(formData.entries()));

      // Make API request
      const response = await axios.put(
        `https://auth.ssccglpinnacle.com/api/edit-courses/${selectedCourse._id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Debugging: Log response
      console.log("API Response:", response.data);

      if (response.data.success) {
        // Update local state
        setCourses((prevCourses) =>
          prevCourses.map((course) =>
            course._id === selectedCourse._id
              ? { ...response.data.course }
              : course
          )
        );

        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Course updated successfully!",
        });

        // Close modal
        setShowModal(false);
        setSelectedCourse(null);
        setHindiCoverPreview(null);
        setEnglishCoverPreview(null);
      } else {
        throw new Error("Failed to update course");
      }
    } catch (error) {
      console.error("Error saving course:", error.response || error.message);
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "There was an error saving the course. Please try again.",
      });
    }
  };

  return (
    <>
      <h2 className={styles.heading}>Manage Video Course</h2>
      <div className={styles.courseListContainer}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>{/* Add any filters or additional controls here */}</Grid>
        </Grid>

        {loading && <Typography>Loading...</Typography>}
        {error && <Typography color="error">{error}</Typography>}

        <MaterialReactTable
          columns={[
            { accessorKey: "serialNumber", header: "S.No.", size: 20 },
            { accessorKey: "courseTitle", header: "Title", size: 300 },
            { accessorKey: "category", header: "Category", size: 100 },
            { accessorKey: "subject", header: "Subject", size: 100 },
            { accessorKey: "price", header: "Price", size: 50 },
            { accessorKey: "mrp", header: "MRP", size: 50 },
            {
              accessorKey: "hindiCoverImage",
              header: "Hindi Cover",
              Cell: ({ cell }) => (
                <img
                  src={cell.getValue()}
                  alt="Hindi Cover"
                  style={{ width: 50, height: 50, objectFit: "cover" }}
                />
              ),
            },
            {
              accessorKey: "englishCoverImage",
              header: "English Cover",
              Cell: ({ cell }) => (
                <img
                  src={cell.getValue()}
                  alt="English Cover"
                  style={{ width: 50, height: 50, objectFit: "cover" }}
                />
              ),
            },
            {
              header: "Actions",
              Cell: ({ row }) => (
                <Button
                  variant="contained"
                  onClick={() => handleEditClick(row.original)}
                >
                  Edit
                </Button>
              ),
            },
          ]}
          data={courses.map((course, index) => ({
            ...course,
            serialNumber: index + 1 + (currentPage - 1) * pageSize,
          }))}
          pagination={{
            pageCount: Math.ceil(totalCourses / pageSize),
            currentPage,
            onPageChange: setCurrentPage,
          }}
        />
        <Modal open={showModal} onClose={() => setShowModal(false)}>
          <Box className={styles.modalContent}>
            <IconButton
              onClick={() => setShowModal(false)}
              style={{ position: "absolute", top: 10, right: 10 }}
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" gutterBottom>
              Edit Course
            </Typography>

            {/* Form Fields */}
            <Box sx={{ marginBottom: "16px" }}>
              <TextField
                label="Course Title"
                value={selectedCourse?.courseTitle || ""}
                onChange={(e) =>
                  setSelectedCourse((prev) => ({
                    ...prev,
                    courseTitle: e.target.value,
                  }))
                }
                fullWidth
                margin="normal"
              />

              <TextField
                label="Short Description"
                value={selectedCourse?.shortDescription || ""}
                onChange={(e) =>
                  setSelectedCourse((prev) => ({
                    ...prev,
                    shortDescription: e.target.value,
                  }))
                }
                fullWidth
                margin="normal"
                multiline
                rows={3}
              />

              <TextField
                label="Long Description"
                value={selectedCourse?.longDescription || ""}
                onChange={(e) =>
                  setSelectedCourse((prev) => ({
                    ...prev,
                    longDescription: e.target.value,
                  }))
                }
                fullWidth
                margin="normal"
                multiline
                rows={5}
              />

              <TextField
                label="Price"
                type="number"
                value={selectedCourse?.price || ""}
                onChange={(e) =>
                  setSelectedCourse((prev) => ({
                    ...prev,
                    price: e.target.value,
                  }))
                }
                fullWidth
                margin="normal"
              />

              <TextField
                label="MRP"
                type="number"
                value={selectedCourse?.mrp || ""}
                onChange={(e) =>
                  setSelectedCourse((prev) => ({
                    ...prev,
                    mrp: e.target.value,
                  }))
                }
                fullWidth
                margin="normal"
              />

              <TextField
                label="Subject"
                value={selectedCourse?.subject || ""}
                onChange={(e) =>
                  setSelectedCourse((prev) => ({
                    ...prev,
                    subject: e.target.value,
                  }))
                }
                select
                fullWidth
                margin="normal"
              >
                {subjects.map((subject) => (
                  <MenuItem key={subject._id} value={subject.title}>
                    {subject.title}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                label="SEO Title"
                value={selectedCourse?.SEOTitle || ""}
                onChange={(e) =>
                  setSelectedCourse((prev) => ({
                    ...prev,
                    SEOTitle: e.target.value,
                  }))
                }
                fullWidth
                margin="normal"
              />

              <TextField
                label="SEO Keywords"
                value={selectedCourse?.SEOKeyword || ""}
                onChange={(e) =>
                  setSelectedCourse((prev) => ({
                    ...prev,
                    SEOKeyword: e.target.value,
                  }))
                }
                fullWidth
                margin="normal"
              />

              <TextField
                label="SEO Description"
                value={selectedCourse?.SEODescription || ""}
                onChange={(e) =>
                  setSelectedCourse((prev) => ({
                    ...prev,
                    SEODescription: e.target.value,
                  }))
                }
                fullWidth
                margin="normal"
                multiline
                rows={3}
              />

              <Typography>Hindi Cover Image:</Typography>
              <input
                type="file"
                onChange={(e) => handleFileChange(e, "hindi")}
              />
              {hindiCoverPreview && (
                <img
                  src={hindiCoverPreview}
                  alt="Hindi Preview"
                  style={{ width: 100, height: 100, marginTop: 10 }}
                />
              )}

              <Typography>English Cover Image:</Typography>
              <input
                type="file"
                onChange={(e) => handleFileChange(e, "english")}
              />
              {englishCoverPreview && (
                <img
                  src={englishCoverPreview}
                  alt="English Preview"
                  style={{ width: 100, height: 100, marginTop: 10 }}
                />
              )}
            </Box>

            {/* Save Changes Button */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <Button
                onClick={handleSave}
                variant="contained"
                color="primary"
                fullWidth
                sx={{
                  padding: "10px",
                  fontSize: "16px",
                  textTransform: "uppercase",
                }}
              >
                Save Changes
              </Button>
            </Box>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default ManageCourseList;
