// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.for-intro-row7-image-introproduct{
    /*border: solid 1px;*/
    margin: 0 auto;

    

    margin-bottom: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/Ebook/Info/Row7intro.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,cAAc;;;;IAId,mBAAmB;AACvB","sourcesContent":[".for-intro-row7-image-introproduct{\n    /*border: solid 1px;*/\n    margin: 0 auto;\n\n    \n\n    margin-bottom: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
