// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BookMyOrder_book_purchased_main_div__hhjtA{
    display: flex;
    margin: 9px;
}
.BookMyOrder_heading_div__qKGyz{
    margin-top: 8px;
}
.BookMyOrder_book_purchased_main_div__hhjtA h2{
    font-size: 24px;
    color: forestgreen;
    font-weight: 600;
}

.BookMyOrder_order_btn__8LjnC{
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 10px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    display: inline-block;
    margin: 5px 5px;
    text-align: center;
    text-decoration: none;
}

.BookMyOrder_order_btn__8LjnC a{
    color: #fff;
    text-decoration: none;
}

.BookMyOrder_purchase_table__0rCwM{
    margin: 8px;
}

.BookMyOrder_myorder_footer__n9hg0{
    position: relative;
    top: 18rem;
}`, "",{"version":3,"sources":["webpack://./src/PagesBook/DigitalContentBody/BookMyOrder.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;AACf;AACA;IACI,eAAe;AACnB;AACA;IACI,eAAe;IACf,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,iBAAiB;IACjB,eAAe;IACf,kBAAkB;IAClB,eAAe;IACf,qBAAqB;IACrB,eAAe;IACf,kBAAkB;IAClB,qBAAqB;AACzB;;AAEA;IACI,WAAW;IACX,qBAAqB;AACzB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,UAAU;AACd","sourcesContent":[".book_purchased_main_div{\n    display: flex;\n    margin: 9px;\n}\n.heading_div{\n    margin-top: 8px;\n}\n.book_purchased_main_div h2{\n    font-size: 24px;\n    color: forestgreen;\n    font-weight: 600;\n}\n\n.order_btn{\n    background-color: #007bff;\n    color: white;\n    border: none;\n    padding: 5px 10px;\n    font-size: 16px;\n    border-radius: 5px;\n    cursor: pointer;\n    display: inline-block;\n    margin: 5px 5px;\n    text-align: center;\n    text-decoration: none;\n}\n\n.order_btn a{\n    color: #fff;\n    text-decoration: none;\n}\n\n.purchase_table{\n    margin: 8px;\n}\n\n.myorder_footer{\n    position: relative;\n    top: 18rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"book_purchased_main_div": `BookMyOrder_book_purchased_main_div__hhjtA`,
	"heading_div": `BookMyOrder_heading_div__qKGyz`,
	"order_btn": `BookMyOrder_order_btn__8LjnC`,
	"purchase_table": `BookMyOrder_purchase_table__0rCwM`,
	"myorder_footer": `BookMyOrder_myorder_footer__n9hg0`
};
export default ___CSS_LOADER_EXPORT___;
