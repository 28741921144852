// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MyOrders_container__v2Lwl {
    padding: 1.5rem;
  }
  
  .MyOrders_header__XvvG\\+ {
    display: flex;
    align-items: center;
    gap: 14px;
  }

  .MyOrders_title__\\+IurX{
    font-size: 24px;
    font-weight: 600;
    color: forestgreen;
  }
  
  .MyOrders_button_link__8LWbI, .MyOrders_button_books__sYaTV {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 10px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    display: inline-block;
    margin: 5px 5px;
    text-align: center;
  }
  
  .MyOrders_button_link__8LWbI a, .MyOrders_button_books__sYaTV a {
    color: white;
    text-decoration: none;
    display: block;
    width: 100%;
    height: 100%;
  }
  
  .MyOrders_message__ltj50 {
    font-size: 16px;
    color: gray;
  }
  
  .MyOrders_table__AG0MT {
    width: 100%;
    margin-top: 5px;
  }
  
  .MyOrders_productTitle__Cjbe8 {
    cursor: pointer;
    color: blue;
  }
  .MyOrders_main_content__47P-Y{
    margin-bottom: 10rem;
  }

  /* Responsive adjustments for small screens */
@media (max-width: 768px) {
  .MyOrders_button_link__8LWbI, .MyOrders_button_books__sYaTV {
    padding: 4px 8px;
    font-size: 14px;
    width: 100%; /* Full width buttons for smaller screens */
  }
}

@media (max-width: 480px) {
  .MyOrders_button_link__8LWbI, .MyOrders_button_books__sYaTV {
    padding: 3px 6px;
    font-size: 12px;
  }
}`, "",{"version":3,"sources":["webpack://./src/VIDEOPLAYER COMPONENTS/MyOrders.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;EACjB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,SAAS;EACX;;EAEA;IACE,eAAe;IACf,gBAAgB;IAChB,kBAAkB;EACpB;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,iBAAiB;IACjB,eAAe;IACf,kBAAkB;IAClB,eAAe;IACf,qBAAqB;IACrB,eAAe;IACf,kBAAkB;EACpB;;EAEA;IACE,YAAY;IACZ,qBAAqB;IACrB,cAAc;IACd,WAAW;IACX,YAAY;EACd;;EAEA;IACE,eAAe;IACf,WAAW;EACb;;EAEA;IACE,WAAW;IACX,eAAe;EACjB;;EAEA;IACE,eAAe;IACf,WAAW;EACb;EACA;IACE,oBAAoB;EACtB;;EAEA,6CAA6C;AAC/C;EACE;IACE,gBAAgB;IAChB,eAAe;IACf,WAAW,EAAE,2CAA2C;EAC1D;AACF;;AAEA;EACE;IACE,gBAAgB;IAChB,eAAe;EACjB;AACF","sourcesContent":[".container {\n    padding: 1.5rem;\n  }\n  \n  .header {\n    display: flex;\n    align-items: center;\n    gap: 14px;\n  }\n\n  .title{\n    font-size: 24px;\n    font-weight: 600;\n    color: forestgreen;\n  }\n  \n  .button_link, .button_books {\n    background-color: #007bff;\n    color: white;\n    border: none;\n    padding: 5px 10px;\n    font-size: 16px;\n    border-radius: 5px;\n    cursor: pointer;\n    display: inline-block;\n    margin: 5px 5px;\n    text-align: center;\n  }\n  \n  .button_link a, .button_books a {\n    color: white;\n    text-decoration: none;\n    display: block;\n    width: 100%;\n    height: 100%;\n  }\n  \n  .message {\n    font-size: 16px;\n    color: gray;\n  }\n  \n  .table {\n    width: 100%;\n    margin-top: 5px;\n  }\n  \n  .productTitle {\n    cursor: pointer;\n    color: blue;\n  }\n  .main_content{\n    margin-bottom: 10rem;\n  }\n\n  /* Responsive adjustments for small screens */\n@media (max-width: 768px) {\n  .button_link, .button_books {\n    padding: 4px 8px;\n    font-size: 14px;\n    width: 100%; /* Full width buttons for smaller screens */\n  }\n}\n\n@media (max-width: 480px) {\n  .button_link, .button_books {\n    padding: 3px 6px;\n    font-size: 12px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `MyOrders_container__v2Lwl`,
	"header": `MyOrders_header__XvvG+`,
	"title": `MyOrders_title__+IurX`,
	"button_link": `MyOrders_button_link__8LWbI`,
	"button_books": `MyOrders_button_books__sYaTV`,
	"message": `MyOrders_message__ltj50`,
	"table": `MyOrders_table__AG0MT`,
	"productTitle": `MyOrders_productTitle__Cjbe8`,
	"main_content": `MyOrders_main_content__47P-Y`
};
export default ___CSS_LOADER_EXPORT___;
