// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
@media (max-width: 546px) {
  .main-body-container {
    width: 100%;
    overflow: hidden;
  }
}
`, "",{"version":3,"sources":["webpack://./src/component/HomePage01/Home.css"],"names":[],"mappings":";AACA;EACE;IACE,WAAW;IACX,gBAAgB;EAClB;AACF","sourcesContent":["\n@media (max-width: 546px) {\n  .main-body-container {\n    width: 100%;\n    overflow: hidden;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
