import React, { useState, useEffect } from 'react';
import "./AddToCart.css";
import { BsCart2 } from "react-icons/bs";
import { RxCross2 } from "react-icons/rx";
import pic from "../iBook/book.jpg";
import { FiArrowRight } from "react-icons/fi";
import { AiOutlineRight } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import Offer from "./OfferBook";
import { useAuth } from "../Context/AuthContext";
import { useCart } from "../Context/CartContext";
import Cartheader from "../component/CartHeader/Cartheader";


function CartPageBook() {
  const [isHovered, setIsHovered] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [subtotal, setSubtotal] = useState(0);
  const { isLoggedIn, user } = useAuth();
  const navigate = useNavigate();
  const { updateCartCount, cartCount } = useCart();

  useEffect(() => {
    const userId = user?._id; // Replace with the actual user ID
    if (userId) {
      // Fetch cart data from the API
      fetch(`https://auth.ssccglpinnacle.com/api/cart-book/${userId}`)
        .then((response) => response.json())
        .then((data) => {
          setCartItems(data); // Update cartItems in the state
          // Calculate subtotal based on the updated cartItems
          const newSubtotal = data.reduce((total, item) => total + item.product.ebookSellingPrice, 0);
          setSubtotal(newSubtotal);
         
        })
        .catch((error) => console.error("Error fetching cart data:", error));
    }
  }, [user]);

// ...

const handleRemoveItem = async (itemIndex) => {
  const userId = user?._id;
  try {
    // Assuming you have the product ID in the cart item data
    const productId = itemIndex;

    // Make a DELETE request to the backend API to remove the item
    const response = await fetch(`https://auth.ssccglpinnacle.com/api/cart-book/${userId}/remove/${productId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        // Include any other headers or authentication tokens if needed
      },
    });

    if (response.ok) {
      // If the backend operation is successful, update the cartItems in the state
      const updatedCartItems = [...cartItems];
      updatedCartItems.splice(itemIndex, 1);
      setCartItems(updatedCartItems);

      // You can also update any other state or perform additional logic if needed
    } else {
      // Handle errors or show a notification to the user
      const errorData = await response.json();
      console.error('Error removing item from cart:', errorData.error);
    }
  } catch (error) {
    console.error('Error removing item from cart:', error.message);
  }
  window.location.reload();
};

  return (
    <>
     <div className="main-body-container">
        <Offer />
       <Cartheader/>
      <div className="container-for-cart">
  
        <div className="margin-for-cart"> </div>

        {cartItems.length === 0 ? (
          <>
            <div className="body-for-cart">
              <div className="body-row1-for-cart">
                <h1>Your cart</h1>

                <div className="checkout-cart-empty">
                  <div
                    className="checkout-cart-button-empty"
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    onClick={() => navigate("/books")}
                  >
                    Continue Shopping
                    <span className="icon">
                      {isHovered ? (
                        <FiArrowRight size={20} />
                      ) : (
                        <AiOutlineRight className="after-hover-arrow" />
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className="cartitems-empty">
                <div className="empty-cart-message">Your cart is empty.</div>

                <div className="checkout-cart-empty-button">
                  <button className="checkout-cart-button-empty-button" onClick={() => navigate("/books")}>
                    <BsCart2 className="cart-svg" />
                    Continue Shopping
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="body-for-cart">
            <div className="body-row1-for-cart">
              <h1>Your cart</h1>

              <div className="subtotal-checkout-cart">
                <div className="subtotal-cart">
                  <div className="subtotal-cart-1">Subtotal</div>
                  <div className="subtotal-cart-2">
                    <span className="money">₹ {subtotal.toFixed(2)} INR</span>
                  </div>
                </div>
                <div className="checkout-cart">
                  <button className="checkout-cart-button">
                    <BsCart2 className="cart-svg" />
                    Check out
                  </button>
                </div>
              </div>
            </div>
            <ul className="cart-u-list">
              {cartItems.map((item, index) => (
                <li className="list-of-the-cart" key={index}>
                  <div className="img-of-current-product">
            
        <img className="card-title-buy-img"  src={`http://13.200.156.92:5000/${item.product.image}`} alt={item.product.title} />
 
                  </div>

                  <div className="product-desc-cart">
                    <div className="title-current-price">
                      <div className="title-of-the-cart-product">
                    
                        <p className="p-for-title-cart">{item.product.title}</p>  
                      </div>

                      <div className="price-of-the-cart-product">
                        <p className="price-for-title-cart">Price</p>
                       
                        <p className="price2-for-title-cart">₹{item.product.ebookPrintingPrice}</p> 
                      </div>
                    </div>

                    <div className="duration-total-price-close">
                      <div className="duration-of-the-product">
                        <select
                          id="selectField-cart"
                          value={item.selectedDuration} // Set selected value based on cart item's selectedDuration
                        >
                          <option value="option1">1 Month</option>
                          <option value="option2">3 Months</option>
                          <option value="option3">6 Months</option>
                        </select>
                      </div>

                      <div className="total-price-of-the-product">
                        <p className="total-price-of-the-product1">Price</p>
                        
                        <p className="total-price-of-the-product2">₹ {item.product.ebookSellingPrice}</p>
                      </div>
                      <div className="for-delete-cart">
                        <div className="circle-for-close" onClick={() => handleRemoveItem(item.product._id)}>
                          <RxCross2 className="icon-cross-cart" />
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>

            <div className="for-line"></div>

            <div className="sub-total-container">
              <div className="for-subtotal">
                <div className="sub-total-container-totalpara">Subtotal</div>

                <div className="sub-total-container-total">₹ {subtotal.toFixed(2)} INR</div>
              </div>
              <div className="sub-total-container-tax">
                <p>Tax included and shipping calculated at checkout</p>
              </div>

              <div className="sub-total-container-message">
                <p>Your cart does not require shipping.</p>
              </div>
              <div className="checkout-cart-bottom">
                <button className="checkout-cart-button-bottom">
                  <BsCart2 size={30} className="cart-svg-bottom" />
                  Check out
                </button>
              </div>
            </div>
            <div className="checkout-cart-empty-on-bottom">
              <div
                className="checkout-cart-button-empty"
                onClick={() => {
                  navigate("/books");
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                Continue Shopping
                <span className="icon">
                  {isHovered ? (
                    <FiArrowRight size={20} />
                  ) : (
                    <AiOutlineRight className="after-hover-arrow" />
                  )}
                </span>
              </div>
            </div>
          </div>
        )}

       
      </div>
      <div className="footer-for-cart"></div>
      </div>
    </>
  );
}

export default CartPageBook;
