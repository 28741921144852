import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import { Editor, EditorState, RichUtils } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import "draft-js/dist/Draft.css";
import Swal from "sweetalert2";
import styles from "./AddCourseForm.module.css";

const AddCourseForm = () => {
  const initialCourseData = {
    courseTitle: "",
    shortDescription: "",
    longDescription: "",
    category: "",
    instructorName: "",
    price: "",
    mrp: "",
    SEOTitle: "",
    SEOKeyword: "",
    SEOPermalink: "",
    SEODescription: "",
    subject: "",
    edition: "",
    hindiCoverImage: null,
    englishCoverImage: null,
  };

  const [categories, setCategories] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [instructors, setInstructors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [courseData, setCourseData] = useState(initialCourseData);

  useEffect(() => {
    const fetchCategoriesInstructorsAndSubjects = async () => {
      try {
        const [categoryRes, instructorRes, subjectRes] = await Promise.all([
          axios.get("https://auth.ssccglpinnacle.com/categories"),
          axios.get("https://auth.ssccglpinnacle.com/api/instructors"),
          axios.get("https://auth.ssccglpinnacle.com/api/subjects"),
        ]);

        setCategories(categoryRes.data);
        setInstructors(instructorRes.data.instructors || []);
        setSubjects(subjectRes.data.subjects || []);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchCategoriesInstructorsAndSubjects();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCourseData({ ...courseData, [name]: value });
  };

  const handleEditorChange = (state) => {
    setEditorState(state);
    setCourseData({
      ...courseData,
      longDescription: stateToHTML(state.getCurrentContent()),
    });
  };

  const { getRootProps: getRootPropsHindi, getInputProps: getInputPropsHindi } =
    useDropzone({
      accept: "image/*",
      onDrop: (acceptedFiles) => onDrop(acceptedFiles, "hindiCoverImage"),
    });

  const {
    getRootProps: getRootPropsEnglish,
    getInputProps: getInputPropsEnglish,
  } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => onDrop(acceptedFiles, "englishCoverImage"),
  });

  const onDrop = (acceptedFiles, name) => {
    setCourseData({ ...courseData, [name]: acceptedFiles[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    Object.keys(courseData).forEach((key) => {
      if (key === "hindiCoverImage" || key === "englishCoverImage") {
        if (courseData[key])
          formData.append(key, courseData[key], courseData[key].name);
      } else {
        formData.append(key, courseData[key]);
      }
    });

    try {
      await axios.post(
        "https://auth.ssccglpinnacle.com/create-course",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      Swal.fire("Success", "Course created successfully", "success");
      setCourseData(initialCourseData);
      setEditorState(EditorState.createEmpty());
    } catch (error) {
      console.error("Error creating course:", error);
      Swal.fire("Error", "Failed to create course", "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className={styles.container}>
      <h2>Add Course</h2>

      <label>Course Title:</label>
      <input
        type="text"
        name="courseTitle"
        value={courseData.courseTitle}
        onChange={handleChange}
        required
      />

      <label>Short Description:</label>
      <textarea
        name="shortDescription"
        value={courseData.shortDescription}
        onChange={handleChange}
        required
      />

      <label>Long Description:</label>
      <div className={styles.editorContainer}>
        <Editor
          editorState={editorState}
          onChange={handleEditorChange}
          placeholder="Write the long description..."
        />
      </div>

      <label>Category:</label>
      <select
        name="category"
        value={courseData.category}
        onChange={handleChange}
        required
      >
        <option value="">Select a category</option>
        {categories.map((cat) => (
          <option key={cat._id} value={cat.categoryTitle}>
            {cat.categoryTitle}
          </option>
        ))}
      </select>

      <label>Subject:</label>
      <select
        name="subject"
        value={courseData.subject}
        onChange={handleChange}
        required
      >
        <option value="">Select a subject</option>
        {subjects.map((sub) => (
          <option key={sub._id} value={sub.title}>
            {sub.title}
          </option>
        ))}
      </select>

      <label>Instructor Name:</label>
      <select
        name="instructorName"
        value={courseData.instructorName}
        onChange={handleChange}
        required
      >
        <option value="">Select an instructor</option>
        {instructors.map((ins) => (
          <option key={ins._id} value={ins.instructorName}>
            {ins.instructorName}
          </option>
        ))}
      </select>

      <label>Hindi Cover Image:</label>
      <div {...getRootPropsHindi()} className={styles.dropzone}>
        <input {...getInputPropsHindi()} />
        <p>Drag 'n drop an image here, or click to select one</p>
      </div>
      {courseData.hindiCoverImage && (
        <img
          src={URL.createObjectURL(courseData.hindiCoverImage)}
          alt="Hindi Cover"
          className={styles.previewImage}
        />
      )}

      <label>English Cover Image:</label>
      <div {...getRootPropsEnglish()} className={styles.dropzone}>
        <input {...getInputPropsEnglish()} />
        <p>Drag 'n drop an image here, or click to select one</p>
      </div>
      {courseData.englishCoverImage && (
        <img
          src={URL.createObjectURL(courseData.englishCoverImage)}
          alt="English Cover"
          className={styles.previewImage}
        />
      )}

      <label>Price:</label>
      <input
        type="number"
        name="price"
        value={courseData.price}
        onChange={handleChange}
        required
      />

      <label>MRP:</label>
      <input
        type="number"
        name="mrp"
        value={courseData.mrp}
        onChange={handleChange}
        required
      />

      <label>SEO Title:</label>
      <input
        type="text"
        name="SEOTitle"
        value={courseData.SEOTitle}
        onChange={handleChange}
        required
      />

      <label>SEO Keyword:</label>
      <input
        type="text"
        name="SEOKeyword"
        value={courseData.SEOKeyword}
        onChange={handleChange}
        required
      />

      <label>SEO Permalink:</label>
      <input
        type="text"
        name="SEOPermalink"
        value={courseData.SEOPermalink}
        onChange={handleChange}
      />

      <label>SEO Description:</label>
      <textarea
        name="SEODescription"
        value={courseData.SEODescription}
        onChange={handleChange}
        required
      />

      <label>Edition:</label>
      <input
        type="text"
        name="edition"
        value={courseData.edition}
        onChange={handleChange}
        required
      />

      <button type="submit" disabled={loading}>
        {loading ? "Saving..." : "Save Course"}
      </button>
    </form>
  );
};

export default AddCourseForm;

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { useDropzone } from "react-dropzone";
// import Swal from "sweetalert2";
// import VideoContentEditor from "./VideoContentEditor";
// import styles from "./AddCourseForm.module.css";

// const AddCourseForm = () => {
//   const initialCourseData = {
//     courseTitle: "",
//     shortDescription: "",
//     longDescription: "",
//     category: "",
//     instructorName: "",
//     price: "",
//     mrp: "",
//     SEOTitle: "",
//     SEOKeyword: "",
//     SEOPermalink: "",
//     SEODescription: "",
//     subject: "",
//     edition: "",
//     hindiCoverImage: null,
//     englishCoverImage: null,
//   };

//   const [categories, setCategories] = useState([]);
//   const [subjects, setSubjects] = useState([]);
//   const [instructors, setInstructors] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [courseData, setCourseData] = useState(initialCourseData);

//   useEffect(() => {
//     const fetchCategoriesInstructorsAndSubjects = async () => {
//       try {
//         const [categoryRes, instructorRes, subjectRes] = await Promise.all([
//           axios.get("https://auth.ssccglpinnacle.com/categories"),
//           axios.get("https://auth.ssccglpinnacle.com/api/instructors"),
//           axios.get("https://auth.ssccglpinnacle.com/api/subjects"),
//         ]);

//         setCategories(categoryRes.data);
//         setInstructors(instructorRes.data.instructors || []);
//         setSubjects(subjectRes.data.subjects || []);
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       }
//     };

//     fetchCategoriesInstructorsAndSubjects();
//   }, []);

//   const handleContentChange = (field, value) => {
//     setCourseData({ ...courseData, [field]: value });
//   };

//   const generateSEO = (field) => {
//     const generatedText = `Generated ${field}`; // Replace with actual logic for generating SEO fields
//     handleContentChange(field, generatedText);
//   };

//   const { getRootProps: getRootPropsHindi, getInputProps: getInputPropsHindi } =
//     useDropzone({
//       accept: "image/*",
//       onDrop: (acceptedFiles) => onDrop(acceptedFiles, "hindiCoverImage"),
//     });

//   const {
//     getRootProps: getRootPropsEnglish,
//     getInputProps: getInputPropsEnglish,
//   } = useDropzone({
//     accept: "image/*",
//     onDrop: (acceptedFiles) => onDrop(acceptedFiles, "englishCoverImage"),
//   });

//   const onDrop = (acceptedFiles, name) => {
//     setCourseData({ ...courseData, [name]: acceptedFiles[0] });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true);

//     const formData = new FormData();
//     Object.keys(courseData).forEach((key) => {
//       if (key === "hindiCoverImage" || key === "englishCoverImage") {
//         if (courseData[key])
//           formData.append(key, courseData[key], courseData[key].name);
//       } else {
//         formData.append(key, courseData[key]);
//       }
//     });

//     try {
//       await axios.post(
//         "https://auth.ssccglpinnacle.com/create-course",
//         formData,
//         {
//           headers: { "Content-Type": "multipart/form-data" },
//         }
//       );
//       Swal.fire("Success", "Course created successfully", "success");
//       setCourseData(initialCourseData);
//     } catch (error) {
//       console.error("Error creating course:", error);
//       Swal.fire("Error", "Failed to create course", "error");
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <form onSubmit={handleSubmit} className={styles.container}>
//       <h2>Add Course</h2>

//       <label>Course Title:</label>
//       <input
//         type="text"
//         name="courseTitle"
//         value={courseData.courseTitle}
//         onChange={(e) => handleContentChange("courseTitle", e.target.value)}
//         required
//       />

//       <label>Short Description:</label>
//       <VideoContentEditor
//         content={courseData.shortDescription}
//         onContentChange={(value) =>
//           handleContentChange("shortDescription", value)
//         }
//       />
//       <button type="button" onClick={() => generateSEO("shortDescription")}>
//         Generate Short Description
//       </button>

//       <label>Long Description:</label>
//       <VideoContentEditor
//         content={courseData.longDescription}
//         onContentChange={(value) =>
//           handleContentChange("longDescription", value)
//         }
//       />
//       <button type="button" onClick={() => generateSEO("longDescription")}>
//         Generate Long Description
//       </button>

//       <label>Category:</label>
//       <select
//         name="category"
//         value={courseData.category}
//         onChange={(e) => handleContentChange("category", e.target.value)}
//         required
//       >
//         <option value="">Select a category</option>
//         {categories.map((cat) => (
//           <option key={cat._id} value={cat.categoryTitle}>
//             {cat.categoryTitle}
//           </option>
//         ))}
//       </select>

//       <label>Subject:</label>
//       <select
//         name="subject"
//         value={courseData.subject}
//         onChange={(e) => handleContentChange("subject", e.target.value)}
//         required
//       >
//         <option value="">Select a subject</option>
//         {subjects.map((sub) => (
//           <option key={sub._id} value={sub.title}>
//             {sub.title}
//           </option>
//         ))}
//       </select>

//       <label>Instructor Name:</label>
//       <select
//         name="instructorName"
//         value={courseData.instructorName}
//         onChange={(e) => handleContentChange("instructorName", e.target.value)}
//         required
//       >
//         <option value="">Select an instructor</option>
//         {instructors.map((ins) => (
//           <option key={ins._id} value={ins.instructorName}>
//             {ins.instructorName}
//           </option>
//         ))}
//       </select>

//       <label>Hindi Cover Image:</label>
//       <div {...getRootPropsHindi()} className={styles.dropzone}>
//         <input {...getInputPropsHindi()} />
//         <p>Drag 'n drop an image here, or click to select one</p>
//       </div>
//       {courseData.hindiCoverImage && (
//         <img
//           src={URL.createObjectURL(courseData.hindiCoverImage)}
//           alt="Hindi Cover"
//           className={styles.previewImage}
//         />
//       )}

//       <label>English Cover Image:</label>
//       <div {...getRootPropsEnglish()} className={styles.dropzone}>
//         <input {...getInputPropsEnglish()} />
//         <p>Drag 'n drop an image here, or click to select one</p>
//       </div>
//       {courseData.englishCoverImage && (
//         <img
//           src={URL.createObjectURL(courseData.englishCoverImage)}
//           alt="English Cover"
//           className={styles.previewImage}
//         />
//       )}

//       <label>Price:</label>
//       <input
//         type="number"
//         name="price"
//         value={courseData.price}
//         onChange={(e) => handleContentChange("price", e.target.value)}
//         required
//       />

//       <label>MRP:</label>
//       <input
//         type="number"
//         name="mrp"
//         value={courseData.mrp}
//         onChange={(e) => handleContentChange("mrp", e.target.value)}
//         required
//       />

//       <label>SEO Title:</label>
//       <input
//         type="text"
//         name="SEOTitle"
//         value={courseData.SEOTitle}
//         onChange={(e) => handleContentChange("SEOTitle", e.target.value)}
//         required
//       />
//       <button type="button" onClick={() => generateSEO("SEOTitle")}>
//         Generate SEO Title
//       </button>

//       <label>SEO Keyword:</label>
//       <input
//         type="text"
//         name="SEOKeyword"
//         value={courseData.SEOKeyword}
//         onChange={(e) => handleContentChange("SEOKeyword", e.target.value)}
//         required
//       />
//       <button type="button" onClick={() => generateSEO("SEOKeyword")}>
//         Generate SEO Keyword
//       </button>

//       <label>SEO Description:</label>
//       <input
//         type="text"
//         name="SEODescription"
//         value={courseData.SEODescription}
//         onChange={(e) => handleContentChange("SEODescription", e.target.value)}
//         required
//       />
//       <button type="button" onClick={() => generateSEO("SEODescription")}>
//         Generate SEO Description
//       </button>

//       <label>Edition:</label>
//       <input
//         type="text"
//         name="edition"
//         value={courseData.edition}
//         onChange={(e) => handleContentChange("edition", e.target.value)}
//         required
//       />

//       <button type="submit" disabled={loading}>
//         {loading ? "Saving..." : "Save Course"}
//       </button>
//     </form>
//   );
// };

// export default AddCourseForm;
