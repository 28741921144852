import React, { useState, useEffect } from "react";
import "./ProgressRow1.css";
import { FiChevronLeft } from 'react-icons/fi';
import pic from "../../i/book.jpg";
import LessonProgress from '../LessonProgress';
import { useAuth } from "../../../Context/AuthContext";
import { useNavigate } from "react-router-dom";

function ProgressRow1({ cardId }) {
  const navigate = useNavigate();
  const [chaptersData, setChaptersData] = useState([]);
  const [EbookData, setEbookData] = useState([]);
  const { user, token } = useAuth();
  const [currentUser] = useState(user._id);

  const navigateToOtherPage = () => {
    navigate(`/ebooks/mylearning`);
  };

  const navigateToOtherPage1 = () => {
    navigate(`/ebook/${cardId}/digitalPage1`);
  };

  useEffect(() => {
    fetch('https://auth.ssccglpinnacle.com/api/chapters-ebook', {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setChaptersData(data);
      })
      .catch((error) => {
        console.error('Error fetching chapters data:', error);
        navigate("/ebooks"); // Redirect on error
      });
  }, [token, navigate]); // Specify dependencies here

  useEffect(() => {
    fetch(`https://auth.ssccglpinnacle.com/api/ebookss/${cardId}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((ebookData) => {
        setEbookData(ebookData);
      })
      .catch((error) => {
        console.error('Error fetching ebook data:', error);
        navigate("/ebooks"); // Redirect on error
      });
  }, [cardId, navigate]); // Specify dependencies here

  const data = [
    { circleNumber: 1, readMoreText: 'Read chapters' },
    { circleNumber: 2, readMoreText: 'Do exercises' },
    { circleNumber: 3, readMoreText: 'Take quiz' },
    { circleNumber: 4, readMoreText: 'Purchase exam' },
  ];

  function countTopics(objects, currentUser) {
    let totalChapters = 0;
    let completedChapters = 0;
    totalChapters = objects.length;
    for (const obj of objects) {
      for (const completeds of obj.completed) {
        if (currentUser._id === completeds.user && completeds.userCompleted === "complete") {
          completedChapters += 1;
        }
      }
    }
    return { totalChapters, completedChapters };
  }

  const relatedChapters = chaptersData.filter((chapters) => chapters.ebook._id === cardId);
  const { totalChapters, completedChapters } = countTopics(relatedChapters, user);

  return (
    <>
      <div className="progress-report-row1">
        <div className="progress-report-row1-1">
          <FiChevronLeft size="29px" />
          <button className="overview-back" onClick={navigateToOtherPage}>Back to overview</button>
        </div>
        <div className="progress-report-row1-2">
          <div className="topic-header-cardbody">
            <div className="topic-container-progress">
              <div className="topic-header-cardbody-col-1">
                <div className="img-box">
                  <img className="card-image-progress-col" src={EbookData.image} />
                </div>
              </div>
              <div className="topic-header-cardbody-col-2">
                <div className="tutorial-container">
                  <p>TUTORIAL</p>
                </div>
                <div className="title-for-progress-2">
                  <h3>{EbookData.title}</h3>
                </div>
                <div className="info-given-para">
                  <p>It involves mathematical abilities, including basic arithmetic, problem-solving, and mathematical reasoning.</p>
                </div>
                <div className="Time-required-progress">
                  <p className="time-requires-p">Time require:</p>
                  <p>40 hours</p>
                </div>
                <div className="continue-for-progress-2">
                  <button className="button-for-continue-progress" onClick={navigateToOtherPage1}>Continue</button>
                </div>
              </div>
              <div className="topic-header-cardbody-col-3"></div>
            </div>
            <div className="lesson-completed-progress">
              <p><b>{completedChapters}</b></p> of <p>&nbsp;<b>{totalChapters}</b></p> lessons completed
            </div>
            <div className="progress-bar-for-learning-for-report">
              <LessonProgress totalLessons={totalChapters} completedLessons={completedChapters} />
            </div>
          </div>
        </div>
        <div className="progress-report-row1-3"></div>
      </div>
      <div className="spacing">
        <div className="divider-3">
          <svg className="svg-divider-3" style={{ fill: "#fafafa" }}>
            <path d="M 0 31 C 313 -17 359 19 530 29 S 905 -20 1303 21 S 1677 -28 2537 29 L 2537 0 L 0 0 L 0 31"></path>
          </svg>
        </div>
      </div>
      <div className="Read-Do-Take-Purchase">
        {data.map((item, index) => (
          <div key={index} className="progress-read-more">
            <div className="progress-read-more-1">
              <div className="circle-for-number">
                <span className="no-for-circle">{item.circleNumber}</span>
              </div>
            </div>
            <div className="progress-read-more-2">{item.readMoreText}</div>
          </div>
        ))}
      </div>
    </>
  );
}

export default ProgressRow1;
