// import React, { useEffect, useState } from "react";
// import { MdClose } from "react-icons/md"; // Import the close icon
// import "./MyComponent.css";
// import ReviewModal from "./ReviewModal";
// import { useParams } from "react-router-dom";
// import { useAuth } from "../../../Context/AuthContext";
// import pic from ".././mbook.svg";
// import { useCookies } from "react-cookie";

// const MyComponent = () => {
//   const [cookies] = useCookies(["token", "email_id"]);
//   const [showModal, setShowModal] = useState(false);
//   const { isLoggedIn, user } = useAuth();
//   const { cardId } = useParams();
//   const [purchaseHistory, setPurchaseHistory] = useState([]);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         if (isLoggedIn && user && user.id) {
//           const purchaseHistoryResponse = await fetch(`https://auth.ssccglpinnacle.com/api/purchase-history-ebook/${user.email_id}`, {
//             headers: {
//               "Content-Type": "application/json",
//               "Accept": "application/json",
//               "Authorization": `Bearer ${cookies.token}`
//             }
//           });

//           if (!purchaseHistoryResponse.ok) {
//             throw new Error(`Failed to fetch purchase history. Status: ${purchaseHistoryResponse.status}`);
//           }

//           const purchaseHistoryData = await purchaseHistoryResponse.json();
//           setPurchaseHistory(purchaseHistoryData.ebooksPurchased || []);
//         }
//       } catch (error) {
//         console.error("Error fetching purchase history:", error);
//       }
//     };

//     fetchData();
//   }, [isLoggedIn, user]);

//   const handleShowModal = () => {
//     setShowModal(true);
//   };

//   const handleCloseModal = () => {
//     setShowModal(false);
//   };

//   const hasPurchased = Array.isArray(purchaseHistory) && purchaseHistory.some(item => item._id === cardId);

//   let purchasedEbookId;
//   const purchasedEbook = hasPurchased && purchaseHistory.some((purchase) => {
//     if (purchase._id === cardId) {
//       purchasedEbookId = purchase._id;
//       return true;
//     }
//     return false;
//   });

//   return (
//     <div>
//       <div className="write-review" onClick={handleShowModal}>
//         Write a Review
//       </div>
//       {showModal && (
//         <div className="modal-container open">
//           <div className="modal-content-review open">
//             {hasPurchased ? (
//               <ReviewModal purchasedEbookId={purchasedEbookId} handleCloseModal={handleCloseModal} />
//             ) : (
//               <div className="you-must">
//                 <img src={pic} alt="Component Image" />
//                 <p>You must buy this digital ebook to give a rating and review.</p>
//               </div>
//             )}
//             <button className="for-review-close-ebook" onClick={handleCloseModal}>
//               <MdClose /> {/* Use the close icon */}
//             </button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default MyComponent;


import React, { useEffect, useState } from "react";
import { MdClose } from "react-icons/md"; // Import the close icon
import "./MyComponent.css";
import ReviewModal from "./ReviewModal";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../Context/AuthContext";
import pic from ".././mbook.svg";
import { useCookies } from "react-cookie";

const MyComponent = () => {
  const [cookies] = useCookies(["token", "email_id"]);
  const [showModal, setShowModal] = useState(false);
  const { isLoggedIn, user } = useAuth();
  const { category, subject, title } = useParams(); // Get parameters from the URL
  const [purchaseHistory, setPurchaseHistory] = useState([]);
  const [cardId, setCardId] = useState(null); // Track the resolved cardId
  const [ebookData, setEbookData] = useState([]); // Store fetched ebooks

  // Utility function to format strings for comparison
  const formatForComparison = (value) =>
    value?.toLowerCase().replace(/[-\s]/g, "") || "";

  // Fetch the ebook data and resolve cardId
  useEffect(() => {
    const fetchEbookData = async () => {
      try {
        const ebookResponse = await fetch(
          "https://auth.ssccglpinnacle.com/api/ebooksforactive?active=true"
        );
        if (!ebookResponse.ok) {
          throw new Error(
            `Failed to fetch ebooks. Status: ${ebookResponse.status}`
          );
        }

        const ebookData = await ebookResponse.json();
        setEbookData(ebookData);

        // Format URL parameters for comparison
        const formattedCategory = formatForComparison(category);
        const formattedSubject = formatForComparison(subject);
        const formattedTitle = formatForComparison(title);

        // Find the matching ebook
        const matchingEbook = ebookData.find((item) => {
          const formattedItemCategory = formatForComparison(item.category);
          const formattedItemSubject = formatForComparison(item.subject);
          const formattedItemTitle = formatForComparison(item.title);

          return (
            formattedItemCategory === formattedCategory &&
            formattedItemSubject === formattedSubject &&
            formattedItemTitle === formattedTitle
          );
        });

        if (matchingEbook) {
          setCardId(matchingEbook._id); // Set the resolved cardId
        }
      } catch (error) {
        console.error("Error fetching ebook data:", error);
      }
    };

    fetchEbookData();
  }, [category, subject, title]);

  // Fetch purchase history
  useEffect(() => {
    const fetchPurchaseHistory = async () => {
      try {
        if (isLoggedIn && user && user.email_id) {
          const purchaseHistoryResponse = await fetch(
            `https://auth.ssccglpinnacle.com/api/purchase-history-ebook/${user.email_id}`,
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${cookies.token}`,
              },
            }
          );

          if (!purchaseHistoryResponse.ok) {
            throw new Error(
              `Failed to fetch purchase history. Status: ${purchaseHistoryResponse.status}`
            );
          }

          const purchaseHistoryData = await purchaseHistoryResponse.json();
          setPurchaseHistory(purchaseHistoryData.ebooksPurchased || []);
        }
      } catch (error) {
        console.error("Error fetching purchase history:", error);
      }
    };

    fetchPurchaseHistory();
  }, [isLoggedIn, user, cookies.token]);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const hasPurchased =
    Array.isArray(purchaseHistory) &&
    purchaseHistory.some((item) => item._id === cardId);

  return (
    <div>
      <div className="write-review" onClick={handleShowModal}>
        Write a Review
      </div>
      {showModal && (
        <div className="modal-container open">
          <div className="modal-content-review open">
            {hasPurchased ? (
              <ReviewModal
                purchasedEbookId={cardId}
                handleCloseModal={handleCloseModal}
              />
            ) : (
              <div className="you-must">
                <img src={pic} alt="Component Image" />
                <p>
                  You must buy this digital ebook to give a rating and review.
                </p>
              </div>
            )}
            <button
              className="for-review-close-ebook"
              onClick={handleCloseModal}
            >
              <MdClose />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default MyComponent;
