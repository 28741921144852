// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.Navbar_nav__lJrUD  {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 18%;
  height: 80%;
  align-items: center;
  
}
.Navbar_nav__lJrUD a:hover,
.Navbar_nav__lJrUD a:active {
  color: #e7d6eb;
}
.Navbar_nav__lJrUD button:focus {
  outline: none;
}

.Navbar_nav__lJrUD button:hover,
.Navbar_nav__lJrUD button:active {
  color: #696669;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.26);
}
.Navbar_nav__lJrUD img{
  vertical-align: middle;
    height: 80%;
    /* padding-bottom: 8rem; */
    margin-top: 4%;
}



@media (max-width: 546px) {

}

@media only screen and (min-width:320px) and (max-width:900px){
  .Navbar_nav__lJrUD {
    width: 18%;
    height: 30%;
    position: relative;
    top: -21px;
  }
  .Navbar_nav__lJrUD img {
    margin-top: 0%;
  }
}


@media (min-width: 1367px) and (max-width: 1399px) {
  .Navbar_headerimage__KPcgX img {
    width: 52%;
  }
}


@media (min-width: 1400px) and (max-width: 1499px) {
  .Navbar_headerimage__KPcgX img {
    width: 60%;
  }
}

@media (min-width: 1501px) and (max-width: 1599px) {

.Navbar_nav__lJrUD img{
  height: 77%;
  }
}


@media (min-width: 1600px) and (max-width: 1919px) {
  .Navbar_nav__lJrUD img{
    height: 63%;

  }
}


@media (min-width: 1920px) and (max-width: 3839px) {
  .Navbar_headerimage__KPcgX img {
    width: 60%;
  }
}


@media (min-width: 3840px) {
  .Navbar_headerimage__KPcgX img {
    width: 60%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/component/HeaderFiles/Navbar.module.css"],"names":[],"mappings":";AACA;EACE,gBAAgB;EAChB,SAAS;EACT,UAAU;EACV,UAAU;EACV,WAAW;EACX,mBAAmB;;AAErB;AACA;;EAEE,cAAc;AAChB;AACA;EACE,aAAa;AACf;;AAEA;;EAEE,cAAc;EACd,0CAA0C;AAC5C;AACA;EACE,sBAAsB;IACpB,WAAW;IACX,0BAA0B;IAC1B,cAAc;AAClB;;;;AAIA;;AAEA;;AAEA;EACE;IACE,UAAU;IACV,WAAW;IACX,kBAAkB;IAClB,UAAU;EACZ;EACA;IACE,cAAc;EAChB;AACF;;;AAGA;EACE;IACE,UAAU;EACZ;AACF;;;AAGA;EACE;IACE,UAAU;EACZ;AACF;;AAEA;;AAEA;EACE,WAAW;EACX;AACF;;;AAGA;EACE;IACE,WAAW;;EAEb;AACF;;;AAGA;EACE;IACE,UAAU;EACZ;AACF;;;AAGA;EACE;IACE,UAAU;EACZ;AACF","sourcesContent":["\n.nav  {\n  list-style: none;\n  margin: 0;\n  padding: 0;\n  width: 18%;\n  height: 80%;\n  align-items: center;\n  \n}\n.nav a:hover,\n.nav a:active {\n  color: #e7d6eb;\n}\n.nav button:focus {\n  outline: none;\n}\n\n.nav button:hover,\n.nav button:active {\n  color: #696669;\n  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.26);\n}\n.nav img{\n  vertical-align: middle;\n    height: 80%;\n    /* padding-bottom: 8rem; */\n    margin-top: 4%;\n}\n\n\n\n@media (max-width: 546px) {\n\n}\n\n@media only screen and (min-width:320px) and (max-width:900px){\n  .nav {\n    width: 18%;\n    height: 30%;\n    position: relative;\n    top: -21px;\n  }\n  .nav img {\n    margin-top: 0%;\n  }\n}\n\n\n@media (min-width: 1367px) and (max-width: 1399px) {\n  .headerimage img {\n    width: 52%;\n  }\n}\n\n\n@media (min-width: 1400px) and (max-width: 1499px) {\n  .headerimage img {\n    width: 60%;\n  }\n}\n\n@media (min-width: 1501px) and (max-width: 1599px) {\n\n.nav img{\n  height: 77%;\n  }\n}\n\n\n@media (min-width: 1600px) and (max-width: 1919px) {\n  .nav img{\n    height: 63%;\n\n  }\n}\n\n\n@media (min-width: 1920px) and (max-width: 3839px) {\n  .headerimage img {\n    width: 60%;\n  }\n}\n\n\n@media (min-width: 3840px) {\n  .headerimage img {\n    width: 60%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nav": `Navbar_nav__lJrUD`,
	"headerimage": `Navbar_headerimage__KPcgX`
};
export default ___CSS_LOADER_EXPORT___;
