
import React, { useState, useEffect } from "react";
import "./MylearningRow4.css";
import { BsArrowRight } from 'react-icons/bs';
import LessonProgress from './LessonProgress';
import pic from "../i/book.jpg";
import { PiGraduationCapDuotone } from 'react-icons/pi';
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../Context/AuthContext";
import LoadingSpinner from '../LoadingSpinner';

function MylearningRow4() {
  const [chaptersData, setChaptersData] = useState([]);
  const [completedEbooks, setCompletedEbooks] = useState([]);
  const navigate = useNavigate();
  const { isLoggedIn, user, token } = useAuth();
  const [purchaseHistory, setPurchaseHistory] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchChaptersData = async () => {
      setLoading(true);
      try {
        const response = await fetch('https://auth.ssccglpinnacle.com/api/chapters-ebook', {
          headers: {
            "Authorization": `Bearer ${token}`
          }
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setChaptersData(data);
      } catch (error) {
        console.error('Error fetching chapters data:', error);
      } finally {
        setLoading(false);
      }
    };

    if (token) {
      fetchChaptersData();
    }
  }, [token]);

  useEffect(() => {
    const fetchPurchaseHistory = async () => {
      if (isLoggedIn && user && token) {
        setLoading(true);
        const useremail = user.email_id;

        try {
          const response = await fetch(`https://auth.ssccglpinnacle.com/api/purchase-history-ebook/${useremail}`, {
            headers: {
              "Authorization": `Bearer ${token}`
            }
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          // Check and handle the ebooksPurchased array:
          setPurchaseHistory(data.ebooksPurchased || []);
        } catch (error) {
          console.error('Error fetching purchase history:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchPurchaseHistory();
  }, [isLoggedIn, user, token]);

  useEffect(() => {
    const completedEbooks = purchaseHistory.filter((ebook) => {
      const relatedChapters = chaptersData.filter(
        (chapter) => chapter.ebook && chapter.ebook._id === ebook._id
      );
      const allChaptersCompleted = relatedChapters.every((chapter) =>
        chapter.completed && chapter.completed.some(
          (completion) => completion.user === user._id && completion.userCompleted === "complete"
        )
      );
      return allChaptersCompleted;
    }).map((ebook) => {
      const relatedChapters = chaptersData.filter(
        (chapter) => chapter.ebook && chapter.ebook._id === ebook._id
      );
      const completedChapters = relatedChapters.filter(chapter =>
        chapter.completed && chapter.completed.some(
          (completion) => completion.user === user._id && completion.userCompleted === "complete"
        )
      ).length;

      return {
        ...ebook,
        totalChapters: relatedChapters.length,
        completedChapters: completedChapters,
      };
    });

    setCompletedEbooks(completedEbooks);
    setLoading(false);
  }, [purchaseHistory, chaptersData, user]);

  if (!isLoggedIn || !user) {
    return <p>User not logged in</p>;
  }

  const handleClick2 = () => {
    navigate('/ebooks');
  };

  // const info = (cardId) => {
  //   navigate(`/Progress/${cardId}`);
  // };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <div className="my-learning-row4">
        <div className="completed-card">
          <div className="only-colour">
            <div className="inprogress-header-new-completed">
              <h2>Completed digital contents</h2>
              <div className="show-all-new-completed">
                <BsArrowRight size={20} color="rgb(255, 192, 199)" />
                <p>Explore all</p>
              </div>
            </div>

            {completedEbooks.length > 0 ? (
              <div className="card-for-progress-info-completed">
                {completedEbooks.slice(0, 4).map((card) => (
                  <div className="custom-card digital-content-card-progress-new" key={card._id}>
                    <div className="class-for-img-progress-new-completed" >
                      <div className="classes-for-pro-img-new-completed">
                      <img className="card-image-progress-showall" src={card.image} alt="Book Cover" />
                      </div>
                    </div>
                    <div className="card-content-progress">
                      <div className="title-progress-report">
                        <h6>{card.title}</h6>
                      </div>
                      <div className="lesson-completed">
                        <p><b>{card.completedChapters}</b> of <b>{card.totalChapters}</b> lessons completed</p>
                      </div>
                      <div className="progress-bar-for-learning">
                        <LessonProgress totalLessons={card.totalChapters} completedLessons={card.completedChapters} />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="not-completed-message">
                <div className="circle-icon-scolar">
                  <PiGraduationCapDuotone size={50} />
                </div>
                <h4>This feed shows completed ebooks.</h4>
                <p>Are there ebooks you miss? Reload the page.</p>
                <button className="browse-tutorial-button" onClick={handleClick2}>Browse all digital content</button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default MylearningRow4;

