import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styles from "./EbookTransactionModal.module.css"; // CSS file for styling

const EbookTransactionModal = () => {
  const [transactionData, setTransactionData] = useState(null);
  const [countdown, setCountdown] = useState(8); // Countdown in seconds
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get("order_id");

  const closeModal = () => {
    navigate(-1);
  };

  const redirectToMyEbooks = () => {
    navigate(`/ebooks/mylearning`);
  };

  const redirectToHome = () => {
    navigate(`/ebooks`);
  };

  // Fetch transaction details
  useEffect(() => {
    if (orderId) {
      const fetchTransactionDetails = async () => {
        try {
          const response = await fetch(
            `https://auth.ssccglpinnacle.com/api/ebook-transaction/${orderId}`
          );

          if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
          }

          const data = await response.json();
          setTransactionData(data);
        } catch (error) {
          console.error("Error fetching transaction:", error.message);
        } finally {
          setLoading(false);
        }
      };

      fetchTransactionDetails();
    }
  }, [orderId]);

  // Redirect on success or failure
  useEffect(() => {
    let timer;
    if (transactionData) {
      if (transactionData.paymentStatus === "Completed") {
        // Push data to the dataLayer for GTM tracking
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "order_complete",
          order_value: transactionData.amountPaid,
          order_id: transactionData.orderId,
        });

        timer = setInterval(() => {
          setCountdown((prev) => {
            if (prev === 1) {
              clearInterval(timer);
              redirectToMyEbooks();
            }
            return prev - 1;
          });
        }, 1000);
      } else if (transactionData.paymentStatus === "Pending") {
        timer = setInterval(() => {
          setCountdown((prev) => {
            if (prev === 1) {
              clearInterval(timer);
              redirectToHome();
            }
            return prev - 1;
          });
        }, 1000);
      }
    }
    return () => clearInterval(timer);
  }, [transactionData]);

  if (loading) {
    return (
      <div className={styles.modalOverlay}>
        <div className={styles.modalContent}>
          <h2>Loading transaction details...</h2>
        </div>
      </div>
    );
  }

  if (!orderId) return null;

  const paymentStatus = transactionData?.paymentStatus;
  const isPaymentSuccessful = paymentStatus === "Completed";
  const isPaymentPending = paymentStatus === "Pending";
  const isPaymentFailed = paymentStatus === "Failed";

  return (
    <div className={styles.modalOverlay} onClick={closeModal}>
      <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        <div className={styles.iconWrapper}>
          <div
            className={
              isPaymentSuccessful ? styles.successIcon : styles.failedIcon
            }
          >
            <span className={styles.checkMark}>
              {isPaymentSuccessful ? "✓" : "✗"}
            </span>
          </div>
        </div>
        <h2
          className={`${styles.statusText} ${
            isPaymentSuccessful ? styles.success : styles.failed
          }`}
        >
          {isPaymentSuccessful
            ? "Payment Successful"
            : isPaymentPending
            ? "Payment Failed"
            : isPaymentFailed
            ? "Payment Failed"
            : ""}
        </h2>
        {transactionData && isPaymentSuccessful ? (
          <>
            <p className={styles.greet}>Thank you for choosing Pinnacle.</p>
            <p className={styles.amount}>₹{transactionData.amountPaid}/-</p>

            {/* <p className={styles.accountInfo}>
              eBook SKUs: {transactionData.ebookSKUs?.join(", ")}
            </p> */}
            {/* <p className={styles.orderId}>
              eBook SKUs: {transactionData.ebookSKUs?.join(", ")}
            </p> */}
            <p className={styles.redirectMessage}>
              Redirecting to My Ebooks in <span>{countdown}</span> seconds...
            </p>
          </>
        ) : (
          <p className={styles.failedMessage}>
            {isPaymentPending
              ? "Your payment is failed. Please try again."
              : "Payment failed. Please try again."}
          </p>
        )}
        {isPaymentSuccessful && (
          <button
            className={styles.redirectButton}
            onClick={redirectToMyEbooks}
          >
            Go to My Ebooks
          </button>
        )}
        {!isPaymentSuccessful && (
          <div>
            <p className={styles.redirectMessage}>
              Redirecting to Home in <span>{countdown}</span> seconds...
            </p>
            <div className={styles.spinner}></div>
            <button className={styles.redirectButton} onClick={redirectToHome}>
              Go back to Home
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default EbookTransactionModal;
