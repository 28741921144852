import React from "react";
import styles from "./Footer.module.css";
import youtube from "./footerimg/youtube.svg";
import telegram from "./footerimg/telegram.svg";
import whatsapp from "./footerimg/whatsapp.svg";
import play from "./footerimg/play.svg";
import gmail from "./footerimg/gmail.svg";
import facebook from "./footerimg/facebook.svg";

function Footer() {
  return (
    <div className={styles.siteFooter}>
      <div className={styles.footerColumn}>
        <h5>Company</h5>
        <ul className={styles.footerLinks}>
          <li>
            <a href="/about-us" target="_blank" rel="noopener noreferrer">
              About Us
            </a>
          </li>
          <li>
            <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>
          </li>
          <li>
            <a
              href="/terms-and-condition"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms & Conditions
            </a>
          </li>
          <li>
            <a href="/refund-policy" target="_blank" rel="noopener noreferrer">
              Refund Policy
            </a>
          </li>
          <li>
            <a href="/contact-us" target="_blank" rel="noopener noreferrer">
              Contact Us
            </a>
          </li>
          <li>
            <a href="/sitemap" target="_blank" rel="noopener noreferrer">
              Sitemap
            </a>
          </li>
        </ul>
      </div>

      <div className={styles.footerColumn}>
        <h5>Explore</h5>
        <ul className={styles.footerLinks}>
          <li>
            <a
              href="https://testportal.ssccglpinnacle.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Test Portal
            </a>
          </li>
          <li>
            <a href="/typing" target="_blank" rel="noopener noreferrer">
              Typing
            </a>
          </li>
          <li>
            <a href="/books" target="_blank" rel="noopener noreferrer">
              Books
            </a>
          </li>
          <li>
            <a href="/videos" target="_blank" rel="noopener noreferrer">
              Videos
            </a>
          </li>
          <li>
            <a href="/ebooks" target="_blank" rel="noopener noreferrer">
              E-Books
            </a>
          </li>
          <li>
            <a href="/blog" target="_blank" rel="noopener noreferrer">
              Blogs
            </a>
          </li>
        </ul>
      </div>

      <div className={styles.footerColumn}>
        <h5>Connect</h5>
        <ul className={styles.footerLinks}>
          <li className={styles.socialIcons}>
            <img src={youtube} alt="YouTube" />
            <a
              href="https://www.youtube.com/@pinnaclepublications"
              target="_blank"
              rel="noopener noreferrer"
            >
              YouTube
            </a>
          </li>
          <li className={styles.socialIcons}>
            <img src={telegram} alt="Telegram" />
            <a
              href="https://t.me/ssccglpinnacleonline"
              target="_blank"
              rel="noopener noreferrer"
            >
              Telegram
            </a>
          </li>
          <li className={styles.socialIcons}>
            <img src={whatsapp} alt="WhatsApp" />
            <a
              href="https://whatsapp.com/channel/0029VaFZ5QTC6ZvcTFgbMe2J"
              target="_blank"
              rel="noopener noreferrer"
            >
              WhatsApp
            </a>
          </li>
          <li className={styles.socialIcons}>
            <img src={facebook} alt="Facebook" />
            <a
              href="https://www.facebook.com/Pinnacle-SSC-Coaching-676467975776244/timeline"
              target="_blank"
              rel="noopener noreferrer"
            >
              Facebook
            </a>
          </li>
          <li className={styles.socialIcons}>
            <img src={play} alt="Google Play" />
            <a
              href="https://play.google.com/store/apps/details?id=com.ssccgl.pinnacle.testportal"
              target="_blank"
              rel="noopener noreferrer"
            >
              Download App
            </a>
          </li>
          <li className={styles.socialIcons}>
            <img src={gmail} alt="Email" />
            <a
              href="mailto:support@ssccglpinnacle.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              support@ssccglpinnacle.com
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Footer;
