import React, { useContext, useState, useEffect } from "react";
import classes from "./Header1.css";
import { AiFillCaretDown, AiFillCaretUp, AiOutlineClose } from "react-icons/ai";
import { ThemeContext } from "../../Context/ThemeContext";
import { Link, useNavigate, useLocation } from "react-router-dom";
// Components with their respective lists
const DigitalCatalogs = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    // Function to fetch data from API
    const fetchData = async () => {
      try {
        const response = await fetch("https://auth.ssccglpinnacle.com/api/ebooksforactive?active=true");
        const jsonData = await response.json();
        setData(jsonData); // Store the fetched data in state
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Call the function when the component mounts
  }, []);

  // console.log("myexam name data", data)

  
  const formatTitleForUrl = (title) => {
    return title.toLowerCase().replace(/\s+/g, '-');
  };

  const info = (category, subject, title) => {
    const formattedExamCategory = formatTitleForUrl(category);
    const formattedSubjects = formatTitleForUrl(subject);
    const formattedTitle = formatTitleForUrl(title);
    navigate(`/ebooks/${formattedExamCategory}/${formattedSubjects}/${formattedTitle}`);
};
  return (
    <div className ="open-for-header1">
    
    <div className = "list-open">
     <div className="w3-row-padding w3-bar-block">
  <div className="w3-container" >
   <h2 ><b>Ebooks</b></h2></div></div>
   
  <div className = "container-fluid-for">
  <div className="w3-col l3 m6">
  <h3 className="w3-margin-top">SSC</h3>
  {data
    .filter(item => item.category === "SSC")
    .map(item => (
      <div 
        key={item._id} 
        className="w3-bar-item ga-top-drop w3-button" 
        onClick={() => info(item.category, item.subject, item.title)}
      >
        {item.title}
      </div>
    ))}
</div>
     
          <div className="w3-col l3 m6">
  <h3 className="w3-margin-top">Railway</h3>
  {/* Replace "Railway" with the appropriate category name */}
  {data
    .filter(item => item.category === "Railway")
    .map(item => (
      <div key={item._id} className="w3-bar-item ga-top-drop w3-button"  onClick={() => info(item.category, item.subject, item.title)}>{item.title}</div>
    ))}
</div>

<div className="w3-col l3 m6">
  <h3 className="w3-margin-top">Banking</h3>
  {/* Replace "Banking" with the appropriate category name */}
  {data
    .filter(item => item.category === "Banking")
    .map(item => (
      <div key={item._id} className="w3-bar-item ga-top-drop w3-button"  onClick={() => info(item.category, item.subject, item.title)}></div>
    ))}
</div>

  
<div className="w3-col l3 m6">
  <h3 className="w3-margin-top">Teaching</h3>
  {/* Replace "Teaching" with the appropriate category name */}
  {data
    .filter(item => item.category === "Teaching")
    .map(item => (
      <div key={item._id} className="w3-bar-item ga-top-drop w3-button"  onClick={() => info(item.category, item.subject, item.title)}></div>
    ))}
</div>

</div>
</div>
     </div>
  );
};

const Exercises = () => {
  return (
   <div className ="open-for-header1">
     <div className = "list-open">
     <div className="w3-row-padding w3-bar-block">
  <div className="w3-container" >
   <h2 ><b>Exercises</b></h2></div></div>
   <button class="ws-btn ws-yellow w3-hover-text-white" 
 href="">Exercises</button>
  <div className = "container-fluid-for">
  <div className="w3-col l3 m6">
  <a className="w3-bar-item ga-top-drop w3-button" href="">Learn HTML</a>
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn CSS</a>
   <a className="w3-bar-item ga-top-drop w3-button" href="" title="Responsive Web Design">Learn RWD</a>
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn Bootstrap</a>
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn W3.CSS</a>
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn Colors</a>
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn Icons</a>
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn Graphics</a>
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn SVG</a>
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn Canvas</a>
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn How To</a>
   <a className="w3-bar-item ga-top-drop w3-button" href="/sass/default.php">Learn Sass</a>   
</div>
  
    
 <div className="w3-col l3 m6">

  

   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn AI</a>
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn Machine Learning</a>
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn Data Science</a> 
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn NumPy</a>       
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn Pandas</a>    
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn SciPy</a>    
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn Matplotlib</a>    
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn Statistics</a>
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn Excel</a>
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn Google Sheets</a>
  </div> 
<div className="w3-col l3 m6">

 
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn XML</a>
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn XML AJAX</a>
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn XML DOM</a>
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn XML DTD</a>
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn XML Schema</a>
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn XSLT</a>
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn XPath</a>
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn XQuery</a>
  </div> 
  
   <div className="w3-col l3 m6">
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn XML</a>
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn XML AJAX</a>
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn XML DOM</a>
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn XML DTD</a>
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn XML Schema</a>
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn XSLT</a>
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn XPath</a>
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn XQuery</a>
  </div>
</div>
    </div>
    </div>
  );
};

const Quizzes = () => {
  return (
    <div className ="open-for-header1">
     <div className = "list-open">
     <div className="w3-row-padding w3-bar-block">
  <div className="w3-container" >
   <h2 ><b>Quizzes</b></h2></div></div>
   <button class="ws-btn ws-yellow w3-hover-text-white" 
 href="">Quizzes</button>
  <div className = "container-fluid-for">
  <div className="w3-col l3 m6">
  <a className="w3-bar-item ga-top-drop w3-button" href="">Learn HTML</a>
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn CSS</a>
   <a className="w3-bar-item ga-top-drop w3-button" href="" title="Responsive Web Design">Learn RWD</a>
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn Bootstrap</a>
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn W3.CSS</a>
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn Colors</a>
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn Icons</a>
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn Graphics</a>
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn SVG</a>
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn Canvas</a>
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn How To</a>
   <a className="w3-bar-item ga-top-drop w3-button" href="/sass/default.php">Learn Sass</a>   
</div>
  
    
 <div className="w3-col l3 m6">
    <a className="w3-bar-item ga-top-drop w3-button" href="">Learn AI</a>
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn Machine Learning</a>
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn Data Science</a> 
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn NumPy</a>       
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn Pandas</a>    
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn SciPy</a>    
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn Matplotlib</a>    
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn Statistics</a>
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn Excel</a>
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn Google Sheets</a>
  </div> 
    <div className="w3-col l3 m6">

 
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn XML</a>
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn XML AJAX</a>
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn XML DOM</a>
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn XML DTD</a>
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn XML Schema</a>
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn XSLT</a>
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn XPath</a>
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn XQuery</a>
  </div> 
  
   <div className="w3-col l3 m6">
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn XML</a>
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn XML AJAX</a>
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn XML DOM</a>
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn XML DTD</a>
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn XML Schema</a>
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn XSLT</a>
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn XPath</a>
   <a className="w3-bar-item ga-top-drop w3-button" href="">Learn XQuery</a>
  </div>
    </div>
    </div>
    </div>
  );
};

function Header1() {
  const { theme } = useContext(ThemeContext);
  const [activeItem, setActiveItem] = useState(null);

  const handleClick = (index) => {
    if (activeItem === index) {
      setActiveItem(null);
    } else {
      setActiveItem(index);
    }
  };

  const handleClose = () => {
    setActiveItem(null);
  };


  return (
    <>
      <div
        className={`theme ${theme === "dark" ? "dark-theme" : "light-theme"}`}
      >
        <nav className="navDigital">
          <ul className="navlistsdigital">
            <li  onClick={() => handleClick(0)}>
             Ebooks
              {activeItem === 0 ? (
                <AiFillCaretUp className="home" fontSize="12px" />
              ) : (
                <AiFillCaretDown className="home" fontSize="12px" />
              )}
            </li>
            {/* <li onClick={() => handleClick(1)}> */}
            <li>
              Exercises
              {activeItem === 1 ? (
                <AiFillCaretUp className="home" fontSize="12px" />
              ) : (
                <AiFillCaretDown className="home" fontSize="12px" />
              )}
            </li>
            <li>
            {/* <li onClick={() => handleClick(2)}> */}
              Quizzes
              {activeItem === 2 ? (
                <AiFillCaretUp className="home" fontSize="12px" />
              ) : (
                <AiFillCaretDown className="home" fontSize="12px" />
              )}
            </li>
           
           
            
       
          </ul>
        </nav>
         <div onClick={handleClose}></div>
        {activeItem !== null && (
          <li id ="abc" onClick={handleClose} className="close-icon">
            <AiOutlineClose id ="abcd" fontSize="1.5em" height="2em" width="2em"  />
          </li>
        )}
      </div>
      {activeItem !== null && (
        <>
          {activeItem === 0 && <DigitalCatalogs />}
          {activeItem === 1 && <Exercises />}
          {activeItem === 2 && <Quizzes />}
        </>
      )}
    </>
  );
}

export default Header1;
