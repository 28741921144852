import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styles from "./VideosTransactionModal.module.css"; // CSS file for styling

const VideoTransactionModal = () => {
  const [transactionData, setTransactionData] = useState(null);
  const [countdown, setCountdown] = useState(8);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get("order_id");

  const closeModal = () => {
    navigate(-1);
  };

  const redirectToMyLearning = () => {
    navigate(`/videos/mylearning`);
  };

  const redirectToCart = () => {
    navigate(`/videos/cart`);
  };

  // Fetch transaction details
  useEffect(() => {
    if (orderId) {
      const fetchTransactionDetails = async () => {
        try {
          const response = await fetch(
            `https://auth.ssccglpinnacle.com/api/transaction/${orderId}`
          );

          if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
          }

          const data = await response.json();
          setTransactionData(data);
        } catch (error) {
          console.error("Error fetching transaction:", error.message);
          setTransactionData({ paymentStatus: "Failed" }); // Set failed status on error
        } finally {
          setLoading(false);
        }
      };

      fetchTransactionDetails();
    }
  }, [orderId]);

  // Handle countdown for redirection and push GTM events
  useEffect(() => {
    let timer;
    if (transactionData) {
      if (transactionData.paymentStatus === "SUCCESS") {
        // Push data to GTM's dataLayer
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "order_complete",
          order_value: transactionData.amountPaid,
          order_id: transactionData.orderId,
        });

        timer = setInterval(() => {
          setCountdown((prev) => {
            if (prev === 1) {
              clearInterval(timer);
              redirectToMyLearning();
            }
            return prev - 1;
          });
        }, 1000);
      } else if (transactionData.paymentStatus === "Pending") {
        timer = setInterval(() => {
          setCountdown((prev) => {
            if (prev === 1) {
              clearInterval(timer);
              redirectToCart();
            }
            return prev - 1;
          });
        }, 1000);
      } else if (transactionData.paymentStatus === "Failed") {
        timer = setInterval(() => {
          setCountdown((prev) => {
            if (prev === 1) {
              clearInterval(timer);
              redirectToCart();
            }
            return prev - 1;
          });
        }, 1000);
      }
    }
    return () => clearInterval(timer);
  }, [transactionData]);

  if (loading) {
    return (
      <div className={styles.modalOverlay}>
        <div className={styles.modalContent}>
          <h2>Loading transaction details...</h2>
        </div>
      </div>
    );
  }

  if (!orderId) return null;

  const paymentStatus = transactionData?.paymentStatus;
  const isPaymentSuccessful = paymentStatus === "SUCCESS";
  const isPaymentPending = paymentStatus === "Pending";
  const isPaymentFailed = paymentStatus === "Failed";

  return (
    <div className={styles.modalOverlay} onClick={closeModal}>
      <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        <div className={styles.iconWrapper}>
          <div
            className={
              isPaymentSuccessful
                ? styles.successIcon
                : isPaymentFailed
                ? styles.failedIcon
                : styles.pendingIcon
            }
          >
            <span className={styles.checkMark}>
              {isPaymentSuccessful ? "✓" : "✗"}
            </span>
          </div>
        </div>
        <h2
          className={`${styles.statusText} ${
            isPaymentSuccessful
              ? styles.success
              : isPaymentFailed
              ? styles.failed
              : styles.pending
          }`}
        >
          {isPaymentSuccessful
            ? "Payment Successful"
            : isPaymentPending
            ? "Payment Pending"
            : isPaymentFailed
            ? "Payment Failed"
            : ""}
        </h2>
        {transactionData && isPaymentSuccessful ? (
          <>
            <p className={styles.greet}>Thank you for choosing Pinnacle.</p>
            <p className={styles.amount}>₹{transactionData.amountPaid}/-</p>
            {/* <p className={styles.redirectMessage}>
              Redirecting to My Learning in <span>{countdown}</span> seconds...
            </p> */}
          </>
        ) : (
          <p className={styles.failedMessage}>
            {isPaymentPending
              ? "Your payment is failed. Please try again."
              : "Payment failed. Please try again."}
          </p>
        )}
        <p className={styles.redirectMessage}>
          Redirecting to {isPaymentSuccessful ? "My Learning" : "Cart"} in{" "}
          <span>{countdown}</span> seconds...
        </p>
        {isPaymentSuccessful && (
          <button
            className={styles.redirectButton}
            onClick={redirectToMyLearning}
          >
            Go to My Learning
          </button>
        )}
        {!isPaymentSuccessful && (
          <button className={styles.redirectButton} onClick={redirectToCart}>
            Back
          </button>
        )}
      </div>
    </div>
  );
};

export default VideoTransactionModal;
