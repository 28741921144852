import React, { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

const MetaTags = ({ type }) => {
  const [metaTag, setMetaTag] = useState(null);
  const baseUrl = "https://auth.ssccglpinnacle.com/api";

  useEffect(() => {
    const fetchMetaTag = async () => {
      try {
        const response = await fetch(`${baseUrl}/meta/${type}`);
        const data = await response.json();
        setMetaTag(data);
      } catch (error) {
        console.error("Error fetching meta tag:", error);
      }
    };
    fetchMetaTag();
  }, [type]);

  if (!metaTag || !metaTag.success || !metaTag.data) return null;

  const jsonLD = metaTag.data.jsonLD;
  const jsonLDScript = jsonLD
    ? JSON.stringify({
        "@context": jsonLD["@context"],
        "@type": jsonLD["@type"],
        name: jsonLD.name,
        description: jsonLD.description,
        keywords: jsonLD.keywords,
        url: jsonLD.url,
      })
    : null;
  // console.log(jsonLDScript);
  return (
    <HelmetProvider>
      <Helmet>
        <title>{metaTag.data.title}</title>
        <meta name="description" content={metaTag.data.description} />
        <meta name="keywords" content={metaTag.data.keywords} />
        <link rel="canonical" href={`${window.location.origin}/${type}/`} />
        {/* Inject JSON-LD only if available */}
        {jsonLDScript && (
          <script type="application/ld+json">{jsonLDScript}</script>
        )}
      </Helmet>
    </HelmetProvider>
  );
};

export default MetaTags;
