import React from "react";
import Row1 from "./Row1";
import classes from "./Row1.module.css";
import Row2 from "./Row2";
import Row3 from "./Row3";
import "./Body.css";
import Row4 from "./Row4";
import Row5 from "./Row5";
import Row6 from "./Row6";
import Rowtyping from "./Rowtyping";
import Footer from "../../FooterMain/Footer";
import Header from "../../component/HeaderFiles/Header";

function HomePageBody() {
  return (
    <>
      <div className="main-body-container">
        <Header />
        <div className="Body-container">
          <Row1 />
          <Row2 />
          <div className="background-blue"></div>
          <Row3 />
          <div className="background-blue"></div>
          <Row4 />
          <div className="background-blue"></div>
          <Rowtyping />
          <div className="background-blue"></div>
          <Row5 />
          <div className="background-blue"></div>
          <Row6 />
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </>
  );
}

export default HomePageBody;
