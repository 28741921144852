import React, { useRef, useEffect, useState } from "react";
import styles from "./UpcomingCourse.module.css";
import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import gsap from "gsap";

export default function MostPopularCourses({ category = "All", subject }) {
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const scrollRef = useRef(null);
  const baseURL = "https://auth.ssccglpinnacle.com";

  useEffect(() => {
    // Construct query parameters based on category and subject
    const queryParams = [];
    if (category && category !== "All")
      queryParams.push(`category=${category}`);
    if (subject && subject.length > 0)
      queryParams.push(`subject=${subject.join(",")}`);

    const queryString = queryParams.length ? `?${queryParams.join("&")}` : "";

    // Build the API URL (default or with category/subject)
    const url = `${baseURL}/mpc/courses${queryString}`;

    setLoading(true);

    // Fetch data from the API
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setCourses(Array.isArray(data) ? data : []);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching courses:", error);
        setLoading(false);
      });
  }, [category, subject]);

  const scroll = (shift) => {
    const targetScroll = scrollRef.current.scrollLeft + shift;
    gsap.to(scrollRef.current, {
      scrollLeft: targetScroll,
      duration: 0.6,
      ease: "power2.inOut",
    });
  };

  const handleViewAll = () => {
    navigate("/view-all-courses", { state: { category, subject } });
  };

  return (
    <div className={styles["UpcomingCourse-firstDiv"]}>
      <div className={styles.forbutton_div}>
        <button
          onClick={() => scroll(-280)}
          className={styles["sliderButton-left"]}
        >
          <AiFillLeftCircle size={30} />
        </button>
        <div className={styles.Course_Heading}>
          Most Popular Courses
          {category && category !== "All" ? ` for ${category}` : ""}
          {subject && subject.length > 0 ? ` - ${subject.join(", ")}` : ""}
        </div>
        <div className={styles["main-card-wrapper"]}>
          <div className={styles["scroll-container"]} ref={scrollRef}>
            <div className={styles["card-wrapper"]}>
              {loading ? (
                <div>Loading...</div>
              ) : courses.length ? (
                courses.map((course) => (
                  <div
                    key={course._id}
                    className={styles["card"]}
                    onClick={() =>
                      navigate(
                        `/videos/${course.category
                          ?.trim()
                          .toLowerCase()
                          .replace(/ +/g, "-")}/${course.subject
                          ?.trim()
                          .toLowerCase()
                          .replace(/ +/g, "-")}/${course.SEOPermalink}`
                      )
                    }
                  >
                    <div className={styles["Image-section"]}>
                      <img
                        className={styles.imagecard}
                        src={course.hindiCoverImage}
                        alt="Hindi cover"
                      />
                      <img
                        className={styles.imagecard}
                        src={course.englishCoverImage}
                        alt="English cover"
                      />
                    </div>
                    <div className={styles["description-section"]}>
                      <div className={styles["title"]}>
                        {course.courseTitle}
                      </div>
                      <div className={styles["gst"]}>18% GST included</div>
                      <div className={styles["price-fprice-div"]}>
                        <div className={styles["price"]}>₹ {course.price}</div>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <div className={styles["mrp"]}>
                          <s>₹{course.mrp}</s>
                        </div>
                        <div className={styles["savings"]}>
                          Save
                          {Math.round(
                            ((course.mrp - course.price) / course.mrp) * 100
                          )}
                          %
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div>No courses available</div>
              )}
            </div>
          </div>
        </div>
        <button
          onClick={() => scroll(280)}
          className={styles["sliderButton-right"]}
        >
          <AiFillRightCircle size={30} />
        </button>
      </div>
      <div className={styles.viewAllButtonDiv}>
        <button onClick={handleViewAll} className={styles.viewAllButton}>
          VIEW ALL
        </button>
      </div>
    </div>
  );
}
