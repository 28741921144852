// import React, { useState, useEffect } from 'react';
// import './MylearningRow3.css';
// import { BsArrowRight } from 'react-icons/bs';
// import pic from '../i/book.jpg';
// import { useAuth } from '../../Context/AuthContext';
// import { useNavigate } from 'react-router-dom';
// import LoadingSpinner from '../LoadingSpinner';

// // Define the truncateText function
// function truncateText(text, maxWords) {
//   const words = (text || '').split(' '); // Add fallback for undefined text

  
//   if (words.length > maxWords) {
//     return words.slice(0, maxWords).join(' ') + '...';
//   }

//   return text;
// }

// function MylearningRow3() {
//   const [chaptersData, setChaptersData] = useState([]);
//   const [ebooksData, setEbooksData] = useState([]);
//   const navigate = useNavigate();
//   const { isLoggedIn, user, token } = useAuth();
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         if (isLoggedIn && user) {
//           const userId = user.id;
//           setLoading(true);

//           // Fetch chapters and ebooks simultaneously
//           const [chaptersRes, ebooksRes] = await Promise.all([
//             fetch('https://auth.ssccglpinnacle.com/api/chapters-ebook', {
//               headers: {
//                 Authorization: `Bearer ${token}`,
//               },
//             }),
//             fetch('https://auth.ssccglpinnacle.com/api/ebooks', {
//               // Add necessary headers if needed
//             }),
//           ]);

//           if (!chaptersRes.ok || !ebooksRes.ok) {
//             throw new Error('Network response was not ok');
//           }

//           const [chaptersData, ebooksData] = await Promise.all([
//             chaptersRes.json(),
//             ebooksRes.json(),
//           ]);

//           setChaptersData(chaptersData);
//           setEbooksData(ebooksData);
//         }
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, [isLoggedIn, user, token]);

//   // Function to count the total chapters for each eBook
//   const countTotalChapters = (chapters) => {
//     return chapters.length;
//   };

//   const info = () => {
//     navigate("/showallebook")
//   }

//   if (loading) {
//     return <><LoadingSpinner/></>;
//   }

//   return (
//     <>
//       <div className="my-learning-row3">
//         <div className="inprogress-header-new">
//           <h2>More digital content</h2>
//           <div className="show-all-new">
//             <BsArrowRight size={20} color="black" />
//             <p onClick={info}>Explore all</p>
//           </div>
//         </div>

//         <div className="card-for-progress-info">
//           {ebooksData.slice(0, 4).map((ebook) => {
//             // Filter related chapters for each eBook
//             const relatedChapters = chaptersData.filter(
//               (chapter) => chapter.ebook?._id === ebook._id
//             );
//             const totalChapters = countTotalChapters(relatedChapters); // Count total chapters

//             return (
//               <div className="custom-card digital-content-card-progress" key={ebook._id}>
//                 <div className="class-for-img-progress-new">
//                   <div className="classes-for-pro-img-new">
//                     <img className="card-image-progress-new" src={ebook.image} alt={ebook.title} />
//                   </div>
//                 </div>
//                 <div className="card-content-progress">
//                   <div className="title-progress-report">
//                     <h6>{ebook.title}</h6>
//                   </div>
//                   <div className="lesson-completed">
//                     <p><b>{totalChapters}</b> lessons</p> {/* Display total chapters */}
//                   </div>
//                   <div className="ebook-information-progress">
//                     <p>{truncateText(ebook.details, 20)}</p>
//                   </div>
//                   <div className="read-more-process">
//                     <p className="read-more-para">Read more</p>
//                     <BsArrowRight className="arrow-process" size={18} color="black" />
//                   </div>
//                 </div>
//               </div>
//             );
//           })}
//         </div>
//       </div>
//     </>
//   );
// }

// export default MylearningRow3;

import React, { useState, useEffect } from 'react';
import './MylearningRow3.css';
import { BsArrowRight } from 'react-icons/bs';
import pic from '../i/book.jpg'; // Ensure the correct path to the image
import { useAuth } from '../../Context/AuthContext';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../LoadingSpinner'; // Ensure this path is correct

// Define the truncateText function to limit text display
function truncateText(text, maxWords) {
  const words = (text || '').split(' '); // Add fallback for undefined text
  if (words.length > maxWords) {
    return words.slice(0, maxWords).join(' ') + '...';
  }
  return text;
}

function MylearningRow3() {
  const [chaptersData, setChaptersData] = useState([]);
  const [ebooksData, setEbooksData] = useState([]);
  const navigate = useNavigate();
  const { isLoggedIn, user, token } = useAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isLoggedIn && user) {
          const userId = user.id;
          setLoading(true);

          // Fetch chapters and ebooks simultaneously
          const [chaptersRes, ebooksRes] = await Promise.all([
            fetch('https://auth.ssccglpinnacle.com/api/chapters-ebook', {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }),
            fetch('https://auth.ssccglpinnacle.com/api/ebooks', {
              // Add necessary headers if needed
            }),
          ]);

          if (!chaptersRes.ok || !ebooksRes.ok) {
            throw new Error('Network response was not ok');
          }

          const [chaptersData, ebooksData] = await Promise.all([
            chaptersRes.json(),
            ebooksRes.json(),
          ]);

          setChaptersData(chaptersData);
          setEbooksData(ebooksData);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [isLoggedIn, user, token]);

  // Function to count the total chapters for each eBook
  const countTotalChapters = (chapters) => {
    return chapters.length;
  };

  const info = () => {
    navigate("/ebooks/showallebook")
  }

  if (loading) {
    return <><LoadingSpinner/></>;
  }

  return (
    <>
      <div className="my-learning-row3">
        <div className="inprogress-header-new">
          <h2>More digital content</h2>
          <div className="show-all-new" onClick={info}>
            <BsArrowRight size={20} color="black" />
            <p>Explore all</p>
          </div>
        </div>

        <div className="card-for-progress-info">
          {ebooksData.slice(0, 4).map((ebook) => {
            // Filter related chapters for each eBook
            const relatedChapters = chaptersData.filter(
              (chapter) => chapter.ebook?._id === ebook._id
            );
            const totalChapters = countTotalChapters(relatedChapters); // Count total chapters

            return (
              <div className="custom-card digital-content-card-progress" key={ebook._id}>
                <div className="class-for-img-progress-new">
                  <div className="classes-for-pro-img-new">
                    <img className="card-image-progress-new" src={ebook.image || pic} alt={ebook.title} />
                  </div>
                </div>
                <div className="card-content-progress">
                  <div className="title-progress-report">
                    <h6>{ebook.title}</h6>
                  </div>
                  <div className="lesson-completed">
                    <p><b>{totalChapters}</b> lessons</p> {/* Display total chapters */}
                  </div>
                  <div className="ebook-information-progress">
                    <p>{truncateText(ebook.details, 20)}</p>
                  </div>
                  <div className="read-more-process">
                    <p className="read-more-para">Read more</p>
                    <BsArrowRight className="arrow-process" size={18} color="black" />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default MylearningRow3;

