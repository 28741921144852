import React, { useEffect, useState } from "react";
import axios from "axios";
import styles from "./PrivacyPolicy.module.css";
import Header from "../component/HeaderFiles/Header";
import Footer from "./Footer";
import MetaTags from "../DynamicMetaData/DynamicMetadata";

const PrivacyPolicy = () => {
  const [privacyContent, setPrivacyContent] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPrivacyContent = async () => {
      try {
        const response = await axios.get(
          "https://auth.ssccglpinnacle.com/api/footer"
        );

        if (response.data && response.data.length > 0) {
          console.log(
            "Fetched Privacy Policy Data:",
            response.data[0].privacy_policy
          );
          setPrivacyContent(response.data[0].privacy_policy || "");
        } else {
          setError("Failed to load Privacy Policy content");
        }
      } catch (error) {
        console.error("Error fetching Privacy Policy content:", error);
        setError("An error occurred while fetching Privacy Policy content");
      } finally {
        setLoading(false);
      }
    };

    fetchPrivacyContent();
  }, []);

  return (
    <>
      <MetaTags type="privacy-policy" />
      <Header />
      <div className={styles.privacyContainer}>
        {loading && <p>Loading Privacy Policy...</p>}
        {error && <p className={styles.errorMessage}>{error}</p>}
        {!loading && !error && (
          <div dangerouslySetInnerHTML={{ __html: privacyContent }} />
        )}
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
