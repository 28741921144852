import React, { useEffect } from "react";
import CourseDetails from "./CourseDetails";
import styles from "./Page.module.css";
import Header from "../HomePage01/Header/Header";
import Footer from "../Footer/Footer";

export default function Page02() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className={styles["main-body-course"]}>
        <Header />
        <CourseDetails />
        <div className={styles["footer"]}>
          <div className={styles["footer"]}>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
}
