import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../../Context/ThemeContext"; 
import "./Home.css";
import { useNavigate, useParams } from 'react-router-dom';
import Footer from "../../../component/Footer/Footer";
import Header from "../../../component/DigitalcontentHeader/Header";
import Sidebar1 from "../../../component/SidebarDigital/Sidebar";
import LoadingSpinner from "../../LoadingSpinner";

function HomeEbook({ chapterData, cardId, ebookName }) {
  const { theme } = useContext(ThemeContext);
  const navigate = useNavigate(); 
  const [bookDetails, setBookDetails] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state

  const chaptersForEbook = chapterData.filter(chapter => chapter.ebook._id === cardId);
  const firstChapter = chaptersForEbook[0];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!firstChapter) {
      // navigate("/ebooks");
    }
  }, [firstChapter, navigate]);

  // Fetch the book details based on cardId
  useEffect(() => {
    const fetchBookDetails = async () => {
      try {
        setLoading(true); // Start loading
        const response = await fetch('https://auth.ssccglpinnacle.com/api/ebooksforactive?active=true');
        const data = await response.json();
        const matchingBook = data.find(book => book._id === cardId);

        if (matchingBook) {
          setBookDetails(matchingBook);
        } else {
          console.error('No matching book found');
        }
      } catch (error) {
        console.error('Failed to fetch book details:', error);
      } finally {
        setLoading(false); // End loading
      }
    };

    fetchBookDetails();
  }, [cardId]);

  const handleClick = () => {
    navigate(`/ebook/${cardId}/digitalPage1`);
  };

  const handleClick2 = () => {
    navigate('/homequiz');
  };

  const handleClick3 = () => {
    navigate('/exer-page');
  };

  return (
    <>
      <div className="main-body-container">
        <div
          className={`my-component ${
            theme === "dark" ? "dark-theme" : "light-theme"
          }`}
        >
          <Header/>
          {chapterData.length > 0 ? (
            <Sidebar1 chapterData={chapterData} cardId={cardId} />
          ) : (
            <LoadingSpinner />
          )}
          <div className="body-for-home"> 
         

            {/* <div className="w3-clear nextprev">
              <a className="w3-left w3-btn" href="">❮ Home</a>
              <a className="w3-right w3-btn" href="">Next ❯</a>
            </div> */}
            
            {/* Display the fetched book details or loading spinner */}
            {loading ? (
  <LoadingSpinner />
) : bookDetails ? (
  <div className="w3-panel w3-info intro">
    <h2>{bookDetails.title}</h2>
    <div className="details-list">
      {bookDetails.details.split('.').map((line, index) => (
        <p key={index} className="details-item">
          <span className="block-icon">■</span> {line.trim()}
        </p>
      ))}
    </div>
    <div className="w3-btn w3-margin-bottom" onClick={handleClick}>
      Click here for the {firstChapter?.title} »
    </div>
  </div>
) : (
  <p>No details available</p>
)}
            <div className="home-row6">
            </div>
            <div className="home-row7">
            </div>
            <div className="home-row8">
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    </>
  );
}

export default HomeEbook;
