import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styles from "./TypingTransactionModal.module.css";
import { useAuth } from "../Context/AuthContext";

const TypingTransactionModal = () => {
  const [transactionData, setTransactionData] = useState(null);
  const [countdown, setCountdown] = useState(8); // 8-second countdown
  const [loading, setLoading] = useState(true); // Loading state
  const navigate = useNavigate();

  const { isLoggedIn, user } = useAuth();
  const userId = user?._id;
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get("order_id");

  const closeModal = () => {
    navigate(-1);
  };

  const redirectToTypingDashboard = () => {
    navigate(`/typingexamselection`);
  };

  const redirectToHome = () => {
    navigate(`/typing`);
  };

  useEffect(() => {
    if (orderId) {
      fetch(`https://auth.ssccglpinnacle.com/api/typing-transaction/${orderId}`)
        .then((response) => response.json())
        .then((data) => {
          setTransactionData(data);
          setLoading(false); // Stop loading after data is fetched
        })
        .catch((error) => {
          console.error("Error fetching transaction:", error);
          setLoading(false); // Stop loading even if there's an error
        });
    }
  }, [orderId]);

  useEffect(() => {
    if (transactionData?.paymentStatus === "Completed") {
      // Push data to the dataLayer for GTM tracking
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "order_complete",
        order_value: transactionData.orderAmount,
        order_id: transactionData.orderId,
      });

      const timer = setInterval(() => {
        setCountdown((prev) => {
          if (prev === 1) {
            clearInterval(timer);
            redirectToTypingDashboard(); // Redirect to Typing Dashboard when countdown ends
          }
          return prev - 1;
        });
      }, 1000); // 1-second interval

      return () => clearInterval(timer); // Cleanup on unmount
    }
  }, [transactionData]);

  useEffect(() => {
    if (transactionData?.paymentStatus === "Pending") {
      const timer = setInterval(() => {
        setCountdown((prev) => {
          if (prev === 1) {
            clearInterval(timer);
            redirectToHome(); // Redirect to Home when countdown ends
          }
          return prev - 1;
        });
      }, 1000); // 1-second interval

      return () => clearInterval(timer); // Cleanup on unmount
    }
  }, [transactionData]);

  if (loading) {
    return (
      <div className={styles.modalOverlay}>
        <div className={styles.modalContent}>
          <h2>Loading transaction details...</h2>
        </div>
      </div>
    );
  }

  if (!orderId) return null;

  const paymentStatus = transactionData?.paymentStatus;
  const isPaymentSuccessful = paymentStatus === "Completed";
  const isPaymentPending = paymentStatus === "Pending";
  const isPaymentFailed = paymentStatus === "Failed";

  return (
    <div className={styles.modalOverlay} onClick={closeModal}>
      <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        <div className={styles.iconWrapper}>
          <div
            className={
              isPaymentSuccessful ? styles.successIcon : styles.failedIcon
            }
          >
            <span className={styles.checkMark}>
              {isPaymentSuccessful ? "✓" : "✗"}
            </span>
          </div>
        </div>
        <h2
          className={`${styles.statusText} ${
            isPaymentSuccessful ? styles.success : styles.failed
          }`}
        >
          {isPaymentSuccessful
            ? "Payment Successful"
            : isPaymentPending
            ? "Payment Failed"
            : isPaymentFailed
            ? "Payment Failed"
            : ""}
        </h2>
        {transactionData && isPaymentSuccessful ? (
          <>
            <p className={styles.greet}>Thank you for choosing Pinnacle.</p>
            <p className={styles.amount}>₹{transactionData.orderAmount}/-</p>
            <p className={styles.redirectMessage}>
              Redirecting to Typing Dashboard in <span>{countdown}</span>{" "}
              seconds...
            </p>
          </>
        ) : (
          <p className={styles.failedMessage}>
            {isPaymentPending
              ? "Your payment is failed. Please try again."
              : "Payment failed. Please try again."}
          </p>
        )}
        {isPaymentSuccessful && (
          <button
            className={styles.redirectButton}
            onClick={redirectToTypingDashboard}
          >
            Go to Typing Dashboard
          </button>
        )}
        {!isPaymentSuccessful && (
          <div>
            <p className={styles.redirectMessage}>
              Redirecting to Home in <span>{countdown}</span> seconds...
            </p>
            <div className={styles.spinner}></div>
            <button className={styles.redirectButton} onClick={redirectToHome}>
              Go back to Home
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default TypingTransactionModal;
