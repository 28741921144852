// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.breadcrumb {
  font-size: 1.2em;
  color: #666;
  margin-bottom: 1em;
  display: flex;
  justify-content: center;
}

.filters-wrapper {
  display: flex;
  flex-direction: column;
  gap: 2%;
  max-width: 300px;
}

.filter-section {
  background: #fff;
  border: 1px solid #ddd;
  /* border-radius: 8px; */
  padding: 1em;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 9%;
}

.filter-section h4 {
  font-size: 1.2em;
  margin-bottom: 1em;
  /* font-weight: bold; */
  color: #333;
  border-bottom: 1px solid #ddd;
  padding-bottom: 0.5em;
  text-align: center;
}

.filter-items {
  max-height: 200px;
  overflow-y: auto;
}

.filter-items::-webkit-scrollbar {
  width: 6px;
}

.filter-items::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.filter-items::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.for-padding-input {
  margin-bottom: 0.8em;
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.checkbox-input {
  accent-color: #333;
  width: 16px;
  height: 16px;
}

.checkbox-label {
  font-size: 1em;
  color: #333;
}

@media (max-width: 768px) {
  .filters-wrapper {
    max-width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/PagesBook/DigitalContentBody/Row1Digital.css"],"names":[],"mappings":";;AAEA;EACE,gBAAgB;EAChB,WAAW;EACX,kBAAkB;EAClB,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,OAAO;EACP,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,sBAAsB;EACtB,wBAAwB;EACxB,YAAY;EACZ,0CAA0C;EAC1C,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,uBAAuB;EACvB,WAAW;EACX,6BAA6B;EAC7B,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,oBAAoB;EACpB,aAAa;EACb,mBAAmB;EACnB,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,cAAc;EACd,WAAW;AACb;;AAEA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":["\n\n.breadcrumb {\n  font-size: 1.2em;\n  color: #666;\n  margin-bottom: 1em;\n  display: flex;\n  justify-content: center;\n}\n\n.filters-wrapper {\n  display: flex;\n  flex-direction: column;\n  gap: 2%;\n  max-width: 300px;\n}\n\n.filter-section {\n  background: #fff;\n  border: 1px solid #ddd;\n  /* border-radius: 8px; */\n  padding: 1em;\n  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);\n  margin-bottom: 9%;\n}\n\n.filter-section h4 {\n  font-size: 1.2em;\n  margin-bottom: 1em;\n  /* font-weight: bold; */\n  color: #333;\n  border-bottom: 1px solid #ddd;\n  padding-bottom: 0.5em;\n  text-align: center;\n}\n\n.filter-items {\n  max-height: 200px;\n  overflow-y: auto;\n}\n\n.filter-items::-webkit-scrollbar {\n  width: 6px;\n}\n\n.filter-items::-webkit-scrollbar-thumb {\n  background: #888;\n  border-radius: 4px;\n}\n\n.filter-items::-webkit-scrollbar-thumb:hover {\n  background: #555;\n}\n\n.for-padding-input {\n  margin-bottom: 0.8em;\n  display: flex;\n  align-items: center;\n  gap: 0.5em;\n}\n\n.checkbox-input {\n  accent-color: #333;\n  width: 16px;\n  height: 16px;\n}\n\n.checkbox-label {\n  font-size: 1em;\n  color: #333;\n}\n\n@media (max-width: 768px) {\n  .filters-wrapper {\n    max-width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
