// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.purchase-heading-books {
  font-size: 24px;
  color: #333;
  padding-left: 20px;
  margin-top: 20px;
  text-align: center;
}

.container-digital-view-all-link-boks {
  padding: 20px;
}

.no-purchases-container {
  text-align: center;
  padding: 50px;
}

.no-purchases-heading,
.no-purchases-text {
  color: #555;
}

.for-giving-margin{
  padding: 15%;
  border: 1px solid #ddd;
}

.browse-books-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 20px;
  cursor: pointer;
}

.cards-digital-view-all-link {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.card-digital-view-all-link {
  width: 19%;
  margin-bottom: 20px;
  cursor: pointer;
}

.upper-card-digital-view-all-link {
  /* border: 1px solid #ddd;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1); */
  transition: box-shadow 0.3s ease-in-out;
}

.upper-card-digital-view-all-link:hover {
  box-shadow: 0 5px 15px rgba(0,0,0,0.2);
}

.card-content-digital-view-all-link {
  padding: 20px;
}

.for-image-size-view-all-link img {
  width: 100%; 
  height: auto; 
}

.card-link-wrapper-digital-view-all-link p {
  font-size: 16px;
  color: #333;
  text-align: center;
  padding: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/PagesBook/PurchaseBook.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,WAAW;EACX,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,aAAa;AACf;;AAEA;;EAEE,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,8BAA8B;AAChC;;AAEA;EACE,UAAU;EACV,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE;0CACwC;EACxC,uCAAuC;AACzC;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,eAAe;EACf,WAAW;EACX,kBAAkB;EAClB,aAAa;AACf","sourcesContent":[".purchase-heading-books {\n  font-size: 24px;\n  color: #333;\n  padding-left: 20px;\n  margin-top: 20px;\n  text-align: center;\n}\n\n.container-digital-view-all-link-boks {\n  padding: 20px;\n}\n\n.no-purchases-container {\n  text-align: center;\n  padding: 50px;\n}\n\n.no-purchases-heading,\n.no-purchases-text {\n  color: #555;\n}\n\n.for-giving-margin{\n  padding: 15%;\n  border: 1px solid #ddd;\n}\n\n.browse-books-button {\n  background-color: #007bff;\n  color: white;\n  border: none;\n  padding: 10px 20px;\n  margin-top: 20px;\n  cursor: pointer;\n}\n\n.cards-digital-view-all-link {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-between;\n}\n\n.card-digital-view-all-link {\n  width: 19%;\n  margin-bottom: 20px;\n  cursor: pointer;\n}\n\n.upper-card-digital-view-all-link {\n  /* border: 1px solid #ddd;\n  box-shadow: 0 2px 5px rgba(0,0,0,0.1); */\n  transition: box-shadow 0.3s ease-in-out;\n}\n\n.upper-card-digital-view-all-link:hover {\n  box-shadow: 0 5px 15px rgba(0,0,0,0.2);\n}\n\n.card-content-digital-view-all-link {\n  padding: 20px;\n}\n\n.for-image-size-view-all-link img {\n  width: 100%; \n  height: auto; \n}\n\n.card-link-wrapper-digital-view-all-link p {\n  font-size: 16px;\n  color: #333;\n  text-align: center;\n  padding: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
