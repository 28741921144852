import React, { useState } from "react";
import Swal from "sweetalert2";
import styles from "./AddMetaDataForm.module.css";

// const baseURL = "http://localhost:5000/api";
const baseURL = "https://auth.ssccglpinnacle.com/api";

const AddMetaDataForm = () => {
  const [formData, setFormData] = useState({
    type: "",
    title: "",
    description: "",
    keywords: "",
  });

  const [titleLimit] = useState(60);
  const [descriptionLimit] = useState(160);
  const [loading, setLoading] = useState(false);
  const [generating, setGenerating] = useState({
    keywords: false,
    description: false,
  });

  // const [titleLimit] = useState(60);
  // const [descriptionLimit] = useState(160);
  // const [keywordsLimit] = useState(60);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const validateForm = () => {
    if (!formData.type || !formData.title) {
      Swal.fire("Error", "Meta Type and Meta Title are required.", "error");
      return false;
    }
    if (formData.title.length > titleLimit) {
      Swal.fire("Error", "Meta Title exceeds the character limit.", "error");
      return false;
    }
    if (formData.description.length > descriptionLimit) {
      Swal.fire(
        "Error",
        "Meta Description exceeds the character limit.",
        "error"
      );
      return false;
    }
    return true;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);
    try {
      const response = await fetch(`${baseURL}/add-meta`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (response.ok) {
        Swal.fire("Success", "Meta tag added successfully!", "success");

        // Dynamically inject JSON-LD into the head of the document
        if (data.jsonLD) {
          const script = document.createElement("script");
          script.type = "application/ld+json";
          script.text = JSON.stringify(data.jsonLD);
          document.head.appendChild(script);
        }

        // Reset the form
        setFormData({
          type: "",
          title: "",
          description: "",
          keywords: "",
        });
      } else {
        Swal.fire("Error", data.message || "Failed to add metadata.", "error");
      }
    } catch (error) {
      console.error("Error submitting form:", error.message);
      Swal.fire("Error", "Something went wrong!", "error");
    } finally {
      setLoading(false);
    }
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   if (!validateForm()) return;

  //   setLoading(true);
  //   try {
  //     const response = await fetch(`${baseURL}/add-meta`, {
  //       method: "POST",
  //       headers: { "Content-Type": "application/json" },
  //       body: JSON.stringify(formData),
  //     });

  //     const data = await response.json();

  //     if (response.ok) {
  //       Swal.fire("Success", "Meta tag added successfully!", "success");
  //       setFormData({
  //         type: "",
  //         title: "",
  //         description: "",
  //         keywords: "",
  //       });
  //     } else {
  //       Swal.fire("Error", data.message || "Failed to add metadata.", "error");
  //     }
  //   } catch (error) {
  //     console.error("Error submitting form:", error.message);
  //     Swal.fire("Error", "Something went wrong!", "error");
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const generateDescription = async () => {
    const { type, title } = formData;

    if (!type || !title) {
      Swal.fire(
        "Error",
        "Please fill in the Meta Type and Meta Title fields before generating the description.",
        "error"
      );
      return;
    }

    setGenerating((prev) => ({ ...prev, description: true }));
    try {
      const response = await fetch(`${baseURL}/meta/generate-description`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ type, title }),
      });

      const data = await response.json();

      if (response.ok) {
        setFormData((prev) => ({
          ...prev,
          description: data.description || "No description generated.",
        }));
        Swal.fire(
          "Success",
          "Meta description generated successfully!",
          "success"
        );
      } else {
        Swal.fire(
          "Error",
          data.message || "Failed to generate description.",
          "error"
        );
      }
    } catch (error) {
      console.error("Error generating description:", error.message);
      Swal.fire(
        "Error",
        "Something went wrong while generating the description.",
        "error"
      );
    } finally {
      setGenerating((prev) => ({ ...prev, description: false }));
    }
  };

  const generateKeywords = async () => {
    const { type, title } = formData;

    if (!type || !title) {
      Swal.fire(
        "Error",
        "Please fill in the Meta Type and Meta Title fields before generating keywords.",
        "error"
      );
      return;
    }

    setGenerating((prev) => ({ ...prev, keywords: true }));
    try {
      const response = await fetch(`${baseURL}/meta/generate-keywords`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ type, title }),
      });

      const data = await response.json();

      if (response.ok) {
        setFormData((prev) => ({
          ...prev,
          keywords: data.keywords.join(", ") || "No keywords generated.",
        }));
        Swal.fire("Success", "Keywords generated successfully!", "success");
      } else {
        Swal.fire(
          "Error",
          data.message || "Failed to generate keywords.",
          "error"
        );
      }
    } catch (error) {
      console.error("Error generating keywords:", error.message);
      Swal.fire(
        "Error",
        "Something went wrong while generating keywords.",
        "error"
      );
    } finally {
      setGenerating((prev) => ({ ...prev, keywords: false }));
    }
  };

  return (
    <div className={styles.metaFormContainer}>
      <h2 className={styles.metaFormTitle}>Add Meta Tag</h2>
      <form onSubmit={handleSubmit} className={styles.metaForm}>
        {/* Meta Type Field */}
        <div className={styles.metaFormGroup}>
          <label className={styles.metaLabel}>Meta Type (Unique):</label>
          <input
            type="text"
            name="type"
            value={formData.type}
            onChange={handleChange}
            required
            className={styles.metaInput}
            placeholder="Enter meta type (e.g., home, about-us)"
          />
        </div>

        {/* Meta Title Field */}
        <div className={styles.metaFormGroup}>
          <label className={styles.metaLabel}>Meta Title:</label>
          <div className={styles.metaInputContainer}>
            <input
              type="text"
              name="title"
              value={formData.title}
              onChange={handleChange}
              className={styles.metaInput}
              placeholder="Enter meta title"
              style={{
                color: formData.title.length > titleLimit ? "red" : "black",
              }}
            />
          </div>
          <small className={styles.metaInfoText}>
            {formData.title.length}/{titleLimit} characters
          </small>
        </div>

        {/* Meta Keywords Field */}
        <div className={styles.metaFormGroup}>
          <label className={styles.metaLabel}>Meta Keywords:</label>
          <div className={styles.metaInputContainer}>
            <input
              type="text"
              name="keywords"
              value={formData.keywords}
              onChange={handleChange}
              className={styles.metaInput}
              placeholder="Enter meta keywords (comma-separated)"
            />
            <button
              type="button"
              onClick={generateKeywords}
              className={styles.metaGenerateButton}
              disabled={generating.keywords}
            >
              {generating.keywords ? "Generating..." : "Generate Keywords"}
            </button>
          </div>
        </div>

        {/* Meta Description Field */}
        <div className={styles.metaFormGroup}>
          <label className={styles.metaLabel}>Meta Description:</label>
          <div className={styles.metaInputContainer}>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleChange}
              className={styles.metaTextarea}
              placeholder="Enter meta description"
              style={{
                color:
                  formData.description.length > descriptionLimit
                    ? "red"
                    : "black",
              }}
            />
            <button
              type="button"
              onClick={generateDescription}
              className={styles.metaGenerateButton}
              disabled={generating.description}
            >
              {generating.description
                ? "Generating..."
                : "Generate Description"}
            </button>
          </div>
          <small className={styles.metaInfoText}>
            {formData.description.length}/{descriptionLimit} characters
          </small>
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          className={styles.metaSubmitButton}
          disabled={loading}
        >
          {loading ? "Submitting..." : "Add Meta Tag"}
        </button>
      </form>
    </div>
  );
};

export default AddMetaDataForm;
