import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import styles from "./AddSubjectForm.module.css";

const AddSubjectForm = () => {
  const [subjects, setSubjects] = useState([]);
  const [subjectData, setSubjectData] = useState({
    title: "",
  });
  const [editingSubjectId, setEditingSubjectId] = useState(null);
  const [loading, setLoading] = useState(false);

  // Fetch subjects
  const fetchSubjects = async () => {
    try {
      const response = await axios.get(
        "https://auth.ssccglpinnacle.com/api/subjects"
      );
      setSubjects(response.data.subjects);
    } catch (error) {
      console.error("Error fetching subjects:", error);
      Swal.fire("Error", "Failed to fetch subjects", "error");
    }
  };

  useEffect(() => {
    fetchSubjects();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSubjectData({ ...subjectData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (editingSubjectId) {
        // Update subject
        await axios.put(
          `https://auth.ssccglpinnacle.com/api/subjects/${editingSubjectId}`,
          subjectData
        );
        Swal.fire("Success", "Subject updated successfully", "success");
        setEditingSubjectId(null);
      } else {
        // Add new subject
        await axios.post(
          "https://auth.ssccglpinnacle.com/api/subjects",
          subjectData
        );
        Swal.fire("Success", "Subject created successfully", "success");
      }
      setSubjectData({ title: "" });
      fetchSubjects();
    } catch (error) {
      console.error("Error saving subject:", error);
      Swal.fire("Error", "Failed to save subject", "error");
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (subject) => {
    setEditingSubjectId(subject._id);
    setSubjectData({ title: subject.title });
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://auth.ssccglpinnacle.com/api/subjects/${id}`);
      Swal.fire("Success", "Subject deleted successfully", "success");
      fetchSubjects();
    } catch (error) {
      console.error("Error deleting subject:", error);
      Swal.fire("Error", "Failed to delete subject", "error");
    }
  };

  return (
    <div className={styles["container-unique"]}>
      <h2 className={styles["heading-unique"]}>Manage Subjects</h2>
      <form onSubmit={handleSubmit} className={styles["form-unique"]}>
        <label>Title:</label>
        <input
          type="text"
          name="title"
          value={subjectData.title}
          onChange={handleChange}
          required
          className={styles["input-unique"]}
        />
        <button
          type="submit"
          className={styles["button-unique"]}
          disabled={loading}
        >
          {loading
            ? "Saving..."
            : editingSubjectId
            ? "Update Subject"
            : "Add Subject"}
        </button>
      </form>

      <div className={styles["list-container-unique"]}>
        <h3 className={styles["subheading-unique"]}>Subjects List</h3>
        <ul className={styles["subject-list-unique"]}>
          {subjects.map((subject) => (
            <li key={subject._id} className={styles["subject-item-unique"]}>
              <span>{subject.title}</span>
              <div>
                <button
                  onClick={() => handleEdit(subject)}
                  className={styles["edit-button-unique"]}
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDelete(subject._id)}
                  className={styles["delete-button-unique"]}
                >
                  Delete
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default AddSubjectForm;
